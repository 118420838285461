import { Flex, Heading, Text } from '@chakra-ui/react';
import { Gender } from '@global/constants';
import { Task, TaskFragmentType } from '@global/types';

type TaskItemProps = {
  task: Task;
};

export default function TaskItem(props: TaskItemProps) {
  const { task } = props;
  return (
    <Flex
      // borderBottom="1px solid"
      height="auto"
      width="100%"
      padding="1rem"
    >
      <Flex minW={0}>
        <Flex direction="column" overflow="hidden">
          <Text fontSize="1.8rem">
            {task.content.map((fragment, index) => {
              if (fragment.type === TaskFragmentType.TEXT) {
                return (
                  <Text as="span" key={`task-fragment-${index}`}>
                    {fragment.content}
                  </Text>
                );
              }

              return (
                <Heading
                  as="span"
                  pos="relative"
                  minW={0}
                  overflow="hidden"
                  whiteSpace="break-spaces"
                  textOverflow="ellipsis"
                  className={`playerName`}
                  color={
                    fragment.gender === Gender.FEMALE ? 'todRed' : 'todGreen'
                  }
                  textAlign="left"
                  fontSize="1.8rem"
                  key={`task-fragment-${index}`}
                >
                  {fragment.name}
                </Heading>
              );
            })}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
