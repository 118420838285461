import {
  Box,
  Center,
  Flex,
  Heading,
  Icon,
  Image,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
} from '@chakra-ui/react';
import { Emoji } from '@components/Emoji';
// import { Logo } from '@components/Logo';
import { EsquentaButton } from '@components/EsquentaButton';
import { Footer } from '@components/Footer';
import { IconRadioGroup } from '@components/IconRadioGroup';
import { useGame } from '@context/game';
import { useStrings } from '@context/strings';
import { DEFAULT_LANG, Language, Paths, colors } from '@global/constants';
import logo from '@img/logo.svg';
import { TruthOrDareAPI } from '@utils/TruthOrDareAPI';
import { HiOutlineFire } from 'react-icons/hi';
import { IoPlay } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import './home.scss';

export function Home() {
  const { strings, setStrings } = useStrings();
  const { game, setGame } = useGame();
  const navigate = useNavigate();
  const buttonIcon = <Icon as={IoPlay} />;

  const updateLang = async (lang: Language) => {
    setStrings(lang);
    if (!game.id) {
      setGame({ ...game, lang });
      return;
    }

    const api = await TruthOrDareAPI.setup(game);

    const updatedGame = await api.updateLang(lang);
    setGame(updatedGame);
  };

  const langOptions = [
    {
      value: `${Language.PT_BR}`,
      label: strings.ptBr,
      icon: <Emoji symbol="🇧🇷" label={strings.ptBr} />,
      checkedColor: 'esquentaBlue',
      onSelect: () => updateLang(Language.PT_BR),
    },
    {
      value: `${Language.EN_US}`,
      label: strings.enUs,
      icon: <Emoji symbol="🇺🇸" label={strings.enUs} />,
      checkedColor: 'esquentaPink',
      onSelect: () => updateLang(Language.EN_US),
    },
  ];

  return (
    <>
      <Flex maxW="1140px" direction="column" alignSelf="center">
        <Center>
          <SimpleGrid columns={{ sm: 1, md: 2 }}>
            <Flex
              direction="row"
              alignItems="center"
              textAlign="center"
              marginTop={0}
              minHeight="100%"
            >
              <Flex direction="column" alignContent="center">
                <Image
                  src={logo}
                  alt="truth or dare logo"
                  width="500px"
                  alignSelf="center"
                />
              </Flex>
            </Flex>
            <Box marginTop="55px">
              <Center>
                <Heading>{strings.rules}</Heading>
              </Center>
              <Center>
                <List
                  width="80%"
                  // marginLeft={{ base: 'auto', lg: '100px' }}
                >
                  {strings.rulesArr.map((rule, index) => (
                    <ListItem
                      key={`rule-${index}`}
                      marginTop="20px"
                      lineHeight="25px"
                      position="relative"
                    >
                      <ListIcon
                        as={HiOutlineFire}
                        color={colors.esquentaPink}
                      />
                      {rule}{' '}
                    </ListItem>
                  ))}
                </List>
              </Center>

              <Flex
                direction="row"
                justifyContent="space-between"
                margin="50px"
              >
                <IconRadioGroup
                  name="lang"
                  defaultValue={game.lang || DEFAULT_LANG}
                  options={langOptions}
                  width="auto"
                />
                <EsquentaButton
                  rightIcon={buttonIcon}
                  float="right"
                  colorType="pink"
                  size="lg"
                  onClick={() => navigate(Paths.PLAYERS)}
                >
                  {strings.startGame}
                </EsquentaButton>
              </Flex>
            </Box>
          </SimpleGrid>
        </Center>
      </Flex>
      <Footer />
    </>
  );
}
