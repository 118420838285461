export default function ExtremelyHotIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="511px"
      viewBox="0 0 511 511"
      enableBackground="new 0 0 511 511"
    >
      <path
        fill="#FCB44D"
        d="M419.568,256.693c-5.834-36.656-20.876-71.329-43.789-100.929c-1.047-1.383-2.912-1.918-4.562-1.308
	c-1.651,0.606-2.686,2.211-2.535,3.928c6.309,71.672-103.826,80.062-81.576-6.776c7.651-29.87,13.884-73.137-15.31-94.538
	c-2.286-1.693-5.757-3.08-7.729-1.076c-3.867,4.002,1.26,9.699,2.211,13.701c1.238,5.113,0.564,10.493-1.896,15.167
	c-11.674,23.942-41.182,31.948-56.092,53.505c-14.439,20.94-16.332,47.424-13.964,71.597c1.012,8.643,0.719,17.384-0.87,25.945
	c-2.817,14.315-15.92,24.483-30.848,23.942c-31.951-2.463-29.27-44.808-26.902-66.747c0.164-1.676-0.807-3.264-2.398-3.918
	c-1.592-0.65-3.432-0.218-4.546,1.07c-14.436,16.011-40.787,57.892-23.115,138.418c1.529,7.084,3.722,14.016,6.546,20.709
	l80.348,76.176l199.762-48.445L419.568,256.693z"
      />
      <path
        fill="#FD8F31"
        d="M328.286,271.92c-12.386-16.859-31.637-27.944-48.995-39.261c-19.012-12.397-54.119-41.033-39.526-66.286
	c-20.196,32.336,3.156,67.673,14.833,99.621c3.743,10.744,2.8,22.518-2.604,32.563c-5.987,11.105-16.701,19.053-29.27,21.709
	c-15.955,2.658-32.23-2.492-43.548-13.779c-16.807-15.472-12.938,30.564-11.283,36.414c2.056,7.566,5.39,14.748,9.862,21.248
	l87.652,38.955l82.186-70.965L328.286,271.92z"
      />
      <path
        fill="#E5394B"
        d="M462.004,375.005l-11.005-53.682l-37.784-36.569L333.25,298.22c-14.684,13.859-60.805,53.053-121.358,63.441
	c-26.798,4.787-54.402,2.953-80.289-5.334c-45.42-14.488-71.921-33.049-87.386-50.094c-2.978-3.477-8.037-4.457-12.156-2.361
	c-4.116,2.098-6.183,6.711-4.961,11.086c0.852,3.246,1.812,6.465,2.876,9.654c0.653,1.947,1.348,3.889,2.084,5.818
	c30.917,81.049,126.902,117.709,208.968,124.346C321.146,461.228,413.176,443.114,462.004,375.005z"
      />
      <path
        fill="#4C8056"
        d="M408.537,304.038c0,0-61.165,26.855-93.884-26.589c-0.995-1.658-0.887-3.736,0.282-5.283
	c1.166-1.551,3.163-2.273,5.084-1.84c16.117,3.682,46.627,7.105,65.577-13.018c26.689-28.346,99.902,50.37,93.82,71.058
	c-4.56,15.514-5.041,34.689,3.945,45.814c1.18,1.67,1.183,3.873,0.011,5.551c-1.174,1.672-3.282,2.465-5.303,1.996
	c-14.126-2.805-31.805-12.812-33.202-45.125c0,0-4.416,43.115-47.279,31.82c-1.624-0.414-2.926-1.596-3.46-3.15
	c-0.537-1.549-0.234-3.26,0.799-4.547C404.759,348.706,425.587,319.327,408.537,304.038z"
      />
      <path
        fill="#527F72"
        d="M446.991,284.27c-2.953-0.004-5.658-1.619-7.01-4.184c-1.348-2.564-1.118-5.65,0.604-7.994
	c12.429-16.049,17.703-36.309,14.629-56.2c-4.333-22.487-21.422-43.38-50.793-62.096c-3.652-2.326-4.675-7.1-2.294-10.659
	c2.385-3.56,7.275-4.562,10.923-2.235c33.136,21.101,52.521,45.373,57.681,72.149c3.893,24.031-2.353,48.573-17.319,68.032
	C451.924,283.092,449.533,284.279,446.991,284.27z"
      />
      <g>
        <path
          fill="#FC4E51"
          d="M203.01,397.548c-3.55,0-7.171-0.086-10.857-0.256c-4.35-0.209-7.705-3.814-7.495-8.061
		c0.338-4.186,3.966-7.389,8.27-7.297c42.885,2.453,85.39-9.055,120.85-32.721c3.42-2.568,8.326-1.979,11.004,1.324
		c2.678,3.309,2.133,8.102-1.222,10.756C288.271,385.423,246.098,398.106,203.01,397.548z"
        />
        <path
          fill="#FC4E51"
          d="M350.217,339.839c0,4.252-3.528,7.699-7.886,7.699s-7.89-3.447-7.89-7.699s3.532-7.699,7.89-7.699
		S350.217,335.587,350.217,339.839z"
        />
      </g>
    </svg>
  );
}
