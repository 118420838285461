import { Box, Flex, Image } from '@chakra-ui/react';
import { HeaderActions } from '@components/HeaderActions';
import { Paths, colors } from '@global/constants';
import logo from '@img/logo-reduced.svg';
import { useNavigate } from 'react-router';

type HeaderProps = {
  showActions?: boolean;
  hidePlayerListButton?: boolean;
};

export function Header(props: HeaderProps) {
  const { showActions = false, hidePlayerListButton } = props;
  const navigate = useNavigate();
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      padding="0 1rem 0.5rem 1 rem"
      maxHeight="90px"
      borderBottom={`1px solid ${colors.esquentaBlue}`}
    >
      <Box onClick={() => navigate(Paths.HOME)}>
        <Image
          display="inline-block"
          alignSelf="flex-start"
          maxHeight="110px"
          maxWidth="110px"
          margin="-25px 0 0 0"
          padding={0}
          src={logo}
        />
      </Box>
      {showActions ? (
        <HeaderActions hidePlayerListButton={hidePlayerListButton} />
      ) : undefined}
    </Flex>
  );
}
