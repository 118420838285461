import {
  Avatar,
  Button,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { EsquentaSolidButton } from '@components/EsquentaSolidButton';
import { IconRadioGroup } from '@components/IconRadioGroup';
import LevelIcon from '@components/icons/LevelIcon';
import TaskItem from '@components/TaskItem';
import { useGame } from '@context/game';
import { useStrings } from '@context/strings';
import { colors, LevelSelector, TaskType } from '@global/constants';
import { Level, Player, Task } from '@global/types';
import { TruthOrDareAPI } from '@utils/TruthOrDareAPI';
import { useEffect, useMemo, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { TbRefresh } from 'react-icons/tb';

type TaskModalProps = {
  isOpen: boolean;
  onClose: () => void;
  player?: Player | undefined;
};

type TaskHeaderProps = {
  selector: LevelSelector;
  icon: JSX.Element;
  taskTypeDescription: string;
  onClose: () => void;
};
function TaskHeader({
  icon,
  selector,
  taskTypeDescription,
  onClose,
}: TaskHeaderProps) {
  return (
    <Flex minW={0} alignItems="center" justifyContent="space-between">
      <Flex minW={0} alignItems="center">
        <Avatar
          icon={icon}
          bg={colors.esquentaBlack}
          iconLabel={`${selector} icon`}
          mr={{ base: 2, lg: 4 }}
          minHeight="70%"
          size="lg"
        />
        <Heading
          as="h4"
          // pos="relative"
          minW={0}
          overflow="hidden"
          whiteSpace="break-spaces"
          textOverflow="ellipsis"
          textAlign="left"
          // marginBottom="5px"
          // fontSize="1.5rem"
        >
          {taskTypeDescription}
        </Heading>
      </Flex>
      <IconButton
        aria-label="close"
        bgColor={colors.esquentaAskov}
        size="md"
        color={colors.esquentaWhite}
        fontSize="x-large"
        icon={<IoMdClose />}
        onClick={() => onClose()}
        _hover={{
          bgColor: colors.esquentaWhite,
          color: colors.esquentaAskov,
        }}
      ></IconButton>
    </Flex>
  );
}

export function TaskModal(options: TaskModalProps) {
  const { game } = useGame();
  const { strings } = useStrings();
  const { isOpen, onClose, player } = options;

  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [taskType, setTaskType] = useState<TaskType>();
  const [task, setTask] = useState<Task>();

  if (!game) {
    return null;
  }

  if (!player) {
    return null;
  }

  const newTask = () => {
    setTask(undefined);
    setReload(!reload);
  };

  useEffect(() => {
    (async () => {
      if (!taskType) {
        if (!game?.currentLevels?.some(({ hasTruth }: Level) => hasTruth)) {
          setTaskType(TaskType.DARE);
        }
        return;
      }
      setLoading(true);
      const api = await TruthOrDareAPI.setup(game);

      const apiTask = await api.getTask(player?._id, taskType);

      setTask(apiTask);
      setLoading(false);
    })();
  }, [taskType, reload]);

  const levelsIcons = useMemo(
    () =>
      game.currentLevels.reduce(
        (acc: Record<LevelSelector, JSX.Element>, cur: Level) => {
          acc[cur.selector] = <LevelIcon level={cur.selector} />;
          return acc;
        },
        {} as any,
      ),
    [game.currentLevels],
  );

  const taskTypeOptions = [
    {
      value: `${TaskType.TRUTH}`,
      label: strings.truth,
      hoverColor: colors.esquentaAskov,
      checkedColor: colors.esquentaAskov,
      onSelect: () => setTaskType(TaskType.TRUTH),
    },
    {
      value: `${TaskType.DARE}`,
      label: strings.dare,
      hoverColor: colors.esquentaPepper,
      checkedColor: colors.esquentaPepper,
      onSelect: () => setTaskType(TaskType.DARE),
    },
  ];

  const taskTypeDescription =
    taskType === TaskType.TRUTH ? strings.truth : strings.dare;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="md" closeOnEsc>
        <ModalOverlay />
        <ModalContent
          bgColor={colors.esquentaPink}
          minHeight="auto"
          borderRadius={'xl'}
        >
          <ModalHeader>
            {task ? (
              <TaskHeader
                icon={levelsIcons[task.level]}
                selector={task.level}
                taskTypeDescription={taskTypeDescription}
                onClose={onClose}
              />
            ) : (
              `${player?.name}, ${strings.choose}`
            )}
          </ModalHeader>
          <ModalBody
            borderBottom="none"
            bgColor={colors.esquentaBlack}
            pt={8}
            pb={8}
          >
            {/* <FormControl mt={task ? 'auto' : 4}> */}
            {loading && (
              <Button isLoading variant="ghost" width="100%" height="60px" />
            )}
            {!taskType && (
              <IconRadioGroup name="taskType" options={taskTypeOptions} />
            )}
            {task && <TaskItem task={task} />}
            {/* </FormControl> */}
          </ModalBody>
          <ModalFooter
            // bgColor="todBlack"
            // marginBottom="20px"
            // borderTop={`1px solid ${colors.esquentaPink}`}
            justifyContent={task ? 'space-between' : 'flex-end'}
          >
            {task ? (
              <>
                <Button
                  paddingLeft={0}
                  fontSize="1.2rem"
                  fontWeight="bold"
                  variant="ghost"
                  disabled={true}
                  color={colors.esquentaWhite}
                  _hover={{ bgColor: 'none' }}
                >
                  {
                    game.currentLevels?.find(
                      ({ selector }: Level) => selector === task?.level,
                    )?.info[game.lang].name
                  }
                </Button>
                <IconButton
                  aria-label="close"
                  bgColor={colors.esquentaAskov}
                  size="md"
                  color={colors.esquentaWhite}
                  fontSize="x-large"
                  icon={<TbRefresh />}
                  onClick={() => newTask()}
                  _hover={{
                    bgColor: colors.esquentaWhite,
                    color: colors.esquentaAskov,
                  }}
                ></IconButton>
              </>
            ) : (
              <EsquentaSolidButton
                colorType="esquentaAskov"
                isLoading={loading}
                onClick={() => onClose()}
              >
                {strings.skip}
              </EsquentaSolidButton>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
