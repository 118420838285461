import {
  Button,
  ButtonGroup,
  Tooltip,
  UseRadioProps,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import { Emoji } from '@components/Emoji';
import { colors } from '@global/constants';
import { ReactElement } from 'react';

function RadioCard(props: RadioCardProps) {
  const { label, checkedColor, hoverColor, icon, onSelect, ...rest } = props;
  const { getInputProps, getCheckboxProps } = useRadio(rest);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const button = (
    <Button
      width="50%"
      size="lg"
      aria-label={label}
      as="label"
      htmlFor={input.id}
      color={colors.esquentaWhite}
      bg="todBlack"
      border={`solid 2px ${colors.esquentaPink}`}
      {...checkbox}
      onClick={() => onSelect()}
      _checked={{
        bg: checkedColor,
        color: 'white',
        borderColor: colors.esquentaPink,
      }}
      _hover={{
        bg: hoverColor,
      }}
    >
      {icon ?? label}
    </Button>
  );

  return (
    <>
      {icon ? <Tooltip label={label}>{button}</Tooltip> : button}
      <input {...input} />
    </>
  );
}

type RadioCardProps = UseRadioProps & {
  label: string;
  icon?: ReactElement<typeof Emoji>;
  checkedColor: string;
  hoverColor?: string;
  onSelect: () => void;
};

type IconRadioGroupProps = {
  name: string;
  defaultValue?: string;
  options: RadioCardProps[];
  width?: string;
};

// Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
export function IconRadioGroup(props: IconRadioGroupProps) {
  const { options, name, defaultValue, width = '100%' } = props;
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange: console.log,
  });

  const group = getRootProps();

  return (
    <ButtonGroup {...group} isAttached width={width}>
      {options.map((option, index) => {
        const { label, icon, checkedColor, hoverColor, onSelect } = option;
        const radio = getRadioProps({ value: option.value });
        return (
          <RadioCard
            key={index}
            {...radio}
            label={label}
            icon={icon}
            checkedColor={checkedColor}
            hoverColor={hoverColor}
            onSelect={onSelect}
          />
        );
      })}
    </ButtonGroup>
  );
}
