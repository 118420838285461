import {
  Box,
  ButtonGroup,
  Icon,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { LevelModal } from '@components/LevelModal';
import { PlayerOrderModal } from '@components/PlayerOrderModal';
import { useGame } from '@context/game';
import { useStrings } from '@context/strings';
import { EMPTY_GAME, Paths, PlayerOrder, colors } from '@global/constants';
import { ExitIcon } from '@icons/ExitIcon';
import { PlayersIcon } from '@icons/PlayersIcon';
import { TruthOrDareAPI } from '@utils/TruthOrDareAPI';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';

import { LevelSelectorIcon } from '../icons/LevelSelectorIcon';
import RandomIcon from '../icons/RandomIcon';
import SequenceIcon from '../icons/SequenceIcon';

export type HeaderActionsProps = {
  hidePlayerListButton?: boolean;
};

export function HeaderActions(props: HeaderActionsProps) {
  const { game, setGame } = useGame();
  const { strings } = useStrings();
  const { hidePlayerListButton } = props;
  const levelDisclosure = useDisclosure();
  const playerOrderDisclosure = useDisclosure();
  const navigate = useNavigate();

  const goToPlayersList = () => {
    Swal.fire({
      icon: 'question',
      text: strings.goToPlayersQuestion,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: strings.goToPlayersConfirm,
      denyButtonText: strings.goToPlayersCancel,
    }).then((result) => {
      const { isConfirmed } = result;

      if (isConfirmed) {
        navigate(Paths.PLAYERS);
      }
    });
  };

  const quitGame = () => {
    if (!game?.id) return;

    Swal.fire({
      icon: 'question',
      title: strings.exitGameQuestion,
      text: strings.exitGameDescription,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: strings.exitKeepingPlayers,
      denyButtonText: strings.exitDeletingPlayers,
      cancelButtonText: strings.exitNo,
    }).then((result) => {
      const { isConfirmed: exitKeepingPlayers, isDenied: exitDeletingPlayers } =
        result;

      if (exitKeepingPlayers) {
        resetGame();
        return;
      }

      if (exitDeletingPlayers) {
        setGame(EMPTY_GAME);
        navigate(Paths.HOME);
      }
    });
  };

  const resetGame = async () => {
    const api = await TruthOrDareAPI.setup(game);
    const resettedGame = await api.resetGame();

    setGame({ ...resettedGame, lastPlayerIndex: -1 });
    navigate(Paths.HOME);
  };

  const actions = [
    {
      label: strings.playerOrder,
      icon:
        game?.playerOrder === PlayerOrder.SEQUENTIAL
          ? SequenceIcon
          : RandomIcon,
      onClick: () => playerOrderDisclosure.onOpen(),
    },
    {
      label: strings.level,
      icon: LevelSelectorIcon,
      onClick: () => levelDisclosure.onOpen(),
    },
    {
      label: strings.players,
      icon: PlayersIcon,
      onClick: () => goToPlayersList(),
      hide: hidePlayerListButton,
    },
    {
      label: 'Exit',
      icon: ExitIcon,
      onClick: () => quitGame(),
    },
  ];
  return (
    <>
      <Box display={{ base: 'block', md: 'block' }} mt={{ base: 0, md: 0 }}>
        <ButtonGroup display="flex">
          {actions.map(({ label, icon, onClick, hide }, index) =>
            hide ? undefined : (
              <IconButton
                aria-label={label}
                isRound={true}
                border={`1.5px solid ${colors.esquentaPink}`}
                icon={<Icon as={icon} maxHeight="100%"></Icon>}
                maxHeight="60px"
                maxWidth="60px"
                className="headerAction"
                variant="link"
                onClick={onClick}
                key={`header-action-${index}`}
              />
            ),
          )}
        </ButtonGroup>
      </Box>

      {levelDisclosure.isOpen ? (
        <LevelModal
          isOpen={levelDisclosure.isOpen}
          onClose={levelDisclosure.onClose}
        />
      ) : undefined}

      {playerOrderDisclosure.isOpen ? (
        <PlayerOrderModal
          isOpen={playerOrderDisclosure.isOpen}
          onClose={playerOrderDisclosure.onClose}
        />
      ) : undefined}
    </>
  );
}
