import {
  Gender,
  Language,
  LevelSelector,
  PlayerOrder,
  SexualPreference,
} from '@global/constants';
import { Dayjs } from 'dayjs';

export type Model = {
  id: number;
  creationDate: string | Date | Dayjs;
  lastUpdate: string | Date | Dayjs;
};

export type Player = {
  _id: string;
  name: string;
  gender: Gender;
  sexualPreference: SexualPreference;
  answeredQuestions?: number[];
};

export type Game = {
  id: string;
  lang: Language;
  playerOrder: PlayerOrder;
  token: string;
  levels: LevelSelector[];
  players: Player[];
  lastPlayerIndex?: number;
  currentLevels: Level[];
};

export enum TaskFragmentType {
  TEXT = 'TEXT',
  PLAYER = 'PLAYER',
}

export type TaskPlayer = {
  type: TaskFragmentType.PLAYER;
  name: string;
  gender: Gender;
};

export type TaskText = {
  type: TaskFragmentType.TEXT;
  content: string;
};

export type TaskFragment = TaskText | TaskPlayer;

export type Task = {
  content: TaskFragment[];
  level: LevelSelector;
};

export type Level = {
  id: string;
  selector: LevelSelector;
  info: Record<Language, { name: string; description: string }>;
  sortIndex: number;
  hasTruth: boolean;
};

export type CurrentPlayerState = Player | undefined | Record<string, unknown>;
