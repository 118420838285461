import { Button, ButtonProps } from '@chakra-ui/react';
import { colors } from '@global/constants';

interface EsquentaButtonProps extends ButtonProps {
  colorType: keyof typeof colors;
  secondColor?: keyof typeof colors;
}

export function EsquentaSolidButton(props: EsquentaButtonProps) {
  const { colorType, secondColor, ...restProps } = props;

  const buttonDefinition = {
    bgColor: colorType,
    color: secondColor || 'esquentaWhite',
    variant: 'solid',
    _hover: {
      color: colorType,
      borderColor: colorType,
      bgColor: secondColor || 'esquentaWhite',
    },
  };

  const updatedProps = {
    ...restProps,
    ...buttonDefinition,
  };

  return <Button {...updatedProps} />;
}
