import {
  FormControl,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Emoji } from '@components/Emoji';
import { EsquentaButton } from '@components/EsquentaButton';
import { IconRadioGroup } from '@components/IconRadioGroup';
import { useGame } from '@context/game';
import { useStrings } from '@context/strings';
import {
  Gender,
  GenderEmojis,
  HomosexualPreferenceDescriptions,
  SexualPreference,
  SexualityEmojis,
  colors,
} from '@global/constants';
import { Player } from '@global/types';
import { TruthOrDareAPI } from '@utils/TruthOrDareAPI';
import { useMemo, useRef, useState } from 'react';
import { FiUserCheck } from 'react-icons/fi';
import { IoMdArrowRoundBack } from 'react-icons/io';
type PlayerModalProps = {
  isOpen: boolean;
  onClose: () => void;
  player?: Player | undefined;
};

export function PlayerModal(options: PlayerModalProps) {
  const { game, setGame } = useGame();
  const { strings } = useStrings();
  const { isOpen, onClose, player } = options;
  const initialRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(player?.name ?? '');
  const [gender, setGender] = useState(player?.gender ?? Gender.MALE);
  const [sexualPreference, setSexualPreference] = useState(
    player?.sexualPreference ?? SexualPreference.BISEXUAL,
  );

  const savePlayer = async () => {
    if (!game?.id) {
      return;
    }
    setLoading(true);

    const newPlayer = { _id: player?._id, name, gender, sexualPreference };

    const api = await TruthOrDareAPI.setup(game);

    const updatedGame = await (player?._id
      ? api.updatePlayer(newPlayer)
      : api.createPlayer(newPlayer));

    setGame(updatedGame);
    setLoading(false);
    onClose();
  };

  const genderOptions = [
    {
      value: `${Gender.MALE}`,
      label: strings.male,
      icon: <Emoji symbol={GenderEmojis[Gender.MALE]} label={strings.male} />,
      checkedColor: colors.esquentaBlue,
      onSelect: () => setGender(Gender.MALE),
    },
    {
      value: `${Gender.FEMALE}`,
      label: strings.female,
      icon: (
        <Emoji symbol={GenderEmojis[Gender.FEMALE]} label={strings.female} />
      ),
      checkedColor: colors.esquentaPink,
      onSelect: () => setGender(Gender.FEMALE),
    },
  ];

  const sexualitySelectColor = useMemo(
    () => (gender === Gender.MALE ? colors.esquentaBlue : colors.esquentaPink),
    [gender],
  );

  const homosexualLabel = useMemo(
    () => HomosexualPreferenceDescriptions(strings)[gender],
    [gender],
  );

  const sexualityOptions = [
    {
      value: `${SexualPreference.HETEROSEXUAL}`,
      label: strings.straight,
      icon: (
        <Emoji
          symbol={SexualityEmojis[SexualPreference.HETEROSEXUAL]}
          label={strings.straight}
        />
      ),
      checkedColor: sexualitySelectColor,
      onSelect: () => setSexualPreference(SexualPreference.HETEROSEXUAL),
    },
    {
      value: `${SexualPreference.BISEXUAL}`,
      label: strings.bisexual,
      icon: (
        <Emoji
          symbol={SexualityEmojis[SexualPreference.BISEXUAL]}
          label={strings.bisexual}
        />
      ),
      checkedColor: sexualitySelectColor,
      onSelect: () => setSexualPreference(SexualPreference.BISEXUAL),
    },
    {
      value: `${SexualPreference.HOMOSEXUAL}`,
      label: homosexualLabel,
      icon: (
        <Emoji
          symbol={SexualityEmojis[SexualPreference.HOMOSEXUAL]}
          label={homosexualLabel}
        />
      ),
      checkedColor: sexualitySelectColor,
      onSelect: () => setSexualPreference(SexualPreference.HOMOSEXUAL),
    },
  ];

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
        size="md"
      >
        <ModalOverlay />
        <ModalContent
          bgColor={colors.esquentaPink}
          minHeight="auto"
          borderRadius={'xl'}
        >
          <ModalHeader>
            {player?._id ? strings.editPlayer : strings.createPlayer}{' '}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6} bgColor={colors.esquentaBlack}>
            <FormControl>
              <FormLabel>{strings.name}</FormLabel>
              <Input
                ref={initialRef}
                placeholder={strings.playerName}
                maxLength={20}
                value={name}
                onChange={(ev) => setName(ev.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>{strings.gender}</FormLabel>
              <IconRadioGroup
                name="gender"
                defaultValue={gender}
                options={genderOptions}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>{strings.sexuality}</FormLabel>
              <IconRadioGroup
                name="sexuality"
                defaultValue={sexualPreference}
                options={sexualityOptions}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter bgColor="todBlack" marginBottom="20px">
            <EsquentaButton
              colorType="pink"
              onClick={onClose}
              leftIcon={<Icon as={IoMdArrowRoundBack} />}
            >
              {strings.cancel}
            </EsquentaButton>
            <EsquentaButton
              colorType="blue"
              ml={3}
              onClick={savePlayer}
              rightIcon={<Icon as={FiUserCheck} />}
              isLoading={loading}
            >
              {strings.save}
            </EsquentaButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
