import { Gender, SexualPreference } from '@global/constants';

export const langDefEnUs = {
  lang: 'en-us',
  ptBr: 'Portuguese (BR)',
  enUs: 'English (US)',
  footerPrefix: 'The game is developed with lots of',
  and: 'and',
  by: 'by',
  rules: 'Rules',
  rulesArr: [
    'You must be over 18+ years old.',
    "Nobody can be forced to play. That's an abusive behavior.",
    'Trust each other.',
    'Everyone has the right to quit the game if feel uncomfortable.',
    'Everyone has the right to refuse to answer a question or fulfill a dare.',
    'Before starting the game, agree on personal rules that are acceptable for everyone.',
    'Have a drink and have fun 😘',
  ],
  startGame: 'Start Game',
  players: 'Players',
  noPLayers: 'No Players!',
  addPlayerInstruction:
    "Click on 'Add Player' button below and start adding your players!",
  addPlayer: 'Add Player',
  back: 'Back',
  createPlayer: 'Create Player',
  editPlayer: 'Edit Player',
  name: 'Name',
  playerName: 'Player Name',
  gender: 'Gender',
  sexuality: 'Sexual Preference',
  cancel: 'Cancel',
  save: 'Save',
  male: 'Male',
  female: 'Female',
  straight: 'Straight',
  bisexual: 'Bisexual',
  gay: 'Gay',
  lesbian: 'Lesbian',
  man: 'Man',
  woman: 'Woman',
  min2players: 'You need at least 2 players to start a game',
  removePlayerQuestion: 'You really want to remove player',
  confirmPlayerRemove: 'Yes, delete it!',
  cancelPlayerRemove: 'No, sorry :(',
  bottleClick: 'Click on the Bottle to Spin',
  playerOrder: 'Player Order',
  exit: 'Exit',
  goToPlayersQuestion: 'You really want to go to Players list?',
  goToPlayersConfirm: 'Yes, take me there!',
  goToPlayersCancel: "No, let's keep playing!",
  level: 'Level',
  exitGameQuestion: 'You really want to quit game?',
  exitGameDescription:
    'Level will turn back to Ice Breaker and all answered taks will be available again.',
  exitKeepingPlayers: 'Yes, but keep players',
  exitDeletingPlayers: 'Yes, and delete players',
  exitNo: 'No, keep playing :D',
  levelSelector: 'Level Selector',
  random: 'Random',
  randomDescription: 'The Player on Turn is chosen randomly by the bottle',
  sequence: 'Sequence',
  sequenceDescription:
    'The Player on Turn follows the list and after the last one, goes back to the first',
  truth: 'Truth',
  dare: 'Dare',
  aOneMale: 'A',
  aOneFemale: 'A',
  for: 'for',
  choose: 'choose',
  newMale: 'New',
  newFemale: 'New',
  done: 'Done',
  skip: 'Skip',
  noTruths: 'Only Dares in this level',
  [Gender.FEMALE + SexualPreference.BISEXUAL]: 'Bisexual Woman',
  [Gender.MALE + SexualPreference.BISEXUAL]: 'Bisexual Man',
  [Gender.FEMALE + SexualPreference.HOMOSEXUAL]: 'Lesbian Woman',
  [Gender.MALE + SexualPreference.HOMOSEXUAL]: 'Gay Man',
  [Gender.FEMALE + SexualPreference.HETEROSEXUAL]: 'Straight Woman',
  [Gender.MALE + SexualPreference.HETEROSEXUAL]: 'Straight Man',
};
