export function MaleIcon() {
  return (
    <svg
      width="491"
      height="491"
      viewBox="0 0 491 491"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1414_81"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="491"
        height="491"
      >
        <circle cx="245.5" cy="245.5" r="245.5" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1414_81)">
        <path
          d="M147.967 179.61C164.319 201.842 164.987 213.842 155.007 240.17C138.807 245.97 118.127 233.29 112.807 216.93C108.647 204.21 110.607 191.73 117.807 184.25L117.771 183.922C119.893 181.553 122.48 179.647 125.371 178.322C132.827 176.136 140.808 176.591 147.967 179.61ZM345.159 179.07C350.479 175.95 362.847 176.578 367.767 178.698C371.016 180.13 373.87 182.325 376.087 185.098C382.647 192.658 384.287 204.698 380.287 216.938C375.007 233.298 354.287 245.978 338.127 240.178C330.987 219.51 330.487 203.638 345.159 179.07Z"
          fill="#AD4F4B"
        />
        <path
          d="M149.371 213.57C148.75 213.57 148.138 213.425 147.582 213.148C147.027 212.87 146.544 212.467 146.171 211.97C144.093 209.604 141.528 207.714 138.652 206.43C135.776 205.146 132.656 204.498 129.507 204.53C128.456 204.537 127.442 204.14 126.676 203.421C125.909 202.702 125.448 201.715 125.387 200.666C125.351 199.61 125.735 198.582 126.455 197.808C127.174 197.034 128.171 196.576 129.227 196.534C133.668 196.45 138.071 197.363 142.113 199.205C146.154 201.048 149.731 203.774 152.579 207.182C153.025 207.776 153.296 208.483 153.363 209.223C153.43 209.963 153.289 210.706 152.957 211.371C152.625 212.035 152.114 212.594 151.482 212.985C150.85 213.375 150.122 213.582 149.379 213.582L149.371 213.57ZM343.723 213.57C342.98 213.57 342.252 213.363 341.62 212.973C340.988 212.582 340.478 212.023 340.145 211.359C339.813 210.694 339.673 209.951 339.739 209.211C339.806 208.471 340.077 207.764 340.523 207.17C343.38 203.761 346.963 201.036 351.011 199.194C355.059 197.352 359.469 196.44 363.915 196.526C364.44 196.545 364.957 196.667 365.435 196.885C365.913 197.103 366.344 197.413 366.702 197.798C367.06 198.183 367.339 198.634 367.523 199.126C367.707 199.619 367.792 200.143 367.773 200.668C367.754 201.193 367.632 201.71 367.414 202.188C367.196 202.666 366.886 203.097 366.501 203.455C366.116 203.813 365.665 204.092 365.173 204.276C364.68 204.46 364.156 204.545 363.631 204.526C360.477 204.498 357.353 205.148 354.471 206.432C351.589 207.715 349.017 209.603 346.927 211.966C346.554 212.464 346.071 212.868 345.515 213.147C344.959 213.425 344.345 213.57 343.723 213.57Z"
          fill="#8E3C3C"
        />
        <path
          d="M317.527 364.571C304.367 387.131 273.807 393.011 246.447 393.011C213.807 393.011 186.167 382.331 175.087 364.771C190.567 357.531 208.127 349.931 213.327 326.291C214.407 321.27 214.93 316.146 214.887 311.011H278.087C278.055 315.947 278.578 320.871 279.647 325.691C284.767 348.331 301.887 358.131 317.527 364.571Z"
          fill="#AD4F4B"
        />
        <path
          d="M397.619 430.61C388.707 416.402 363.159 385.878 317.899 364.758L317.535 364.59C296.207 376.382 271.975 386.498 246.527 386.634C220.323 386.778 200.491 374.234 177.099 363.834L175.075 364.778C129.815 385.898 104.275 416.422 95.3551 430.63C84.9551 447.214 94.5831 456.21 109.471 464.422C124.827 472.85 171.547 490.61 246.487 490.61C321.427 490.61 368.147 472.858 383.503 464.406C398.391 456.186 408.015 447.19 397.619 430.61Z"
          fill="#6346B7"
        />
        <path
          d="M226.127 389.89C202.844 389.515 179.97 383.707 159.327 372.93C146.327 365.93 137.327 357.81 135.727 350.57C132.807 337.05 151.927 307.53 165.687 306.01C178.327 304.65 196.407 315.53 212.247 330.57C230.927 348.33 246.487 371.85 246.487 387.97C239.787 389.323 232.963 389.967 226.127 389.89ZM266.847 389.89C260.012 389.967 253.188 389.323 246.487 387.97C246.487 371.85 262.047 348.33 280.727 330.57C296.567 315.53 314.647 304.65 327.287 306.01C341.047 307.53 360.167 337.05 357.247 350.57C355.647 357.81 346.647 365.93 333.647 372.93C313.005 383.707 290.131 389.515 266.847 389.89Z"
          fill="#513D8B"
        />
        <path
          d="M279.647 325.691C269.591 332.001 257.918 335.253 246.047 335.051C234.542 335.201 223.218 332.17 213.327 326.291C214.407 321.27 214.93 316.146 214.887 311.011H278.087C278.055 315.947 278.579 320.871 279.647 325.691Z"
          fill="#8E3C3C"
        />
        <path
          d="M345.167 179.61C345.324 189.617 344.843 199.624 343.727 209.57C342.496 219.876 340.626 230.096 338.127 240.17C330.327 272.69 313.287 298.53 278.087 311.01C271.813 313.204 265.381 314.916 258.847 316.13C250.721 317.69 242.373 317.69 234.247 316.13C227.668 314.921 221.195 313.196 214.887 310.97C179.807 298.49 162.807 272.69 155.007 240.17C149.959 220.39 147.591 200.021 147.967 179.61C147.967 165.81 134.895 109.934 173.727 68.0102C221.815 16.0982 296.879 28.0102 324.487 66.5102C356.423 111.054 344.967 142.61 345.167 179.61Z"
          fill="#C96355"
        />
        <path
          d="M196.635 192.57H197.059C201.422 192.57 204.959 189.033 204.959 184.67V180.086C204.959 175.723 201.422 172.186 197.059 172.186H196.635C192.272 172.186 188.735 175.723 188.735 180.086V184.67C188.735 189.033 192.272 192.57 196.635 192.57Z"
          fill="#773F46"
        />
        <path
          d="M207.595 181.183C206.184 179.531 204.301 178.352 202.199 177.803C199.51 176.942 196.699 176.527 193.875 176.575C191 176.554 188.134 176.927 185.359 177.683C182.643 178.826 179.809 179.665 176.907 180.183L176.527 180.211C176.184 180.235 175.839 180.192 175.513 180.083C175.186 179.975 174.884 179.802 174.624 179.577C174.364 179.352 174.151 179.077 173.997 178.769C173.844 178.461 173.752 178.126 173.727 177.783C173.702 177.398 173.761 177.011 173.899 176.651C174.805 174.934 176.071 173.433 177.612 172.253C179.152 171.072 180.93 170.239 182.823 169.811C186.364 168.57 190.08 167.903 193.831 167.835C197.639 167.785 201.427 168.387 205.031 169.615C206.981 170.303 208.815 171.282 210.471 172.519C212.431 173.931 213.963 175.857 214.899 178.083C215.283 178.984 215.318 179.995 214.996 180.92C214.674 181.844 214.019 182.616 213.159 183.083C212.299 183.551 211.295 183.682 210.344 183.449C209.393 183.217 208.563 182.639 208.015 181.827L207.595 181.183Z"
          fill="#6D383E"
        />
        <path
          d="M296.483 172.194H296.059C291.696 172.194 288.159 175.731 288.159 180.094V184.678C288.159 189.041 291.696 192.578 296.059 192.578H296.483C300.846 192.578 304.383 189.041 304.383 184.678V180.094C304.383 175.731 300.846 172.194 296.483 172.194Z"
          fill="#773F46"
        />
        <path
          d="M278.211 178.074C279.148 175.848 280.68 173.923 282.639 172.51C284.296 171.274 286.13 170.295 288.079 169.606C291.684 168.378 295.472 167.776 299.279 167.826C303.03 167.894 306.747 168.561 310.287 169.802C312.18 170.232 313.957 171.067 315.496 172.249C317.035 173.431 318.3 174.932 319.203 176.65C319.362 177.06 319.415 177.503 319.357 177.939C319.299 178.375 319.133 178.789 318.873 179.144C318.613 179.498 318.269 179.782 317.871 179.968C317.472 180.154 317.034 180.238 316.595 180.21L316.195 180.182C313.294 179.664 310.459 178.825 307.743 177.682C304.968 176.926 302.103 176.553 299.227 176.574C296.404 176.527 293.592 176.941 290.903 177.802C288.802 178.35 286.92 179.53 285.511 181.182L285.079 181.814C284.531 182.623 283.701 183.199 282.752 183.431C281.802 183.662 280.801 183.531 279.942 183.065C279.083 182.598 278.429 181.829 278.106 180.906C277.783 179.984 277.815 178.974 278.195 178.074H278.211Z"
          fill="#6D383E"
        />
        <path
          d="M246.523 258.79H245.355C239.383 258.678 225.259 257.59 223.075 249.826C222.933 249.32 222.892 248.792 222.954 248.27C223.016 247.748 223.181 247.244 223.438 246.786C223.695 246.327 224.04 245.924 224.453 245.6C224.866 245.275 225.339 245.034 225.845 244.892C226.351 244.75 226.88 244.709 227.401 244.771C227.923 244.834 228.427 244.998 228.886 245.255C229.344 245.513 229.747 245.858 230.072 246.271C230.396 246.683 230.637 247.156 230.779 247.662C231.579 248.814 238.131 251.022 248.063 250.782C256.915 250.562 261.763 248.498 262.391 247.442C262.552 246.945 262.809 246.484 263.149 246.087C263.488 245.689 263.903 245.363 264.37 245.127C264.836 244.89 265.344 244.748 265.865 244.709C266.387 244.67 266.911 244.734 267.407 244.898C268.418 245.233 269.256 245.954 269.737 246.905C270.218 247.855 270.304 248.957 269.975 249.97C267.403 257.946 252.615 258.79 246.523 258.79ZM246.527 226.49C246.399 226.49 233.767 226.186 233.767 219.41C233.764 218.527 234.053 217.668 234.59 216.966C235.126 216.265 235.88 215.761 236.733 215.533C237.586 215.304 238.49 215.365 239.305 215.704C240.121 216.044 240.8 216.644 241.239 217.41C242.934 218.045 244.719 218.407 246.527 218.482C248.306 218.403 250.061 218.042 251.727 217.414C252.165 216.648 252.845 216.048 253.66 215.708C254.475 215.369 255.38 215.308 256.233 215.537C257.086 215.765 257.84 216.269 258.376 216.97C258.913 217.672 259.202 218.531 259.199 219.414C259.207 226.186 246.651 226.49 246.527 226.49Z"
          fill="#8E3C3C"
        />
        <path
          d="M210.251 453.251C210.039 453.25 209.828 453.234 209.619 453.203C208.571 453.036 207.633 452.459 207.01 451.6C206.388 450.741 206.132 449.67 206.299 448.623C209.935 428.173 215.311 408.07 222.367 388.535C223.663 384.963 225.039 381.407 226.491 377.767C226.68 377.271 226.966 376.818 227.332 376.434C227.698 376.049 228.136 375.742 228.622 375.529C229.108 375.316 229.632 375.202 230.162 375.193C230.693 375.184 231.22 375.281 231.712 375.478C232.205 375.675 232.654 375.968 233.032 376.34C233.411 376.712 233.711 377.155 233.917 377.644C234.122 378.134 234.228 378.659 234.228 379.189C234.229 379.72 234.124 380.245 233.919 380.735C232.499 384.295 231.155 387.775 229.887 391.259C223.029 410.326 217.781 429.935 214.199 449.879C214.05 450.819 213.57 451.674 212.846 452.292C212.123 452.91 211.203 453.25 210.251 453.251ZM282.723 453.251C281.771 453.25 280.851 452.91 280.128 452.292C279.404 451.674 278.924 450.819 278.775 449.879C275.188 429.931 269.939 410.319 263.083 391.247C261.794 387.723 260.451 384.218 259.055 380.735C258.86 380.247 258.763 379.725 258.77 379.2C258.777 378.674 258.887 378.155 259.094 377.672C259.301 377.19 259.602 376.752 259.978 376.385C260.354 376.019 260.799 375.73 261.287 375.535C261.775 375.34 262.297 375.243 262.822 375.25C263.347 375.256 263.866 375.367 264.349 375.574C264.832 375.781 265.269 376.081 265.636 376.458C266.003 376.834 266.292 377.279 266.487 377.767C267.939 381.407 269.315 384.967 270.607 388.523C277.663 408.058 283.038 428.161 286.675 448.611C286.841 449.658 286.584 450.728 285.962 451.586C285.339 452.444 284.402 453.02 283.355 453.187C283.146 453.226 282.935 453.247 282.723 453.251Z"
          fill="#F7F0DF"
        />
        <path
          d="M218.679 164.391C213.902 163.483 209.187 162.281 204.559 160.791C199.947 159.479 195.319 158.279 190.687 157.439C186.164 156.518 181.558 156.073 176.943 156.111C172.774 155.954 168.666 157.148 165.231 159.515L165.087 159.635C164.485 160.142 163.708 160.393 162.923 160.334C162.137 160.276 161.406 159.912 160.886 159.321C160.366 158.73 160.098 157.959 160.139 157.173C160.18 156.387 160.528 155.648 161.107 155.115C165.422 151.423 170.873 149.322 176.551 149.163C181.703 148.862 186.872 149.139 191.963 149.987C196.963 150.787 201.835 151.919 206.643 153.167C211.433 154.313 216.111 155.883 220.623 157.859H220.659C221.416 158.205 222.023 158.815 222.365 159.574C222.708 160.334 222.763 161.192 222.522 161.989C222.28 162.787 221.757 163.47 221.051 163.911C220.344 164.353 219.501 164.523 218.679 164.391ZM272.487 157.843C277.005 155.866 281.69 154.296 286.487 153.151C291.287 151.903 296.167 150.751 301.167 149.971C306.257 149.123 311.427 148.846 316.579 149.147C322.256 149.306 327.707 151.407 332.023 155.099C332.591 155.634 332.929 156.369 332.965 157.148C333.002 157.928 332.735 158.691 332.22 159.278C331.705 159.864 330.983 160.228 330.205 160.292C329.427 160.357 328.655 160.117 328.051 159.623L327.899 159.499C324.463 157.132 320.355 155.938 316.187 156.095C311.572 156.058 306.965 156.503 302.443 157.423C297.811 158.263 293.183 159.463 288.575 160.775C283.945 162.264 279.228 163.467 274.451 164.375C273.626 164.515 272.777 164.35 272.065 163.911C271.352 163.472 270.824 162.788 270.579 161.988C270.334 161.187 270.389 160.325 270.733 159.562C271.078 158.799 271.688 158.188 272.451 157.843H272.487Z"
          fill="#6D383E"
        />
        <path
          d="M377.739 119.974C381.795 140.63 379.803 159.21 376.087 185.09C373.79 182.421 370.88 180.349 367.607 179.05C362.687 176.93 350.487 176.49 345.167 179.61C345.047 159.57 335.207 129.77 325.647 106.53C324.297 103.246 322.095 100.38 319.269 98.2303C316.442 96.0802 313.093 94.7232 309.567 94.2993C306.041 93.8754 302.466 94.3999 299.21 95.8186C295.955 97.2374 293.137 99.4991 291.047 102.37C270.127 131.29 233.767 157.45 168.047 159.29C163.967 159.41 164.287 145.49 169.447 126.93C169.475 126.712 169.529 126.497 169.607 126.29C163.966 133.32 159.409 141.153 156.087 149.53C151.646 159.007 148.898 169.187 147.967 179.61C142.607 176.49 130.447 176.93 125.487 179.05C122.595 180.217 119.98 181.975 117.807 184.21C116.382 172.447 116.991 160.527 119.607 148.97C119.807 148.05 122.247 139.65 122.247 139.65C120.419 140.804 118.379 141.579 116.247 141.93C115.235 142.03 114.215 141.864 113.287 141.45C109.847 139.85 107.367 133.97 110.247 117.49C118.727 69.1704 138.327 44.9704 173.567 22.8904C200.295 6.13044 255.379 -17.5736 305.891 24.9704C306.588 25.5524 307.463 25.8796 308.371 25.8984C361.947 27.0104 403.319 117.546 378.915 118.57C378.74 118.571 378.568 118.61 378.411 118.684C378.253 118.759 378.113 118.866 378.001 119C377.889 119.134 377.808 119.29 377.763 119.458C377.717 119.627 377.709 119.803 377.739 119.974Z"
          fill="#773F46"
        />
        <path
          d="M228.287 47.8909C192.647 67.4909 171.527 120.211 169.607 126.291C163.967 133.32 159.41 141.153 156.087 149.531C151.247 110.771 186.087 62.0109 224.447 40.8509C224.91 40.5988 225.417 40.4402 225.94 40.3841C226.464 40.3281 226.993 40.3757 227.499 40.5243C228.004 40.6728 228.475 40.9194 228.885 41.25C229.294 41.5806 229.635 41.9887 229.887 42.4509C230.139 42.9132 230.298 43.4206 230.354 43.9441C230.41 44.4677 230.362 44.9971 230.214 45.5023C230.065 46.0074 229.819 46.4784 229.488 46.8882C229.158 47.2981 228.75 47.6388 228.287 47.8909ZM135.047 108.611C129.528 118.396 125.23 128.82 122.247 139.651C120.42 140.805 118.38 141.58 116.247 141.931C115.047 133.451 121.007 116.011 128.247 104.411C128.826 103.558 129.713 102.962 130.721 102.747C131.729 102.531 132.781 102.713 133.658 103.255C134.535 103.797 135.169 104.656 135.428 105.654C135.687 106.652 135.55 107.711 135.047 108.611Z"
          fill="#673D3D"
        />
      </g>
    </svg>
  );
}
