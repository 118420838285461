export function PlayersIcon() {
  return (
    <svg
      width="508"
      viewBox="0 0 508 435"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M114.186 151.329C123.773 151.329 131.343 143.19 130.65 133.628L130.346 129.429H98.0396C98.0404 129.45 97.9116 131.132 97.7259 133.557C96.99 143.143 104.571 151.329 114.186 151.329ZM75.6324 94.3846L28.1305 7.78271C24.15 0.525329 15.0401 -2.1309 7.78271 1.84963C0.52533 5.83017 -2.13091 14.94 1.84963 22.1974L48.4512 107.159L65.5408 108.744L75.6324 94.3846Z"
        fill="#BA6746"
      />
      <path
        d="M247.956 174.378C244.256 166.789 235.105 163.637 227.516 167.337L184.934 188.098C181.432 178.247 183.165 183.246 183.165 183.246L164.913 177.851L153.988 190.434C153.988 190.434 161.4 213.149 161.873 214.474C162.596 216.499 163.738 218.348 165.225 219.901C166.711 221.454 168.509 222.676 170.499 223.488C172.49 224.299 174.63 224.681 176.778 224.61C178.927 224.538 181.036 224.014 182.969 223.072L240.915 194.819C248.504 191.118 251.656 181.966 247.956 174.378Z"
        fill="#BA6746"
      />
      <path
        d="M92.6762 434.047C82.7676 434.047 74.7354 426.015 74.7354 416.106V272.704C74.7354 262.795 82.7676 254.763 92.6762 254.763C102.585 254.763 110.617 262.795 110.617 272.704V416.105C110.617 426.015 102.585 434.047 92.6762 434.047Z"
        fill="#BA6746"
      />
      <path
        d="M136.304 434.047C126.396 434.047 118.363 426.015 118.363 416.106V272.704C118.363 262.795 126.396 254.763 136.304 254.763C146.213 254.763 154.245 262.795 154.245 272.704V416.105C154.245 426.015 146.213 434.047 136.304 434.047Z"
        fill="#BA6746"
      />
      <path
        d="M152.571 186.429C153.035 187.743 153.508 189.081 153.988 190.434L183.165 183.246C180.062 174.511 177.211 166.401 174.959 159.848C168.023 139.663 156.392 129.429 140.39 129.429H130.347C130.735 138.15 122.642 145.578 114.193 145.578C105.138 145.578 98.4337 138.179 98.0395 129.429H94.8542L75.6315 94.3838L48.4502 107.158L72.9371 151.8L55.1726 297.763C54.1764 302.905 58.14 307.657 63.3261 307.657H165.652C170.857 307.657 174.802 302.91 173.805 297.763L152.571 186.429Z"
        fill="#6ABF8C"
      />
      <path
        d="M143.168 100.446C149.722 84.6377 142.22 66.5096 126.413 59.9553C110.605 53.401 92.4766 60.9025 85.9223 76.7103C79.368 92.5182 86.8695 110.646 102.677 117.201C118.485 123.755 136.613 116.253 143.168 100.446Z"
        fill="#BA6746"
      />
      <path
        d="M356.481 433.658C347.021 433.658 339.353 425.989 339.353 416.529V279.618C339.353 270.158 347.021 262.489 356.481 262.489C365.941 262.489 373.61 270.158 373.61 279.618V416.529C373.61 425.989 365.941 433.658 356.481 433.658ZM398.133 433.658C388.673 433.658 381.005 425.989 381.005 416.529V279.618C381.005 270.158 388.673 262.489 398.133 262.489C407.593 262.489 415.262 270.158 415.262 279.618V416.529C415.263 425.989 407.594 433.658 398.133 433.658Z"
        fill="#FFB69E"
      />
      <path
        d="M377.308 133.769C393.646 133.769 406.891 120.524 406.891 104.186C406.891 87.8474 393.646 74.6025 377.308 74.6025C360.969 74.6025 347.725 87.8474 347.725 104.186C347.725 120.524 360.969 133.769 377.308 133.769Z"
        fill="#FFB69E"
      />
      <path
        d="M333.235 219.281C334.684 215.248 336.131 211.213 337.573 207.177L329.234 191.888L311.841 194.372L272.534 174.362C265.508 170.786 256.914 173.581 253.338 180.606C249.761 187.631 252.557 196.226 259.582 199.803L313.328 227.164C315.133 228.082 317.112 228.607 319.135 228.702C321.158 228.797 323.177 228.46 325.06 227.715C326.943 226.969 328.646 225.832 330.055 224.378C331.464 222.924 332.548 221.186 333.235 219.281ZM492.57 201.227L445.831 202.88C444.872 200.196 443.084 195.172 443.084 195.172L427.565 192.65L417.668 208.708C417.668 208.708 421.133 218.598 422.476 222.336C423.498 225.184 425.4 227.634 427.905 229.331C430.41 231.028 433.39 231.885 436.415 231.778L493.579 229.758C501.457 229.48 507.618 222.867 507.34 214.989C507.06 207.109 500.475 200.944 492.57 201.227ZM389.95 143.198H362.851L362.465 147.799C361.777 156.007 368.253 163.049 376.49 163.049C384.96 163.049 391.511 155.617 390.453 147.213C390.157 144.864 389.95 143.21 389.95 143.198Z"
        fill="#FFB69E"
      />
      <path
        d="M413.223 194.99L417.613 208.727L443.083 195.173C439.776 185.866 437.081 178.185 435.039 172.241C428.416 152.97 417.313 143.199 402.035 143.199H389.949C390.169 150.45 383.432 156.594 376.394 156.594C368.85 156.594 363.256 150.473 362.85 143.199H352.579C337.301 143.199 326.198 152.97 319.575 172.241C317.042 179.634 314.464 187.012 311.842 194.373L337.573 207.178L341.507 194.554L320.675 303.914C319.724 308.824 323.508 313.361 328.46 313.361H426.154C431.123 313.361 434.89 308.828 433.939 303.914L413.223 194.99Z"
        fill="#FF6473"
      />
      <path
        d="M313.696 434.315C307.111 434.315 300.754 430.739 297.505 424.494L256.354 345.43C255.795 344.357 255.345 343.231 255.009 342.068L238.099 283.485C235.306 273.808 240.886 263.699 250.563 260.906C260.238 258.109 270.348 263.694 273.141 273.37L289.547 330.206L329.857 407.655C334.506 416.589 331.034 427.601 322.1 432.251C319.506 433.606 316.623 434.314 313.696 434.315Z"
        fill="#414B82"
      />
      <path
        d="M194.106 434.315C184.035 434.315 175.87 426.151 175.87 416.079V333.229C175.87 331.106 176.241 329 176.965 327.005L201.75 258.749C205.187 249.282 215.648 244.393 225.116 247.832C234.583 251.269 239.471 261.731 236.033 271.197L212.343 336.438V416.079C212.343 426.151 204.178 434.315 194.106 434.315Z"
        fill="#5F6E9B"
      />
      <path
        d="M279.393 129.789C285.278 114.294 277.489 96.9624 261.995 91.0767C246.501 85.191 229.169 92.9803 223.283 108.475C217.397 123.969 225.187 141.301 240.681 147.186C256.175 153.072 273.507 145.283 279.393 129.789Z"
        fill="#CF886B"
      />
      <path
        d="M319.804 123.511L314.268 54.2035C313.662 46.6298 307.055 40.978 299.456 41.5835C291.882 42.1882 286.232 48.8197 286.836 56.3942L292.06 121.791L284.01 137.417L291.195 149.175H308.908L318.319 130.907C319.493 128.628 320.008 126.067 319.804 123.511ZM251.793 184.451C259.829 185.23 266.866 179.089 267.183 171.022L267.37 166.237L240.35 162.108L239.311 168.053C237.905 176.111 243.653 183.662 251.793 184.451ZM216.498 130.632L207.338 117.762L212.42 63.3242C213.125 55.7581 207.564 49.0521 199.998 48.3456C192.426 47.6408 185.726 53.2002 185.02 60.7663L179.462 120.295C179.155 123.58 180.039 126.865 181.953 129.552L192.418 144.255L209.634 146.377L216.498 130.632Z"
        fill="#CF886B"
      />
      <path
        d="M284.01 137.417L269.032 166.49L267.371 166.237C266.428 172.603 260.283 177.671 253.878 177.671C246.24 177.671 240.584 171.429 240.254 164.047C240.224 163.389 240.271 162.745 240.35 162.108L238.725 161.859L216.499 130.631L192.418 144.254L215.07 176.082C215.052 176.188 215.03 176.294 215.013 176.402L202.048 259.584C200.151 271.749 208.521 283.134 220.699 284.952L252.816 289.748C264.945 291.56 276.244 283.191 278.047 271.06L290.988 183.959L308.908 149.175L284.01 137.417Z"
        fill="#64AFFF"
      />
    </svg>
  );
}
