export function LevelSelectorIcon() {
  return (
    <svg
      width="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M357.66 302.536V104.024C357.66 77.1071 334.099 55.2891 305.043 55.2891C275.981 55.2891 252.424 77.1071 252.424 104.024V302.536C228.881 317.876 213.499 343.234 213.499 371.923C213.499 418.749 254.485 456.711 305.042 456.711C355.601 456.711 396.585 418.749 396.585 371.923C396.586 343.235 381.201 317.877 357.66 302.536Z"
        fill="#04B9FB"
      />
      <path
        d="M305.043 428.942C270.18 428.942 241.82 402.671 241.82 370.384C241.82 345.865 258.269 324.034 282.955 315.518V104.024C282.955 92.7414 292.859 83.5664 305.043 83.5664C317.221 83.5664 327.131 92.7414 327.131 104.024V315.519C351.811 324.034 368.266 345.865 368.266 370.382C368.266 402.671 339.902 428.942 305.043 428.942Z"
        fill="#F3E8D7"
      />
      <path
        d="M282.955 141.431V315.519C258.269 324.034 241.82 345.865 241.82 370.382C241.82 402.671 270.179 428.94 305.043 428.94C339.902 428.94 368.266 402.672 368.266 370.382C368.266 345.863 351.811 324.033 327.131 315.519V141.431H282.955Z"
        fill="#FC4E51"
      />
      <path
        d="M430.539 196.586H392.596C388.981 196.586 386.055 193.872 386.055 190.528C386.055 187.184 388.981 184.469 392.596 184.469H430.539C434.15 184.469 437.078 187.183 437.078 190.528C437.078 193.873 434.15 196.586 430.539 196.586Z"
        fill="#EB83B5"
      />
      <path
        d="M409.822 147.488H392.593C388.98 147.488 386.054 144.776 386.054 141.431C386.054 138.086 388.98 135.373 392.593 135.373H409.822C413.431 135.373 416.363 138.086 416.363 141.431C416.363 144.776 413.434 147.488 409.822 147.488Z"
        fill="#EB83B5"
      />
      <path
        d="M415.928 98.3924H392.596C388.981 98.3924 386.055 95.6804 386.055 92.3354C386.055 88.9904 388.981 86.2764 392.596 86.2764H415.928C419.541 86.2764 422.467 88.9904 422.467 92.3354C422.467 95.6804 419.541 98.3924 415.928 98.3924Z"
        fill="#EB83B5"
      />
      <path
        d="M415.928 294.778H392.596C388.981 294.778 386.055 292.064 386.055 288.72C386.055 285.373 388.981 282.663 392.596 282.663H415.928C419.541 282.663 422.467 285.373 422.467 288.72C422.467 292.064 419.541 294.778 415.928 294.778Z"
        fill="#EB83B5"
      />
      <path
        d="M409.822 245.682H392.593C388.98 245.682 386.054 242.968 386.054 239.623C386.054 236.28 388.98 233.565 392.593 233.565H409.822C413.431 233.565 416.363 236.279 416.363 239.623C416.363 242.969 413.434 245.682 409.822 245.682Z"
        fill="#EB83B5"
      />
      <path
        d="M327.131 315.519V141.431H282.955V315.519C282.955 315.519 313.49 328.643 313.49 370.382C313.49 393.591 298.84 413.688 277.652 423.156C285.943 426.862 295.234 428.938 305.039 428.938C339.9 428.938 368.266 402.67 368.266 370.38C368.266 345.863 351.811 324.035 327.131 315.519Z"
        fill="#E5394B"
      />
      <path
        d="M208.914 146.151C208.545 143.508 205.17 142.282 202.979 144.009C200.66 145.836 198.204 147.521 195.63 149.046C194.354 149.802 192.71 148.871 192.858 147.475C197.473 103.903 162.445 70.1287 132.046 70.0001C119.756 69.9479 147.925 97.5527 115.836 125.787C112.824 128.437 110.21 130.953 107.937 133.339C107.181 134.134 105.819 134.08 105.144 133.224C101.159 128.168 97.9727 122.535 95.7626 116.484C94.8486 113.98 91.3032 113.442 89.5312 115.512C80.4237 126.154 74.9915 139.616 75 154.257C75.0209 189.183 105.637 217.088 143.305 216.544C179.944 216.015 209.473 188.314 209.473 154.221C209.472 151.486 209.283 148.792 208.914 146.151Z"
        fill="#FC4E51"
      />
      <path
        d="M209.473 154.221C209.473 188.315 179.944 216.014 143.306 216.545C139.959 216.592 136.671 216.418 133.455 216.031C166.456 212.054 191.942 185.898 191.942 154.221C191.942 151.473 191.751 148.768 191.38 146.116C191.009 143.471 187.641 142.283 185.448 144.009C184.132 145.047 182.772 146.037 181.371 146.979C178.892 148.647 175.446 146.862 175.614 144.027C177.695 108.779 153.975 80.528 128.772 72.3778C128.981 70.8322 129.893 69.99 132.047 70.0001C162.445 70.1272 197.474 103.9 192.857 147.474C192.711 148.859 194.342 149.808 195.607 149.06C198.19 147.53 200.653 145.842 202.979 144.01C205.169 142.283 208.541 143.471 208.911 146.117C209.282 148.767 209.473 151.472 209.473 154.221Z"
        fill="#E5394B"
      />
      <path
        d="M186.399 169.923C186.155 168.182 183.922 167.357 182.48 168.494C181.268 169.45 180.001 170.346 178.682 171.178C177.441 171.962 175.754 171.071 175.869 169.683C178.221 141.366 155.361 119.57 135.486 119.486C127.346 119.452 146.003 137.735 124.75 156.435C122.972 158 121.403 159.493 120.017 160.919C119.239 161.719 117.868 161.635 117.199 160.754C114.778 157.563 112.831 154.04 111.455 150.273C110.853 148.623 108.533 148.224 107.363 149.588C101.385 156.555 97.7876 165.352 97.7045 174.927C97.5106 197.226 117.525 216.223 141.575 216.546C166.471 216.881 186.768 198.271 186.768 175.267C186.769 173.457 186.642 171.672 186.399 169.923Z"
        fill="#FCC16D"
      />
      <path
        d="M186.769 175.268C186.769 197.848 167.211 216.194 142.945 216.545C140.427 216.582 137.96 216.424 135.555 216.095C157.007 213.129 173.461 195.979 173.461 175.268C173.461 173.448 173.331 171.658 173.086 169.899C172.841 168.149 170.608 167.361 169.156 168.503C167.948 169.457 166.684 170.351 165.369 171.181C164.124 171.967 162.445 171.068 162.56 169.676C164.474 146.577 149.615 127.82 133.285 121.647C133.266 120.251 133.807 119.48 135.485 119.486C155.36 119.57 178.219 141.367 175.868 169.683C175.752 171.071 177.439 171.962 178.681 171.178C179.995 170.349 181.258 169.457 182.468 168.503C183.915 167.361 186.148 168.149 186.394 169.899C186.64 171.658 186.769 173.448 186.769 175.268Z"
        fill="#FCB44D"
      />
      <path
        d="M204.244 116.983C203.25 116.983 202.443 116.235 202.443 115.313V109.256C202.443 103.749 207.259 99.2852 213.199 99.2852C214.194 99.2852 215 100.032 215 100.954V107.012C215 112.518 210.184 116.983 204.244 116.983Z"
        fill="#FC4E51"
      />
      <path
        d="M105.916 93.8069C106.911 93.8069 107.717 93.0594 107.717 92.1373V86.08C107.717 80.5729 102.901 76.1084 96.9611 76.1084C95.9664 76.1084 95.1602 76.8563 95.1602 77.778V83.8345C95.1602 89.3424 99.9758 93.8069 105.916 93.8069Z"
        fill="#FC4E51"
      />
    </svg>
  );
}
