import {
  Language,
  LevelSelector,
  PlayerOrder,
  TaskType,
} from '@global/constants';
import { Game, Level, Player, Task } from '@global/types';
import Axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { RequestException } from './RequestException';

const baseURL = <string>process.env.REACT_APP_API_HOST;

export class TruthOrDareAPI {
  private connector: AxiosInstance;
  private static instance: TruthOrDareAPI;

  private constructor() {
    const connector = Axios.create({
      baseURL: `${baseURL}/v2/games/my-game`,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      validateStatus: () => true,
    });

    connector.interceptors.response.use(
      (response) => response,
      (error) => {
        this.handleAxiosError(error);
      },
    );

    this.connector = connector;
  }

  public static async setup(
    game: Partial<Game>,
    reauth = false,
  ): Promise<TruthOrDareAPI> {
    if (this.instance && !reauth) {
      return this.instance;
    }

    if (game.id && game.token && reauth) {
      await this.reauth(game.id, game.token);
    }

    this.instance = new this();

    return this.instance;
  }

  public static async create(lang: Language): Promise<Game> {
    const { data } = await Axios.post<Game>(
      `${baseURL}/v2/games/`,
      {
        lang,
      },
      { withCredentials: true },
    );

    this.instance = new this();

    return data;
  }

  private static async reauth(id: string, token: string) {
    await Axios.post(
      `${baseURL}/v2/games/auth`,
      {
        id,
        token,
      },
      { withCredentials: true },
    );
  }

  async updateLevels(levels: LevelSelector[]): Promise<Game> {
    const response = await this.connector.patch<Game>(`/levels`, levels);

    return this.validateResponse<Game>(response);
  }

  async updatePlayerOrder(playerOrder: PlayerOrder) {
    const response = await this.connector.patch<Game>(`/`, { playerOrder });

    return this.validateResponse<Game>(response);
  }

  async updateLang(lang: Language) {
    const response = await this.connector.patch<Game>(`/`, { lang });

    return this.validateResponse<Game>(response);
  }

  async getCurrentGame() {
    const response = await this.connector.get<Game>(`/`);

    return this.validateResponse<Game>(response);
  }

  async resetGame() {
    const response = await this.connector.patch<Game>('/reset');

    return this.validateResponse<Game>(response);
  }

  /*
   * Start Players
   */

  async createPlayer(playerDto: Omit<Player, '_id'>) {
    const { name, gender, sexualPreference } = playerDto;
    const response = await this.connector.post(`/players`, {
      name,
      gender,
      sexualPreference,
    });

    return this.validateResponse<Game>(response);
  }

  async updatePlayer(playerDto: Partial<Player>) {
    const { _id, name, gender, sexualPreference } = playerDto;

    const data = { name, gender, sexualPreference };

    const response = await this.connector.patch(`/players/${_id}`, data);

    return this.validateResponse<Game>(response);
  }

  async removePlayer(id: string) {
    const response = await this.connector.delete(`/players/${id}`);

    return this.validateResponse<Game>(response);
  }

  /*
   * End Players
   */

  /*
   * Start Question
   */

  async getTask(playerId: string, type: TaskType) {
    const response = await this.connector.get<Task>('/question', {
      params: { player: playerId, type, format: 'TEMPLATE' },
    });

    return this.validateResponse<Task>(response);
  }

  /*
   * End Question
   */

  /*
   * Start Level
   */

  static async listLevels() {
    const url = `${baseURL}/v2/levels`;

    try {
      const { data } = await Axios.get<Level[]>(url);

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /*
   * End Level
   */

  private validateResponse<T>(response: AxiosResponse): T {
    if (response.status > 199 && response.status < 300) {
      return response.data;
    }

    const error: any = new Error(
      'An error occurred while communicating with Truth or Dare Core',
    );
    error.response = response;

    console.log('RES status:', response.status);
    console.log('RES data:', response.data);

    throw error;
  }

  private handleAxiosError(error: AxiosError) {
    let errorMessage = 'An error ocurred while setting up the API request';
    let statusCode = 0;
    if (error.response) {
      errorMessage = (error.response.data as Record<string, any>).message;
      statusCode = error.response.status;
    } else if (error.request) {
      errorMessage = 'No response was received from API';
    }

    throw new RequestException(errorMessage, statusCode);
  }
}
