export default function WarmIcon() {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="512px"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <g>
        <path
          fill="#FC4E51"
          d="M398.399,180.092L325.523,57.845c-1.852-3.089-5.073-1.824-5.073,1.743l-0.838,22.408v75.737
		c0,3.642-5.065,4.844-6.819,1.619l-26.085-47.951c-1.323-2.433-4.91-2.503-6.335-0.124l-41.223,68.816
		c-33.526,55.966,3.667,125.051,67.378,133.467c4.249,0.562,8.614,0.045,13.084,0.045
		C391.135,313.604,434.161,239.789,398.399,180.092L398.399,180.092z"
        />
        <g>
          <path
            fill="#E5394B"
            d="M400.075,180.092L326.361,57.037c-1.851-3.088-6.749-1.823-6.749,1.743v23.215l58.764,98.097
			c33.915,56.616-4.545,126.646-69.613,133.725c3.541,0.387,7.157,0.596,10.85,0.596
			C391.135,314.412,435.836,239.789,400.075,180.092L400.075,180.092z"
          />
        </g>
        <path
          fill="#B57F5F"
          d="M103.502,451.084c-1.388-1.338-2.428-1.268-3.122-2.916c-2.081-4.945-1.041-10.826,3.122-14.838
		l206.062-198.603l9.766,9.412l8.655,9.957L123.599,451.084C118.05,456.434,109.052,456.434,103.502,451.084L103.502,451.084z"
        />
        <path
          fill="#9F6E50"
          d="M319.612,244.412L113.551,443.016c-3.718,3.582-8.979,4.752-13.718,3.535
		c0.623,2.252,1.836,4.383,3.669,6.148c5.549,5.35,14.547,5.35,20.097,0l206.062-198.602L319.612,244.412z"
        />
        <path
          fill="#FCC16D"
          d="M341.354,265.365c-16.777,16.17-38.812,20.344-51.281,8.325c-2.051-1.978-4.508-4.247-5.707-6.733
		c-6.092-12.623-1.348-30.796,12.67-44.306c14.017-13.51,32.873-18.082,45.97-12.211c2.579,1.156,4.096,3.524,6.147,5.501
		C361.623,227.96,358.13,249.195,341.354,265.365z"
        />
        <path
          fill="#FCB44D"
          d="M349.991,215.135c-2.052-1.978-4.412-3.53-6.99-4.686c6.091,12.623,1.355,30.792-12.661,44.302
		c-14.018,13.51-32.869,18.074-45.966,12.203c1.2,2.484,2.81,4.76,4.862,6.737c12.469,12.019,36.179,8.653,52.955-7.517
		C358.969,250.003,362.46,227.153,349.991,215.135z"
        />
      </g>
    </svg>
  );
}
