import { Paths } from '@global/constants';
import { Home } from '@pages/Home';
import { Play } from '@pages/Play';
import { Players } from '@pages/Players';
import { BrowserRouter, Route, Routes as Router } from 'react-router-dom';

export function Routes() {
  return (
    <BrowserRouter>
      <Router>
        <Route path={Paths.HOME} element={<Home />} />
        <Route path={Paths.PLAYERS} element={<Players />} />
        <Route path={Paths.PLAY} element={<Play />} />
        {/* <Route exact path="/players">
            <Page><PlayerList /></Page>
          </Route>
          <Route exact path="/players/:id" component={PlayerEdit}/>
          <Route exact path="/game" component={Game}/> */}
        {/* 
        <Route exact path="/names">
          <Names numberOfPlayers={this.state.numberOfPlayers} setNames={this.setPlayersNames} />
        </Route>
        <Route exact path="/game">
          <Game names={this.state.names} numberOfPlayers={this.state.numberOfPlayers} />
        </Route> */}
      </Router>
    </BrowserRouter>
  );
}
