export default function HotIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="512px"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <g id="flat">
        <path
          fill="#FC4E51"
          d="M276,134.881c13.008,15.755,20,34.632,20,53.993v18.679c14.105,0,26.4-8.305,29.82-20.143l2.18-7.541
		l5.912,6.138c19.682,20.432,19.443,50.17-0.564,70.364c-7.531,7.601-7.029,18.936,1.152,26.016c8.648,7.482,22.672,7.482,31.32,0
		c0.578-0.5,1.124-1.027,1.635-1.58L384,262.921l16,34.605c14.782,42.628,1.316,88.835-35.059,120.305l-2.019,1.746
		c-27.482,23.776-64.757,37.134-103.623,37.134h-16.325c-51.478,0-99.18-23.366-125.665-61.555
		C85.36,349.088,90.441,290.6,129.991,249.18l64.764-67.827c13.018-13.632,16-32.462,7.7-48.62
		c-10.66-20.748-2.49-45.123,19.345-57.719L256,55.289v25.6C256,100.25,262.992,119.126,276,134.881z"
        />
        <path
          fill="#FCB44D"
          d="M315.077,409.484l0.071-0.062c25.336-22.087,34.906-54.291,25.024-84.212L316.9,345.347
		c-9.236,7.991-24.212,7.991-33.449,0.001c-0.001,0-0.002-0.001-0.002-0.001c-8.652-7.483-9.28-19.439-1.449-27.57
		c15.898-16.506,18.996-39.588,7.889-58.804l-37.716-65.262l-5.8,55.195c-1.405,13.376-9.051,25.679-21.173,34.071l-32.973,22.821
		c-17.729,12.271-28.049,30.848-28.049,50.489v7.854c0,19.189,9.852,37.387,26.891,49.672c13.626,9.824,30.848,15.135,48.584,14.983
		l23.119-0.198C282.434,428.429,301.228,421.561,315.077,409.484L315.077,409.484z"
        />
        <path
          fill="#FF6268"
          d="M120,200.631c-4.418,0-8-3.099-8-6.921v-13.842c0-3.823,3.582-6.921,8-6.921s8,3.098,8,6.921v13.842
		C128,197.533,124.418,200.631,120,200.631z"
        />
        <path
          fill="#E82F3E"
          d="M144,166.026c-4.418,0-8-3.099-8-6.921v-13.842c0-3.823,3.582-6.921,8-6.921s8,3.098,8,6.921v13.842
		C152,162.927,148.418,166.026,144,166.026z"
        />
        <path
          fill="#961B20"
          d="M112,138.342c-4.418,0-8-3.099-8-6.921v-13.842c0-3.823,3.582-6.921,8-6.921s8,3.099,8,6.921v13.842
		C120,135.243,116.418,138.342,112,138.342z"
        />
        <path
          fill="#E82F3E"
          d="M392,221.395c-4.418,0-8-3.099-8-6.921v-13.842c0-3.823,3.582-6.921,8-6.921s8,3.098,8,6.921v13.842
		C400,218.296,396.418,221.395,392,221.395z"
        />
        <path
          fill="#FF6268"
          d="M368,172.947c-4.418,0-8-3.099-8-6.921v-13.842c0-3.822,3.582-6.921,8-6.921s8,3.099,8,6.921v13.842
		C376,169.849,372.418,172.947,368,172.947z"
        />
        <path
          fill="#E82F3E"
          d="M400,152.184c-4.418,0-8-3.098-8-6.921V124.5c0-3.823,3.582-6.921,8-6.921s8,3.098,8,6.921v20.763
		C408,149.085,404.418,152.184,400,152.184z"
        />
      </g>
    </svg>
  );
}
