export default function MildIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="512px"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <path
        fill="#FD8F31"
        d="M146.149,85.498c3.72,15.246,23.692,21.254,36.373,10.946c2.143-1.743,5.353,0.417,4.226,2.841
	c-6.669,14.358,5.674,30.094,22.479,28.658c2.84-0.242,4.067,3.249,1.613,4.6c-14.506,7.987-14.506,27.438,0,35.422
	c2.454,1.351,1.227,4.842-1.613,4.6c-16.804-1.437-29.148,14.299-22.479,28.657c1.127,2.429-2.083,4.585-4.226,2.842
	c-12.681-10.309-32.656-4.297-36.373,10.946c-0.628,2.578-4.593,2.578-5.221,0c-3.717-15.246-23.688-21.255-36.373-10.946
	c-2.143,1.743-5.354-0.414-4.226-2.842c6.669-14.357-5.674-30.093-22.478-28.657c-2.84,0.242-4.067-3.249-1.614-4.6
	c14.506-7.985,14.506-27.435,0-35.422c-2.45-1.351-1.227-4.842,1.614-4.6c16.804,1.436,29.147-14.299,22.478-28.658
	c-1.127-2.424,2.083-4.584,4.226-2.841c12.684,10.309,32.656,4.3,36.373-10.946C141.558,82.919,145.521,82.919,146.149,85.498z"
      />
      <path
        fill="#FF7F1F"
        d="M210.84,167.966c-14.506-7.985-14.506-27.435,0-35.422c2.454-1.348,1.227-4.842-1.613-4.598
	c-16.801,1.437-29.145-14.302-22.479-28.66c1.127-2.424-2.08-4.584-4.226-2.841c-12.681,10.312-32.653,4.299-36.373-10.946
	c-0.628-2.579-4.593-2.579-5.221,0c-1.375,5.638-4.973,10.005-9.602,12.762c16.42,12.223,26.938,30.966,26.938,51.997
	c0,21.028-10.518,39.774-26.938,51.998c4.629,2.753,8.227,7.124,9.602,12.758c0.628,2.579,4.596,2.579,5.221,0
	c3.72-15.245,23.692-21.254,36.373-10.946c2.146,1.743,5.353-0.413,4.226-2.842c-6.666-14.357,5.677-30.093,22.479-28.657
	C212.067,172.81,213.293,169.316,210.84,167.966z"
      />
      <path
        fill="#FCC16D"
        d="M173.06,150.255c0,15.102-13.216,27.34-29.521,27.34c-16.302,0-29.518-12.238-29.518-27.34
	c0-15.099,13.216-27.341,29.518-27.341C159.842,122.914,173.06,135.156,173.06,150.255z"
      />
      <path
        fill="#D8D8D8"
        d="M357.66,302.536V104.024c0-26.917-23.561-48.735-52.617-48.735c-29.062,0-52.619,21.818-52.619,48.735
	v198.512c-23.543,15.34-38.925,40.698-38.925,69.387c0,46.826,40.986,84.788,91.543,84.788c50.559,0,91.543-37.962,91.543-84.788
	C396.586,343.235,381.201,317.877,357.66,302.536z"
      />
      <path
        fill="#F3E8D7"
        d="M305.043,428.942c-34.863,0-63.223-26.271-63.223-58.558c0-24.519,16.449-46.35,41.135-54.866V104.024
	c0-11.283,9.904-20.458,22.088-20.458c12.178,0,22.088,9.175,22.088,20.458v211.495c24.68,8.515,41.135,30.346,41.135,54.863
	C368.266,402.671,339.902,428.942,305.043,428.942z"
      />
      <path
        fill="#FC4E51"
        d="M282.955,141.431v174.088c-24.686,8.515-41.135,30.346-41.135,54.863c0,32.289,28.359,58.558,63.223,58.558
	c34.859,0,63.223-26.268,63.223-58.558c0-24.519-16.455-46.349-41.135-54.863V141.431H282.955z"
      />
      <g>
        <path
          fill="#0D6E9A"
          d="M430.539,196.585h-37.943c-3.615,0-6.541-2.714-6.541-6.058s2.926-6.059,6.541-6.059h37.943
		c3.611,0,6.539,2.714,6.539,6.059S434.15,196.585,430.539,196.585z"
        />
        <path
          fill="#0D6E9A"
          d="M409.822,147.488h-17.229c-3.613,0-6.539-2.712-6.539-6.057s2.926-6.058,6.539-6.058h17.229
		c3.609,0,6.541,2.713,6.541,6.058S413.434,147.488,409.822,147.488z"
        />
        <path
          fill="#0D6E9A"
          d="M415.928,98.392h-23.332c-3.615,0-6.541-2.712-6.541-6.057s2.926-6.059,6.541-6.059h23.332
		c3.613,0,6.539,2.714,6.539,6.059S419.541,98.392,415.928,98.392z"
        />
        <path
          fill="#0D6E9A"
          d="M415.928,294.778h-23.332c-3.615,0-6.541-2.714-6.541-6.058c0-3.347,2.926-6.057,6.541-6.057h23.332
		c3.613,0,6.539,2.71,6.539,6.057C422.467,292.064,419.541,294.778,415.928,294.778z"
        />
        <path
          fill="#0D6E9A"
          d="M409.822,245.682h-17.229c-3.613,0-6.539-2.714-6.539-6.059c0-3.343,2.926-6.058,6.539-6.058h17.229
		c3.609,0,6.541,2.714,6.541,6.058C416.363,242.969,413.434,245.682,409.822,245.682z"
        />
      </g>
      <path
        fill="#E5394B"
        d="M327.131,315.519V141.431h-44.176v174.088c0,0,30.535,13.124,30.535,54.863
	c0,23.209-14.65,43.306-35.838,52.774c8.291,3.706,17.582,5.782,27.387,5.782c34.861,0,63.227-26.268,63.227-58.558
	C368.266,345.863,351.811,324.035,327.131,315.519z"
      />
    </svg>
  );
}
