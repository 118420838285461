import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Avatar, Flex, HStack, Heading, Icon, Text } from '@chakra-ui/react';
import { FemaleIcon } from '@components/icons/FemaleIcon';
import { MaleIcon } from '@components/icons/MaleIcon';
import { useStrings } from '@context/strings';
import { Gender, SexualPreference, colors } from '@global/constants';

type PlayerListItemProps = {
  name: string;
  gender: Gender;
  sexualPreference: SexualPreference;
  onEditClick: () => void;
  onRemoveClick: () => void;
};

const genderIcons = {
  [Gender.FEMALE]: FemaleIcon,
  [Gender.MALE]: MaleIcon,
};

const genderColors = {
  [Gender.FEMALE]: colors.esquentaPink,
  [Gender.MALE]: colors.esquentaBlue,
};

export function PlayerListItem({
  name,
  sexualPreference,
  gender,
  onEditClick,
  onRemoveClick,
}: PlayerListItemProps) {
  const { strings } = useStrings();

  return (
    <Flex
      padding="1.25rem"
      bgColor={colors.esquentaBlack}
      borderBottom={`1px solid ${colors.esquentaAskov}`}
    >
      <Flex minW={0}>
        <Avatar
          icon={<Icon as={genderIcons[gender]} maxHeight="100%" />}
          border={`2px solid ${genderColors[gender]}`}
          bgColor={colors.esquentaWhite}
          iconLabel={`${name} profile image`}
          mr={{ base: 2, lg: 4 }}
          maxHeight="100%"
          size="lg"
        />
        <Flex direction="column" overflow="hidden">
          <Heading
            as="h6"
            pos="relative"
            minW={0}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            fontSize="1.5rem"
            textColor={genderColors[gender]}
          >
            {name}
          </Heading>
          <Text
            color="textSecondary"
            fontSize="md"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {(strings as any)[gender + sexualPreference]}
          </Text>
        </Flex>
      </Flex>
      <Flex
        shrink={0}
        basis="25%"
        mt={1}
        ml="auto"
        color="textSecondary"
        fontSize="xl"
        justifyContent="center"
      >
        <HStack>
          <EditIcon
            color={colors.esquentaBlue}
            margin="5px 25px 5px 0"
            fontSize="1.5rem"
            onClick={() => onEditClick()}
          />
          <DeleteIcon
            color={colors.esquentaPepper}
            margin="5px 25px 5px 0"
            fontSize="1.5rem"
            onClick={() => onRemoveClick()}
          />
        </HStack>
      </Flex>
    </Flex>
  );
}
