import { LangDef } from '@global/strings';
import { Game } from '@global/types';

const apiVersion = <string>process.env.REACT_APP_API_VERSION;

export const LocalStorageKey = `@HotToD:Game:${apiVersion}`;

export const colors = {
  green: '#008080',
  red: '#cc5151',
  black: '#1f1f1f',
  white: '#e5e5e5',
  esquentaBlue: '#04b9fb',
  esquentaPink: '#eb83b5',
  esquentaGray: '#545863',
  esquentaPepper: '#c1292e',
  esquentaAskov: '#0b76fb',
  esquentaBlack: '#1f1f1f',
  esquentaWhite: '#e5e5e5',
};

export enum Language {
  PT_BR = 'pt_br',
  EN_US = 'en_us',
}

export const DEFAULT_LANG = Language.PT_BR;

export enum LevelSelector {
  ICE_BREAKER = 'ICE_BREAKER',
  MILD = 'MILD',
  WARM = 'WARM',
  HOT = 'HOT',
  VERY_HOT = 'VERY_HOT',
  EXTREMELY_HOT = 'EXTREMELY_HOT',
  INSANELY_HOT = 'INSANELY_HOT',
  HOT_AS_FUCK = 'HOT_AS_FUCK',
  FINISH_LINE = 'FINISH_LINE',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum SexualPreference {
  BISEXUAL = 'BISEXUAL',
  HOMOSEXUAL = 'HOMOSEXUAL',
  HETEROSEXUAL = 'HETEROSEXUAL',
}

export const SexualPreferenceDescriptions = {
  [SexualPreference.BISEXUAL]: 'Bisexual',
  [SexualPreference.HETEROSEXUAL]: 'Straight',
};

export const HomosexualPreferenceDescriptions = (lang: LangDef) => ({
  [Gender.MALE]: lang.gay,
  [Gender.FEMALE]: lang.lesbian,
});

export enum PlayerOrder {
  RANDOM = 'RANDOM',
  SEQUENTIAL = 'SEQUENTIAL',
}

export enum TaskType {
  TRUTH = 'TRUTH',
  DARE = 'DARE',
}

export enum Paths {
  HOME = '/',
  PLAYERS = '/players',
  PLAY = '/play',
}

export const GenderEmojis = {
  [Gender.MALE]: '💁‍♂️',
  [Gender.FEMALE]: '🙆‍♀️',
};

export const SexualityEmojis = {
  [SexualPreference.BISEXUAL]: '💖💜💙',
  [SexualPreference.HOMOSEXUAL]: '🏳️‍🌈',
  [SexualPreference.HETEROSEXUAL]: '👩🏻‍❤️‍💋‍👨🏻',
};

export const EMPTY_GAME: Game = {
  id: '',
  levels: [LevelSelector.ICE_BREAKER],
  playerOrder: PlayerOrder.RANDOM,
  token: '',
  players: [],
  currentLevels: [],
  lang: Language.PT_BR,
};
