import { LevelSelector } from '@global/constants';

import ExtremelyHotIcon from './ExtremelyHotIcon';
import FinishLineIcon from './FinishLineIcon';
import HotAsFuckIcon from './HotAsFuckIcon';
import HotIcon from './HotIcon';
import IceBreakerIcon from './IceBreakerIcon';
import InsanelyHotIcon from './InsanelyHotIcon';
import MildIcon from './MildIcon';
import VeryHotIcon from './VeryHotIcon';
import WarmIcon from './WarmIcon';

export const iconsByLevel = {
  [LevelSelector.ICE_BREAKER]: IceBreakerIcon,
  [LevelSelector.MILD]: MildIcon,
  [LevelSelector.WARM]: WarmIcon,
  [LevelSelector.HOT]: HotIcon,
  [LevelSelector.VERY_HOT]: VeryHotIcon,
  [LevelSelector.EXTREMELY_HOT]: ExtremelyHotIcon,
  [LevelSelector.INSANELY_HOT]: InsanelyHotIcon,
  [LevelSelector.HOT_AS_FUCK]: HotAsFuckIcon,
  [LevelSelector.FINISH_LINE]: FinishLineIcon,
};

export type LevelIconProps = {
  level: LevelSelector;
};

export default function LevelIcon({ level }: LevelIconProps) {
  const SelectedIcon = iconsByLevel[level];
  return <SelectedIcon />;
}

export function iconByLevel(level: LevelSelector) {
  return iconsByLevel[level];
}
