export default function IceBreakerIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="512px"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <path
        fill="#C4E1E8"
        d="M442.691,178.734c-4.289-70.84-63.086-126.969-135.008-126.969c-58,0-107.469,36.512-126.695,87.805
	c-49.949,0.602-90.258,41.258-90.258,91.348c0,50.469,40.91,91.383,91.379,91.383h258.074c39.66,0,71.812-32.152,71.812-71.816
	C511.996,211.664,481.188,180.059,442.691,178.734z"
      />
      <path
        fill="#A4D5DD"
        d="M512,250.484c0,39.66-32.156,71.816-71.82,71.816h-5.562c5.207-9.938,8.152-21.258,8.152-33.254
	c0-38.816-30.805-70.426-69.305-71.754C369.18,146.457,310.375,90.32,238.453,90.32c-9.695,0-19.152,1.02-28.266,2.961
	c24.602-25.586,59.188-41.512,97.496-41.512c71.918,0,130.723,56.125,135.012,126.973C481.195,180.055,512,211.664,512,250.484z"
      />
      <path
        fill="#C4E1E8"
        d="M406.887,170.82c-3.492,0-6.656-2.383-7.508-5.926c-1.25-5.203-2.965-10.336-5.09-15.242
	c-1.691-3.918,0.109-8.465,4.023-10.16c3.922-1.695,8.469,0.105,10.16,4.023c2.477,5.723,4.473,11.699,5.93,17.77
	c1,4.148-1.555,8.32-5.703,9.316C408.094,170.746,407.484,170.82,406.887,170.82z"
      />
      <path
        fill="#C4E1E8"
        d="M381.332,124.172c-2.031,0-4.059-0.797-5.574-2.375c-17.996-18.75-42.172-29.078-68.07-29.078
	c-4.27,0-7.727-3.457-7.727-7.723c0-4.27,3.457-7.727,7.727-7.727c30.148,0,58.281,12.012,79.219,33.828
	c2.953,3.078,2.852,7.969-0.227,10.926C385.184,123.457,383.258,124.172,381.332,124.172z"
      />
      <path
        fill="#F0F5F7"
        d="M71.051,219.664c3.066-50.645,45.102-90.773,96.52-90.773c41.465,0,76.832,26.102,90.574,62.77
	c35.711,0.434,64.531,29.5,64.531,65.309c0,36.082-29.25,65.332-65.332,65.332c-15.102,0-168.98,0-184.5,0
	c-28.355,0-51.34-22.988-51.34-51.344C21.504,243.203,43.527,220.609,71.051,219.664z"
      />
      <path
        fill="#DDE9ED"
        d="M322.676,256.977c0,35.77-28.762,64.824-64.43,65.309c10.695-11.637,17.215-27.141,17.215-44.176
	c0-35.809-28.812-64.879-64.531-65.312c-13.746-36.664-49.105-62.77-90.578-62.77c-4.902,0-9.711,0.367-14.422,1.07
	c16.73-13.871,38.207-22.203,61.637-22.203c41.473,0,76.832,26.094,90.578,62.77C293.863,192.098,322.676,221.156,322.676,256.977z"
      />
      <path
        fill="#60DAFF"
        d="M225.758,307.575c0.06,62.34-50.431,112.928-112.771,112.987S0.06,370.132,0,307.792
	c-0.06-62.34,50.431-112.927,112.771-112.987C175.11,194.745,225.698,245.235,225.758,307.575z"
      />
      <path
        fill="#F0F5F7"
        d="M179.258,323.898L167.645,329c0.531-3.094-0.859-6.328-3.734-7.992c-3.234-1.875-7.273-1.137-9.66,1.57
	l-8.871-5.109l6.176-2.715c2.805-1.23,4.617-4.008,4.617-7.07c0-3.066-1.812-5.844-4.617-7.074l-6.176-2.711l8.887-5.113
	c1.488,1.68,3.602,2.613,5.781,2.613c1.312,0,2.645-0.336,3.863-1.039c2.875-1.668,4.266-4.898,3.734-7.992l11.613,5.102
	c1.012,0.445,2.066,0.656,3.105,0.656c2.973,0,5.805-1.727,7.078-4.621c1.715-3.906-0.059-8.465-3.965-10.18l-2.492-1.098
	c3.375-2.234,4.48-6.73,2.434-10.285c-2.059-3.578-6.539-4.875-10.176-3.035l0.297-2.719c0.461-4.242-2.602-8.059-6.844-8.52
	c-4.238-0.465-8.055,2.598-8.52,6.84l-1.371,12.562c-2.418-2.031-5.941-2.445-8.832-0.773c-3.227,1.871-4.594,5.715-3.461,9.117
	l-8.902,5.121l0.734-6.715c0.332-3.043-1.164-5.992-3.812-7.527c-2.648-1.531-5.953-1.355-8.426,0.449l-5.496,4.012v-10.211
	c3.527-0.715,6.188-3.832,6.188-7.574c0-3.32-2.102-6.141-5.043-7.234l10.188-7.434c3.445-2.516,4.203-7.348,1.688-10.793
	c-2.516-3.449-7.348-4.203-10.793-1.688l-2.25,1.637c-0.234-4.055-3.59-7.277-7.703-7.277c-4.117,0-7.477,3.227-7.703,7.285
	l-2.258-1.645c-3.449-2.52-8.281-1.762-10.793,1.688c-2.516,3.445-1.762,8.281,1.688,10.793l10.188,7.434
	c-2.941,1.094-5.043,3.918-5.043,7.238c0,3.742,2.66,6.859,6.195,7.57v10.215l-5.508-4.016c-2.473-1.805-5.777-1.98-8.422-0.445
	c-2.648,1.531-4.145,4.484-3.812,7.523l0.734,6.719l-8.902-5.125c1.133-3.402-0.234-7.25-3.465-9.117
	c-2.887-1.672-6.41-1.258-8.832,0.773l-1.371-12.562c-0.461-4.238-4.27-7.305-8.516-6.84c-4.242,0.461-7.305,4.277-6.844,8.52
	l0.297,2.719c-3.641-1.84-8.121-0.543-10.18,3.035c-2.047,3.555-0.941,8.051,2.434,10.285l-2.488,1.094
	c-3.906,1.719-5.684,6.273-3.965,10.184c1.27,2.895,4.105,4.617,7.074,4.617c1.039,0,2.094-0.207,3.105-0.652l11.613-5.105
	c-0.531,3.094,0.863,6.328,3.734,7.992c1.219,0.707,2.551,1.043,3.867,1.043c2.176,0,4.293-0.934,5.777-2.617l8.887,5.113
	l-6.172,2.715c-2.809,1.23-4.617,4.008-4.617,7.07c0,3.066,1.809,5.84,4.617,7.074l6.176,2.715l-8.871,5.105
	c-2.387-2.703-6.422-3.445-9.66-1.566c-2.875,1.664-4.266,4.898-3.738,7.988l-11.613-5.102c-3.902-1.719-8.465,0.059-10.18,3.965
	c-1.719,3.906,0.059,8.465,3.965,10.184l2.492,1.094c-3.375,2.234-4.484,6.73-2.434,10.285c1.43,2.484,4.027,3.875,6.703,3.875
	c1.176,0,2.363-0.285,3.473-0.848l-0.297,2.727c-0.461,4.242,2.602,8.055,6.844,8.52c0.285,0.031,0.566,0.047,0.848,0.047
	c3.891,0,7.238-2.934,7.672-6.891l1.371-12.566c1.398,1.176,3.16,1.82,4.965,1.82c1.316,0,2.648-0.336,3.867-1.043
	c3.227-1.867,4.594-5.711,3.461-9.113l8.902-5.121l-0.734,6.711c-0.328,3.043,1.164,5.992,3.812,7.527
	c1.203,0.695,2.539,1.039,3.871,1.039c1.602,0,3.203-0.5,4.551-1.484l5.508-4.02v10.215c-3.531,0.711-6.195,3.832-6.195,7.574
	c0,3.32,2.102,6.145,5.043,7.234l-10.188,7.434c-3.445,2.516-4.203,7.348-1.688,10.797c2.516,3.445,7.348,4.199,10.797,1.688
	l2.254-1.645c0.227,4.059,3.586,7.285,7.703,7.285s7.473-3.223,7.703-7.281l2.25,1.641c1.375,1,2.969,1.484,4.547,1.484
	c2.383,0,4.734-1.102,6.246-3.172c2.516-3.449,1.762-8.281-1.688-10.797l-10.184-7.434c2.938-1.09,5.039-3.914,5.039-7.234
	c0-3.742-2.656-6.859-6.188-7.574v-10.207l5.5,4.012c2.469,1.801,5.773,1.973,8.422,0.445c2.648-1.535,4.145-4.484,3.812-7.527
	l-0.734-6.711l8.902,5.121c-1.129,3.402,0.238,7.246,3.465,9.113c1.219,0.707,2.551,1.043,3.863,1.043
	c1.805,0,3.566-0.645,4.969-1.82l1.371,12.566c0.43,3.957,3.777,6.891,7.668,6.891c0.281,0,0.562-0.016,0.848-0.047
	c4.242-0.465,7.309-4.277,6.844-8.52l-0.297-2.727c1.113,0.562,2.297,0.848,3.473,0.848c2.676,0,5.277-1.391,6.707-3.875
	c2.047-3.555,0.938-8.051-2.438-10.285l2.492-1.098c3.906-1.715,5.684-6.273,3.965-10.18S183.168,322.18,179.258,323.898z
	 M117.434,319.164c-0.023-0.016-0.047-0.027-0.07-0.043c-0.297-0.211-0.605-0.402-0.934-0.57c-0.051-0.027-0.109-0.051-0.16-0.078
	c-0.309-0.152-0.629-0.285-0.957-0.395c-0.031-0.012-0.059-0.023-0.086-0.031c-0.332-0.105-0.676-0.184-1.023-0.242
	c-0.09-0.016-0.18-0.031-0.273-0.043c-0.316-0.043-0.637-0.07-0.965-0.074c-0.027,0-0.059-0.008-0.086-0.008
	c-0.031,0-0.066,0.008-0.098,0.008c-0.316,0.004-0.629,0.031-0.934,0.074c-0.102,0.012-0.203,0.027-0.305,0.043
	c-0.336,0.062-0.664,0.137-0.984,0.234c-0.059,0.02-0.117,0.043-0.172,0.066c-0.289,0.098-0.57,0.215-0.844,0.344
	c-0.078,0.039-0.16,0.074-0.242,0.117c-0.301,0.156-0.586,0.336-0.863,0.527c-0.035,0.027-0.074,0.043-0.113,0.07l-3.648,2.664
	l0.484-4.445c0.004-0.031,0-0.062,0.004-0.094c0.035-0.359,0.047-0.719,0.031-1.082c-0.004-0.066-0.012-0.129-0.016-0.195
	c-0.023-0.34-0.066-0.68-0.133-1.02c-0.008-0.031-0.012-0.062-0.02-0.094c-0.074-0.34-0.176-0.676-0.301-1.012
	c-0.031-0.082-0.062-0.168-0.098-0.25c-0.125-0.312-0.27-0.621-0.441-0.922c-0.023-0.039-0.047-0.074-0.066-0.109
	c-0.168-0.281-0.352-0.543-0.547-0.797c-0.059-0.074-0.113-0.145-0.172-0.215c-0.227-0.27-0.461-0.523-0.715-0.758
	c-0.039-0.035-0.078-0.062-0.117-0.098c-0.246-0.215-0.504-0.41-0.773-0.594c-0.059-0.039-0.113-0.078-0.176-0.117
	c-0.297-0.191-0.609-0.355-0.93-0.504c-0.035-0.016-0.066-0.039-0.102-0.055l-4.07-1.789l4.07-1.785
	c0.066-0.031,0.129-0.074,0.195-0.105c0.254-0.125,0.508-0.258,0.75-0.406c0.105-0.066,0.207-0.133,0.309-0.203
	c0.219-0.148,0.426-0.309,0.629-0.48c0.094-0.082,0.188-0.156,0.273-0.238c0.199-0.188,0.387-0.391,0.566-0.598
	c0.07-0.082,0.148-0.156,0.215-0.242c0.207-0.262,0.402-0.547,0.578-0.844c0.02-0.035,0.047-0.066,0.066-0.102
	c0.004,0,0.004,0,0.004-0.004c0.18-0.312,0.328-0.633,0.457-0.957c0.031-0.078,0.059-0.152,0.09-0.227
	c0.125-0.34,0.23-0.684,0.305-1.027c0.008-0.027,0.008-0.055,0.016-0.082c0.07-0.344,0.113-0.688,0.137-1.031
	c0.004-0.062,0.012-0.125,0.012-0.188c0.02-0.363,0.004-0.723-0.031-1.082c0-0.035,0.004-0.066,0-0.098l-0.484-4.445l3.648,2.664
	c0.031,0.02,0.066,0.035,0.094,0.055c0.289,0.203,0.586,0.391,0.902,0.555c0.062,0.031,0.125,0.059,0.188,0.09
	c0.301,0.148,0.609,0.277,0.934,0.383c0.031,0.012,0.062,0.027,0.094,0.035c0.336,0.109,0.684,0.188,1.039,0.246
	c0.078,0.016,0.156,0.027,0.234,0.039c0.352,0.047,0.707,0.082,1.07,0.082h0.004c0.367,0,0.727-0.035,1.082-0.086
	c0.07-0.012,0.137-0.02,0.207-0.031c0.367-0.062,0.727-0.145,1.07-0.254c0.004,0,0.008-0.004,0.012-0.004
	c0.363-0.117,0.715-0.266,1.051-0.434c0.035-0.016,0.07-0.035,0.105-0.051c0.34-0.176,0.664-0.375,0.973-0.594
	c0.016-0.012,0.035-0.02,0.051-0.031l3.652-2.664l-0.484,4.445c-0.004,0.023,0,0.051-0.004,0.074
	c-0.035,0.371-0.047,0.746-0.031,1.125c0,0.043,0.008,0.086,0.008,0.129c0.027,0.379,0.07,0.754,0.152,1.125v0.008
	c0.078,0.359,0.188,0.715,0.32,1.066c0.02,0.062,0.047,0.121,0.07,0.18c0.133,0.336,0.281,0.664,0.469,0.984
	c0.195,0.344,0.418,0.66,0.656,0.957c0.055,0.07,0.117,0.129,0.172,0.195c0.195,0.227,0.395,0.445,0.609,0.645
	c0.078,0.074,0.164,0.141,0.246,0.211c0.215,0.184,0.434,0.352,0.66,0.508c0.094,0.062,0.188,0.129,0.285,0.188
	c0.254,0.156,0.516,0.297,0.781,0.422c0.062,0.027,0.117,0.066,0.176,0.094l4.07,1.789l-4.07,1.789
	c-0.031,0.016-0.059,0.035-0.09,0.051c-0.328,0.148-0.648,0.316-0.957,0.516c-0.047,0.027-0.09,0.062-0.137,0.094
	c-0.289,0.191-0.562,0.402-0.828,0.633c-0.023,0.023-0.051,0.043-0.074,0.066c-0.262,0.238-0.508,0.5-0.738,0.781
	c-0.051,0.059-0.098,0.117-0.145,0.18c-0.215,0.273-0.414,0.555-0.594,0.863c-0.008,0.012-0.016,0.02-0.02,0.031
	c-0.004,0.004-0.004,0.004-0.008,0.008c-0.18,0.316-0.328,0.641-0.461,0.969c-0.027,0.066-0.055,0.137-0.082,0.203
	c-0.129,0.348-0.234,0.699-0.312,1.051c0,0.008,0,0.016-0.004,0.027c-0.078,0.367-0.121,0.734-0.148,1.105
	c0,0.047-0.008,0.09-0.008,0.137c-0.016,0.379-0.008,0.754,0.031,1.125c0.004,0.023,0,0.047,0.004,0.07l0.484,4.445L117.434,319.164
	z"
      />
      <g>
        <path
          fill="#60DAFF"
          d="M47.078,110.281c-0.012,0-0.027,0-0.039,0c-4.266-0.02-7.711-3.496-7.688-7.762l0.016-3.027
		c0.02-4.254,3.477-7.688,7.723-7.688h0.039c4.266,0.02,7.711,3.496,7.688,7.762l-0.012,3.027
		C54.781,106.848,51.328,110.281,47.078,110.281z"
        />
        <path
          fill="#60DAFF"
          d="M46.984,141.086c-0.016,0-0.027,0-0.039,0c-4.266-0.02-7.711-3.496-7.691-7.762l0.016-3.027
		c0.02-4.254,3.477-7.688,7.723-7.688h0.039c4.27,0.02,7.711,3.492,7.691,7.762l-0.016,3.023
		C54.688,137.652,51.23,141.086,46.984,141.086z"
        />
        <path
          fill="#60DAFF"
          d="M63.988,124.227c-0.016,0-0.027,0-0.039,0l-3.031-0.016c-4.27-0.02-7.711-3.496-7.691-7.762
		c0.023-4.27,3.547-7.715,7.766-7.691l3.031,0.016c4.266,0.02,7.711,3.496,7.691,7.766
		C71.691,120.793,68.238,124.227,63.988,124.227z"
        />
        <path
          fill="#60DAFF"
          d="M33.121,124.133c-0.016,0-0.027,0-0.039,0l-3.031-0.016c-4.266-0.02-7.711-3.496-7.691-7.762
		c0.02-4.254,3.477-7.691,7.727-7.691h0.035l3.031,0.016c4.27,0.02,7.711,3.492,7.691,7.762
		C40.824,120.695,37.371,124.133,33.121,124.133z"
        />
        <path
          fill="#60DAFF"
          d="M265.328,374.188c-0.012,0-0.023,0-0.035,0c-4.27-0.02-7.711-3.496-7.691-7.762l0.016-3.027
		c0.02-4.254,3.477-7.691,7.723-7.691h0.039c4.266,0.023,7.711,3.496,7.688,7.762l-0.012,3.027
		C273.035,370.754,269.578,374.188,265.328,374.188z"
        />
        <path
          fill="#60DAFF"
          d="M265.234,404.992c-0.016,0-0.023,0-0.039,0c-4.266-0.023-7.711-3.496-7.688-7.762l0.012-3.027
		c0.02-4.254,3.477-7.691,7.727-7.691h0.035c4.27,0.02,7.711,3.496,7.691,7.762l-0.016,3.027
		C272.938,401.555,269.484,404.992,265.234,404.992z"
        />
        <path
          fill="#60DAFF"
          d="M282.238,388.133c-0.012,0-0.023,0-0.039,0l-3.031-0.016c-4.266-0.023-7.707-3.496-7.688-7.766
		c0.02-4.254,3.473-7.688,7.723-7.688h0.039l3.031,0.016c4.266,0.02,7.711,3.496,7.691,7.762
		C289.945,384.695,286.488,388.133,282.238,388.133z"
        />
        <path
          fill="#60DAFF"
          d="M251.371,388.035c-0.012,0-0.027,0-0.039,0l-3.031-0.016c-4.266-0.02-7.711-3.496-7.688-7.762
		c0.02-4.258,3.477-7.691,7.723-7.691h0.039l3.031,0.016c4.27,0.023,7.711,3.496,7.691,7.766
		C259.074,384.602,255.621,388.035,251.371,388.035z"
        />
        <path
          fill="#60DAFF"
          d="M344.562,366.121c-0.016,0-0.027,0-0.039,0h-0.105c-4.27-0.02-7.711-3.496-7.691-7.766
		c0.02-4.254,3.477-7.688,7.727-7.688h0.035h0.109c4.266,0.02,7.707,3.496,7.688,7.762
		C352.266,362.684,348.812,366.121,344.562,366.121z"
        />
        <path
          fill="#60DAFF"
          d="M189.09,453.188h-0.109c-4.266,0-7.723-3.457-7.723-7.727c0-4.266,3.457-7.727,7.723-7.727h0.109
		c4.266,0,7.727,3.461,7.727,7.727C196.816,449.73,193.355,453.188,189.09,453.188z"
        />
        <path
          fill="#60DAFF"
          d="M374.016,400.086h-0.109c-4.266,0-7.723-3.457-7.723-7.723c0-4.27,3.457-7.727,7.723-7.727h0.109
		c4.27,0,7.727,3.457,7.727,7.727C381.742,396.629,378.285,400.086,374.016,400.086z"
        />
        <path
          fill="#60DAFF"
          d="M474.395,381.738c-0.012,0-0.023,0-0.035,0h-0.105c-4.27-0.02-7.711-3.496-7.691-7.762
		c0.02-4.258,3.477-7.691,7.723-7.691h0.039h0.105c4.27,0.02,7.711,3.496,7.691,7.762
		C482.102,378.301,478.645,381.738,474.395,381.738z"
        />
        <path
          fill="#60DAFF"
          d="M428.598,366.121c-0.012,0-0.023,0-0.035,0h-0.109c-4.266-0.02-7.707-3.496-7.688-7.766
		c0.02-4.254,3.477-7.688,7.723-7.688h0.039h0.105c4.27,0.02,7.711,3.496,7.691,7.762
		C436.305,362.684,432.848,366.121,428.598,366.121z"
        />
        <path
          fill="#60DAFF"
          d="M90.785,74.133c-0.012,0-0.023,0-0.039,0h-0.105c-4.266-0.02-7.711-3.496-7.691-7.762
		c0.023-4.258,3.477-7.691,7.727-7.691h0.039h0.105c4.266,0.02,7.711,3.496,7.691,7.762C98.488,70.699,95.035,74.133,90.785,74.133z
		"
        />
        <path
          fill="#60DAFF"
          d="M153.555,107.258c-0.016,0-0.027,0-0.039,0h-0.105c-4.266-0.02-7.711-3.496-7.691-7.766
		c0.02-4.254,3.477-7.688,7.727-7.688h0.035h0.109c4.266,0.02,7.711,3.496,7.688,7.762
		C161.262,103.824,157.805,107.258,153.555,107.258z"
        />
        <path
          fill="#60DAFF"
          d="M30.387,445.242c-0.016,0-0.027,0-0.039,0h-0.105c-4.27-0.02-7.711-3.496-7.691-7.762
		c0.02-4.258,3.477-7.691,7.727-7.691h0.035h0.109c4.266,0.02,7.711,3.496,7.688,7.762C38.09,441.805,34.637,445.242,30.387,445.242
		z"
        />
      </g>
    </svg>
  );
}
