import { Badge, Box } from '@chakra-ui/layout';
import { Gender, colors } from '@global/constants';
import { Player } from '@global/types';

import './circle-names.scss';

export type CircleNamesProps = {
  players: Player[];
};

export function CircleNames(props: CircleNamesProps) {
  const { players } = props;
  const playerCount = players.length;
  return (
    <Box className={`circle__names circle__names--${playerCount}`}>
      {players.map((player, index) => {
        const { name, gender } = player;
        const color =
          gender === Gender.MALE ? colors.esquentaBlue : colors.esquentaPink;
        return (
          <Badge
            textTransform="none"
            className={`circle__name circle__name${index + 1}`}
            border={`1px solid ${color}`}
            textColor={colors.esquentaWhite}
            bg={colors.esquentaBlack}
            key={index}
          >
            {name}
          </Badge>
        );
      })}
    </Box>
  );
}
