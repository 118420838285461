export default function HotAsFuckIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="512px"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <path
        fill="#FCB44D"
        d="M400.718,170.647c0,0,37.497-12.499,43.746-31.248c0,0,0-6.25-18.748-6.25
 c11.183-7.775,19.867-18.63,24.998-31.248h-18.749l-18.748-6.25h-18.749c-31.247-31.247-43.746,6.25-43.746,6.25
 c-24.998,0-18.748,31.248-18.748,31.248c-24.998,24.998,6.249,37.497,6.249,37.497H400.718z"
      />
      <path
        fill="#FCB44D"
        d="M400.718,176.89h-62.495c-0.793,0-1.58-0.151-2.318-0.444c-1.693-0.675-16.636-7.018-19.436-20.667
 c-1.278-9.201,2.041-18.443,8.88-24.729c-1.533-9.459,0.614-19.147,6-27.073c3.794-4.547,9.209-7.436,15.098-8.056
 c3.629-8.73,10.901-15.43,19.899-18.33c9.543-2.256,19.866,1.713,30.622,11.831h16.249c0.671,0.001,1.338,0.111,1.975,0.325
 l17.786,5.925h17.736c3.451,0.002,6.247,2.803,6.244,6.254c0,0.669-0.108,1.334-0.32,1.97c-3.43,9.407-8.946,17.916-16.136,24.885
 c9.273,2.912,10.212,8.493,10.212,10.612c-0.002,0.671-0.112,1.338-0.325,1.975c-7.031,21.104-43.559,33.816-47.696,35.197
 C402.056,176.779,401.389,176.889,400.718,176.89z M339.554,164.391h60.126c12.137-4.218,30.96-13.667,37.272-23.692
 c-3.658-1.022-7.454-1.463-11.249-1.306c-3.452-0.001-6.248-2.8-6.248-6.251c0.001-2.085,1.041-4.032,2.773-5.191
 c7.458-5.331,13.812-12.056,18.711-19.805h-8.975c-0.671-0.001-1.338-0.111-1.975-0.325l-17.786-5.924h-17.736
 c-1.657,0-3.246-0.659-4.418-1.832c-5.625-5.624-13.749-11.993-20.817-10.33c-7.067,1.663-11.536,10.98-12.586,14.143
 c-0.852,2.548-3.238,4.267-5.925,4.269c-3.634-0.323-7.212,1.063-9.681,3.75c-3.416,6.095-4.462,13.236-2.938,20.054
 c0.402,2.043-0.239,4.152-1.712,5.625c-4.118,4.118-8.793,10.181-7.687,15.673C329.812,158.742,336.173,162.766,339.554,164.391
 L339.554,164.391z"
      />
      <path
        fill="#055177"
        d="M375.72,232.404c-3.451,0-6.249-2.798-6.249-6.249v-93.011c0-3.452,2.798-6.25,6.249-6.25
 s6.249,2.798,6.249,6.25v93.011C381.969,229.606,379.171,232.404,375.72,232.404z"
      />
      <path
        fill="#055177"
        d="M375.72,170.641c-3.451,0-6.249-2.799-6.248-6.251c0-1.657,0.658-3.245,1.829-4.417l18.749-18.749
 c2.447-2.44,6.408-2.434,8.849,0.013c2.441,2.447,2.435,6.409-0.012,8.849l-18.748,18.749
 C378.963,169.998,377.374,170.647,375.72,170.641z"
      />
      <path
        fill="#055177"
        d="M375.72,195.639c-2.091,0.002-4.046-1.042-5.206-2.781l-24.998-37.497c-1.863-2.905-1.019-6.771,1.886-8.635
 c2.834-1.818,6.598-1.064,8.514,1.704l24.998,37.497c1.912,2.874,1.133,6.753-1.74,8.665
 C378.149,195.272,376.948,195.637,375.72,195.639L375.72,195.639z"
      />
      <path
        fill="#FCB44D"
        d="M338.223,258.202c0,0,43.746-31.247,49.996-49.996c0,0,12.499,12.499,6.249,37.497
 c0,0,49.996-25.517,56.246-49.996c0,0,0,49.996-12.499,62.495h12.499c0,0,0,12.499-18.749,24.997h-93.742V258.202z"
      />
      <path
        fill="#FCB44D"
        d="M431.965,289.449h-93.742c-3.451,0-6.249-2.799-6.249-6.25v-24.997c0.001-2.017,0.977-3.909,2.618-5.081
 c11.705-8.368,43.121-33.122,47.702-46.871c1.094-3.273,4.633-5.041,7.907-3.948c0.917,0.307,1.751,0.821,2.436,1.505
 c7.134,8.409,10.656,19.297,9.8,30.291c15.911-9.562,38.634-25.866,42.222-39.916c0.853-3.344,4.255-5.364,7.6-4.511
 c2.762,0.704,4.696,3.187,4.705,6.037c0,7.112-0.45,37.647-7.868,56.246h1.619c3.451,0,6.249,2.797,6.249,6.249
 c0,1.625-0.625,16.247-21.529,30.196C434.406,289.084,433.199,289.449,431.965,289.449z M344.473,276.949h85.549
 c4.897-3.238,9.032-7.502,12.118-12.498h-3.925c-3.451,0-6.249-2.799-6.248-6.251c0-1.656,0.658-3.245,1.829-4.417
 c5-4.999,7.98-20.061,9.48-35.047c-13.67,13.005-29.15,23.963-45.959,32.535c-3.073,1.57-6.838,0.351-8.407-2.724
 c-0.687-1.345-0.864-2.892-0.498-4.357c2.171-7.626,2.104-15.715-0.193-23.304c-11.755,16.211-35.379,34.315-43.746,40.497V276.949z
 "
      />
      <path
        fill="#FF7F1F"
        d="M206.983,183.208c0,0,6.25-43.747,43.747-49.996c0,0-6.25,18.749,0,24.998c0,0,37.496-6.25,43.746-24.998
 c0,0,6.25,24.998-6.25,37.497c3.236,6.831,11.396,9.746,18.229,6.509c0.176-0.083,0.349-0.169,0.521-0.26
 c0,0,7.293,19.373-7.881,26.979L206.983,183.208z"
      />
      <path
        fill="#FF7F1F"
        d="M299.089,210.188c-0.462,0.002-0.924-0.049-1.375-0.15l-92.105-20.729c-3.181-0.714-5.279-3.753-4.819-6.981
 c0.282-1.969,7.381-48.352,48.909-55.277c3.404-0.564,6.622,1.739,7.187,5.145c0.165,0.998,0.086,2.02-0.231,2.98
 c-1.762,5.118-2.57,10.515-2.387,15.924c11.024-2.594,30.729-9.249,34.271-19.874c0.902-2.62,3.405-4.348,6.176-4.262
 c2.776,0.106,5.15,2.034,5.823,4.731c0.269,1.056,5.806,23.911-4.412,39.184c2.288,1.911,5.554,2.108,8.056,0.487
 c3.087-1.545,6.842-0.296,8.387,2.791c0.098,0.196,0.186,0.397,0.263,0.603c3.206,8.53,5.05,26.779-10.942,34.766
 C301.02,209.962,300.061,210.188,299.089,210.188L299.089,210.188z M214.42,178.478l83.605,18.817
 c3.595-3.064,5.266-7.823,4.376-12.462c-4.712,0.561-9.454-0.752-13.206-3.656c-2.701-2.06-4.93-4.678-6.53-7.674
 c-1.201-2.406-0.728-5.311,1.175-7.212c3.975-4.763,6.084-10.809,5.932-17.011c-11.416,7.729-24.379,12.879-37.985,15.093
 c-1.989,0.325-4.013-0.324-5.443-1.744c-4.406-5.835-5.928-13.354-4.138-20.442C224.257,149.968,216.982,168.966,214.42,178.478
 L214.42,178.478z"
      />
      <path
        fill="#FCB44D"
        d="M150.738,158.21c22.872-0.799,42.824-15.764,49.996-37.497c-7.941-3.613-17.057-3.613-24.998,0
 c0,0,17.186-24.998,12.499-43.746c0,0-24.998,0-31.248,12.499V64.468c0,0-37.497,6.25-43.747,24.998
 c-10.021,12.3-12.425,29.131-6.249,43.746C114.693,150.074,132.301,160.136,150.738,158.21z"
      />
      <path
        fill="#FCB44D"
        d="M146.445,164.66c-19.478,0.2-37.22-11.168-45.177-28.947c-7.012-16.38-4.544-35.278,6.437-49.309
 c8.087-20.835,44.108-27.41,48.258-28.098c1.812-0.292,3.663,0.218,5.068,1.4c1.398,1.187,2.205,2.928,2.206,4.762V75.88
 c7.904-3.372,16.404-5.127,24.998-5.162c2.868,0,5.368,1.954,6.062,4.737c3.082,12.312-1.662,26.379-6.343,36.247
 c5.374,0.039,10.68,1.208,15.574,3.431c2.779,1.394,4.111,4.62,3.125,7.568c-7.925,24.22-30.145,40.904-55.614,41.759
 C149.501,164.593,147.97,164.66,146.445,164.66z M150.738,72.249c-11.249,2.975-28.298,9.375-31.572,19.192
 c-0.232,0.698-0.585,1.35-1.044,1.925c-8.612,10.473-10.694,24.862-5.405,37.347c6.552,14.45,21.669,23.031,37.434,21.249h0.569
 c17.904-0.656,33.972-11.172,41.74-27.316c-4.701-0.978-9.592-0.394-13.93,1.663c-3.089,1.539-6.841,0.281-8.38-2.808
 c-1.011-2.029-0.844-4.446,0.437-6.316c6.902-9.964,11.106-21.545,12.205-33.616c-7.531,0.85-17.555,3.375-20.211,8.699
 c-1.54,3.089-5.292,4.346-8.381,2.806c-2.125-1.059-3.466-3.231-3.462-5.605V72.249z"
      />
      <path
        fill="#055177"
        d="M125.74,217.23c-3.452,0-6.25-2.798-6.25-6.25v-96.517c0-3.452,2.798-6.25,6.25-6.25s6.25,2.798,6.25,6.25
 v96.517C131.99,214.433,129.192,217.23,125.74,217.23z"
      />
      <path
        fill="#055177"
        d="M125.74,151.961c-3.452-0.001-6.249-2.799-6.248-6.251c0-1.657,0.659-3.245,1.83-4.417l18.749-18.749
 c2.483-2.398,6.439-2.329,8.837,0.154c2.339,2.422,2.339,6.261,0,8.683l-18.748,18.749
 C128.987,151.302,127.398,151.96,125.74,151.961z"
      />
      <path
        fill="#055177"
        d="M125.74,176.959c-2.091,0.002-4.045-1.042-5.206-2.781l-24.998-37.497c-1.964-2.838-1.255-6.731,1.583-8.695
 c2.838-1.964,6.731-1.255,8.695,1.583c0.042,0.06,0.082,0.121,0.121,0.182l24.998,37.497c1.912,2.874,1.133,6.753-1.74,8.665
 C128.17,176.593,126.969,176.957,125.74,176.959z"
      />
      <path
        fill="#055177"
        d="M344.473,364.443h-74.994c-3.451,0-6.25-2.799-6.25-6.25s2.799-6.25,6.25-6.25h74.994
 c3.451,0,6.249,2.799,6.249,6.25S347.924,364.443,344.473,364.443z"
      />
      <path
        fill="#FCC16D"
        d="M350.722,320.697c-3.451-0.002-6.249-2.799-6.248-6.252c0-1.656,0.658-3.246,1.829-4.416l6.25-6.25
 c2.482-2.398,6.438-2.33,8.837,0.152c2.339,2.422,2.339,6.262,0,8.684l-6.249,6.25C353.969,320.037,352.379,320.697,350.722,320.697
 z"
      />
      <path
        fill="#FF7F1F"
        d="M281.978,108.214c-3.451,0-6.249-2.799-6.248-6.251c0-1.656,0.658-3.245,1.829-4.417l6.25-6.25
 c2.482-2.397,6.438-2.329,8.837,0.153c2.339,2.422,2.339,6.262,0,8.684l-6.249,6.25
 C285.225,107.556,283.635,108.214,281.978,108.214z"
      />
      <path
        fill="#FF7F1F"
        d="M219.482,101.965c-3.452,0-6.249-2.799-6.248-6.251c0-1.656,0.658-3.245,1.829-4.417l6.25-6.25
 c2.483-2.397,6.439-2.329,8.837,0.154c2.339,2.422,2.339,6.261,0,8.684l-6.25,6.25C222.729,101.306,221.14,101.965,219.482,101.965z
 "
      />
      <path
        fill="#FCC16D"
        d="M413.217,320.697c-3.451-0.002-6.249-2.799-6.248-6.252c0-1.656,0.658-3.246,1.829-4.416l6.25-6.25
 c2.482-2.398,6.439-2.33,8.837,0.152c2.339,2.422,2.339,6.262,0,8.684l-6.25,6.25C416.463,320.037,414.874,320.697,413.217,320.697z
 "
      />
      <path
        fill="#FD8F31"
        d="M388.219,283.199c-3.451-0.008-6.243-2.811-6.236-6.262c0.006-2.861,1.954-5.354,4.73-6.049
 c11.819-2.182,21.833-9.987,26.835-20.917c1.092-3.279,4.636-5.052,7.915-3.959c3.278,1.092,5.052,4.636,3.959,7.915l0,0
 c-6.332,15.004-19.688,25.895-35.66,29.078C389.258,283.135,388.739,283.199,388.219,283.199L388.219,283.199z"
      />
      <path
        fill="#2290D3"
        d="M200.734,358.193V233.204l68.744-49.996l62.495,43.747v131.238H200.734z"
      />
      <path
        fill="#2290D3"
        d="M331.974,364.443H200.734c-3.452,0-6.25-2.799-6.25-6.25V233.204c0-2,0.957-3.88,2.575-5.056l68.744-49.996
 c2.157-1.568,5.07-1.596,7.256-0.069l62.495,43.747c1.673,1.17,2.669,3.083,2.668,5.125v131.238
 C338.223,361.645,335.425,364.443,331.974,364.443z M206.983,351.943h118.74V230.211l-56.177-39.322l-62.563,45.497V351.943z"
      />
      <g>
        <path
          fill="#055177"
          d="M231.981,258.202h24.998v31.247h-24.998V258.202z"
        />
        <path
          fill="#055177"
          d="M256.979,295.699h-24.998c-3.452,0-6.25-2.799-6.25-6.25v-31.247c0-3.452,2.798-6.249,6.25-6.249h24.998
   c3.451,0,6.249,2.797,6.249,6.249v31.247C263.229,292.9,260.431,295.699,256.979,295.699z M238.231,283.199h12.5v-18.748h-12.5
   V283.199z"
        />
        <path
          fill="#055177"
          d="M281.978,258.202h24.998v31.247h-24.998V258.202z"
        />
        <path
          fill="#055177"
          d="M306.976,295.699h-24.998c-3.451,0-6.249-2.799-6.249-6.25v-31.247c0-3.452,2.798-6.249,6.249-6.249h24.998
   c3.451,0,6.249,2.797,6.249,6.249v31.247C313.225,292.9,310.427,295.699,306.976,295.699z M288.227,283.199h12.5v-18.748h-12.5
   V283.199z"
        />
        <path
          fill="#055177"
          d="M256.979,326.945h24.998v31.248h-24.998V326.945z"
        />
        <path
          fill="#055177"
          d="M281.978,364.443h-24.998c-3.452,0-6.249-2.799-6.249-6.25v-31.248c0-3.451,2.797-6.248,6.249-6.248h24.998
   c3.451,0,6.249,2.797,6.249,6.248v31.248C288.227,361.645,285.429,364.443,281.978,364.443z M263.229,351.943h12.5v-18.748h-12.5
   V351.943z"
        />
      </g>
      <path
        fill="#FCB44D"
        d="M81.994,251.953c-11.55-8.69-16.525-23.615-12.499-37.497l18.749,6.249c0,0-18.749-31.247,6.25-49.996
 c0,0,12.499,18.749,31.247,18.749c15.951,11.286,21.262,32.532,12.499,49.996c-11.366,18.824-35.839,24.871-54.664,13.505
 C83.04,252.636,82.513,252.301,81.994,251.953z"
      />
      <path
        fill="#FCB44D"
        d="M104.436,264.846c-9.174,0-18.152-2.657-25.848-7.649l-0.062-0.044
 c-13.8-10.116-19.825-27.766-15.092-44.209c0.419-1.689,1.525-3.128,3.05-3.969c1.529-0.848,3.345-1.009,5-0.443l5.737,1.912
 c-5.669-16.266-0.208-34.332,13.524-44.733c1.37-1.041,3.112-1.462,4.806-1.163c1.692,0.291,3.191,1.264,4.144,2.694
 c0.1,0.149,10.955,15.967,26.047,15.967c1.328-0.001,2.622,0.422,3.693,1.206c18.374,13.21,24.429,37.81,14.287,58.039
 c-5.967,10.54-16.041,18.131-27.816,20.961C112.155,264.361,108.303,264.842,104.436,264.846z M85.444,246.722
 c8.11,5.253,18.037,6.902,27.41,4.556c8.407-1.979,15.616-7.355,19.911-14.849c6.931-14.082,3.079-31.093-9.243-40.815
 c-11.549-1.338-22.162-7.001-29.704-15.849c-13.387,15.274-0.8,36.716-0.207,37.703c1.775,2.96,0.815,6.799-2.145,8.574
 c-1.564,0.938-3.461,1.146-5.192,0.569l-11.78-3.894c-0.569,9.329,3.534,18.332,10.949,24.022V246.722z"
      />
      <path
        fill="#146FB4"
        d="M156.988,183.208l-56.245,49.996h99.992l68.744-49.996H156.988z"
      />
      <path
        fill="#146FB4"
        d="M200.734,239.454h-99.992c-3.452-0.004-6.248-2.804-6.245-6.255c0.001-1.782,0.764-3.479,2.095-4.663
 l56.245-49.996c1.144-1.018,2.62-1.58,4.15-1.581h112.491c3.451-0.001,6.25,2.796,6.251,6.248c0,2.001-0.958,3.881-2.576,5.057
 l-68.744,49.996C203.341,239.036,202.054,239.454,200.734,239.454z M117.179,226.955h81.524l51.559-37.497h-90.899L117.179,226.955z
 "
      />
      <path
        fill="#055177"
        d="M100.743,233.204h99.992v124.989h-99.992V233.204z"
      />
      <path
        fill="#055177"
        d="M200.734,364.443h-99.992c-3.452,0-6.25-2.799-6.25-6.25V233.204c0-3.451,2.798-6.249,6.25-6.249h99.992
 c3.451,0,6.249,2.798,6.249,6.249v124.989C206.983,361.645,204.186,364.443,200.734,364.443z M106.992,351.943h87.493V239.454
 h-87.493V351.943z"
      />
      <path
        fill="#2290D3"
        d="M175.736,208.206h-6.25c-3.452,0-6.25-2.798-6.25-6.249c0-3.452,2.798-6.25,6.25-6.25h6.25
 c3.452,0,6.25,2.798,6.25,6.25C181.986,205.408,179.188,208.206,175.736,208.206z"
      />
      <path
        fill="#2290D3"
        d="M200.734,208.206h-6.25c-3.452,0-6.25-2.798-6.25-6.249c0-3.452,2.798-6.25,6.25-6.25h6.25
 c3.451,0,6.249,2.798,6.249,6.25C206.983,205.408,204.186,208.206,200.734,208.206z"
      />
      <path
        fill="#FCC16D"
        d="M208.321,408.189c24.998-12.5,29.91-43.746,29.91-43.746l-12.499-18.748
 c6.25-24.998-6.25-37.498-6.25-37.498c-9.609,14.33-26.743,21.672-43.746,18.748c-6.25-12.498,0-31.246,0-31.246
 c-31.248,0-62.495,49.996-62.495,49.996c-12.499-18.75,0-49.996,0-49.996c-37.497,6.248-49.996,62.494-49.996,62.494v93.742h112.491
 l31.247-43.746"
      />
      <path
        fill="#FCC16D"
        d="M175.736,458.186H63.246c-3.452,0-6.25-2.799-6.25-6.25v-93.742c-0.001-0.457,0.047-0.91,0.144-1.355
 c0.55-2.469,13.893-60.439,55.077-67.309c3.405-0.564,6.624,1.736,7.191,5.139c0.188,1.127,0.063,2.283-0.36,3.344
 c-4.233,11.037-5.733,22.934-4.375,34.678c11.368-15.311,35.616-43.24,61.064-43.24c3.452,0.002,6.248,2.801,6.247,6.252
 c0,0.666-0.106,1.328-0.316,1.959c-2.506,7.627-3.001,15.77-1.438,23.643c13.605,0.883,26.652-5.537,34.253-16.855
 c1.084-1.455,2.747-2.367,4.556-2.5c1.81-0.148,3.591,0.516,4.862,1.812c0.593,0.594,13.986,14.375,8.518,40.691l11.012,16.51
 c0.87,1.305,1.218,2.889,0.975,4.438c-0.225,1.406-5.73,34.586-33.291,48.365c-0.212,0.104-0.421,0.195-0.625,0.275l-29.653,41.516
 C179.663,457.205,177.762,458.188,175.736,458.186z M69.495,445.686h103.022l29.373-41.127c1.133-1.59,2.943-2.559,4.894-2.619
 c17.224-9.373,23.229-29.447,24.804-36.197l-11.055-16.578c-0.981-1.471-1.295-3.287-0.862-5c2.477-8.381,2.132-17.346-0.981-25.512
 c-11.411,12.043-28.169,17.447-44.465,14.338c-1.769-0.441-3.257-1.633-4.074-3.264c-3.496-8.361-4.393-17.582-2.575-26.461
 c-20.529,6.406-41.834,34.197-49.033,45.74c-1.125,1.801-3.084,2.906-5.206,2.938c-2.138,0.1-4.163-0.963-5.293-2.779
 c-9.006-13.514-7.024-31.842-4.25-43.748c-23.273,12.199-33.003,48.314-34.297,53.516V445.686z"
      />
      <path
        fill="#FCB44D"
        d="M89.494,441.174c-1.967,0-3.819-0.926-5-2.5c-20.717-27.615-2.712-68.795-1.938-70.531
 c1.404-3.152,5.1-4.57,8.252-3.166c3.153,1.406,4.57,5.1,3.165,8.254l0,0c-0.163,0.361-15.768,36.246,0.513,57.943
 c2.071,2.762,1.511,6.68-1.25,8.75c-1.082,0.811-2.397,1.25-3.75,1.25H89.494z"
      />
      <path
        fill="#FCB44D"
        d="M75.745,451.936c0,0,43.746-62.494,99.992-74.994c7.194,11.465,7.194,26.033,0,37.498
 c-12.499,18.748,99.992-37.498,99.992-37.498s6.249,24.998-6.25,37.498c0,0,68.744,0,93.742-43.748
 c24.998-43.746,56.245-31.246,56.245-31.246s-18.748,18.748-12.499,37.496c6.25,18.748,0,24.998,0,24.998
 s37.497-7.293,43.747-24.998c0,0,0,56.246-24.998,74.994H75.745z"
      />
      <path
        fill="#FCB44D"
        d="M425.716,458.186H75.745c-3.452-0.002-6.248-2.801-6.246-6.254c0-1.279,0.395-2.527,1.128-3.576
 c1.843-2.639,45.808-64.639,103.741-77.494c2.52-0.549,5.118,0.496,6.556,2.637c6.933,11.047,8.394,24.67,3.962,36.936
 c30.098-11.254,59.496-24.297,88.037-39.061c3.088-1.543,6.842-0.289,8.383,2.799c0.204,0.406,0.362,0.834,0.473,1.275
 c2.496,10.553,2.207,21.572-0.838,31.98c19.799-2.139,59.865-10.105,76.869-39.816c27.385-47.922,63.633-34.104,63.989-33.953
 c3.204,1.279,4.766,4.916,3.485,8.121c-0.314,0.787-0.786,1.502-1.387,2.102c-0.143,0.15-15.923,16.299-10.992,31.105
 c2.119,5.842,3.162,12.021,3.081,18.234c12.205-3.748,26.016-10.33,28.841-18.342c1.158-3.25,4.733-4.947,7.984-3.789
 c2.478,0.883,4.138,3.221,4.152,5.852c0,2.426-0.312,59.602-27.498,79.994C428.383,457.746,427.068,458.186,425.716,458.186
 L425.716,458.186z M88.531,445.686h334.972c12.181-10.623,17.63-33.615,19.761-51.189c-10.812,6.559-22.697,11.156-35.108,13.58
 c-3.389,0.66-6.67-1.551-7.33-4.939c-0.396-2.033,0.242-4.133,1.705-5.604c-0.075,0,3.043-4.998-1.494-18.617
 c-3.156-12.072-0.624-24.928,6.875-34.902c-9.644,0.312-25.255,5.268-39.26,29.777c-26.497,46.367-96.217,46.896-99.173,46.896
 c-3.451,0-6.248-2.799-6.248-6.25c0-1.656,0.658-3.246,1.83-4.418c5.712-5.711,6.398-15.979,5.799-23.672
 c-91.724,45.371-97.398,38.928-100.485,35.402c-2.618-3.139-2.549-7.721,0.163-10.779c5.207-8,5.938-18.113,1.938-26.779
 C134.546,394.689,102.617,428.699,88.531,445.686L88.531,445.686z"
      />
      <path
        fill="#FCC16D"
        d="M225.732,439.438c-3.452-0.002-6.249-2.801-6.248-6.252c0-1.656,0.658-3.246,1.829-4.416l6.25-6.25
 c2.483-2.398,6.439-2.33,8.837,0.152c2.339,2.422,2.339,6.262,0,8.684l-6.25,6.25C228.979,438.777,227.39,439.438,225.732,439.438z"
      />
      <path
        fill="#FD8F31"
        d="M319.475,458.186c-3.451,0.004-6.253-2.791-6.258-6.242c-0.002-2.965,2.075-5.521,4.976-6.127
 c24.479-5.154,26.249-30.621,26.292-31.715c0.098-3.371,2.908-6.025,6.279-5.928c0.099,0.002,0.197,0.008,0.295,0.016
 c3.444,0.182,6.09,3.117,5.912,6.562c-1.004,20.914-15.797,38.607-36.202,43.303C320.343,458.143,319.909,458.188,319.475,458.186z"
      />
      <path
        fill="#FD8F31"
        d="M119.491,458.186c-3.452-0.002-6.249-2.799-6.248-6.252c0-2.916,2.018-5.443,4.861-6.092
 c13.209-2.709,23.582-12.949,26.46-26.123c0.533-3.41,3.731-5.744,7.143-5.211s5.745,3.73,5.212,7.143l0,0
 c-3.619,18.26-17.809,32.584-36.034,36.373C120.428,458.131,119.96,458.186,119.491,458.186z"
      />
      <path
        fill="#FCB44D"
        d="M200.734,364.443c-3.452-0.002-6.249-2.799-6.248-6.252c0-1.656,0.658-3.244,1.829-4.416l6.25-6.25
 c2.483-2.398,6.439-2.33,8.837,0.152c2.339,2.424,2.339,6.262,0,8.686l-6.25,6.248C203.981,363.783,202.392,364.443,200.734,364.443
 z"
      />
      <path
        fill="#FCC16D"
        d="M144.489,264.451c-3.452,0-6.249-2.799-6.248-6.251c0-1.656,0.659-3.245,1.83-4.417l6.25-6.249
 c2.483-2.397,6.439-2.329,8.837,0.153c2.339,2.422,2.339,6.262,0,8.684l-6.25,6.25C147.736,263.793,146.146,264.451,144.489,264.451
 z"
      />
    </svg>
  );
}
