export default function InsanelyHotIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="511px"
      viewBox="0 0 511 511"
      enableBackground="new 0 0 511 511"
    >
      <path
        fill="#FCB44D"
        d="M383.653,194.96c-0.123,0.401-0.244,0.802-0.369,1.2c-4.352,13.976-25.413,9.814-24.169-4.749
 c1.16-13.602,0.07-27.626-3.559-41.598C340.031,89.975,283.229,51.179,222.651,55.3c-5.383,0.364-8.647,5.975-6.218,10.705
 c4.118,8.028,7.423,16.616,9.775,25.687c7.664,29.52,4.009,59.264-8.286,84.812c-14.866,30.891-59.534,30.86-74.515,0.025
 c-2.959-6.092-5.529-12.4-7.672-18.895c-1.891-5.724-9.589-6.911-13.221-2.065c-19.348,25.818-30.781,57.712-30.781,92.228
 c0,85.278,69.783,154.552,156.338,155.732c82.975,1.132,198.445-82.169,149.858-209.057
 C395.374,187.805,385.695,188.129,383.653,194.96z"
      />
      <path
        fill="#FD8F31"
        d="M350.76,366.896c24.971-35.382,35.029-82.469,13.815-137.865c-2.554-6.667-12.23-6.346-14.276,0.489
 c-0.115,0.398-0.24,0.796-0.365,1.193c-4.352,13.981-25.41,9.817-24.17-4.748c1.159-13.6,0.068-27.62-3.557-41.596
 c-12.278-47.325-50.377-81.483-95.746-91.659c-0.084-0.336-0.171-0.679-0.256-1.015c-2.352-9.074-5.65-17.663-9.778-25.687
 c-2.42-4.733,0.841-10.344,6.221-10.705c60.581-4.12,117.383,34.672,132.915,94.511c3.616,13.975,4.716,27.996,3.548,41.595
 c-1.237,14.565,19.818,18.729,24.17,4.748c0.125-0.397,0.249-0.796,0.368-1.193c2.046-6.834,11.722-7.155,14.273-0.489
 C427.062,270.553,397.21,330.963,350.76,366.896z"
      />
      <path
        fill="#FCB44D"
        d="M403.352,351.378c-1.211,0-2.432-0.349-3.501-1.076c-2.797-1.899-3.494-5.67-1.56-8.419
 c14.806-21.001,23.924-44.183,27.107-68.897c0.427-3.313,3.52-5.673,6.885-5.244c3.376,0.418,5.769,3.448,5.343,6.765
 c-3.433,26.661-13.258,51.651-29.201,74.27C407.229,350.47,405.306,351.378,403.352,351.378z"
      />
      <path
        fill="#FCB44D"
        d="M378.183,150.202c-2.748,0-5.256-1.82-5.966-4.559c-5.983-23.068-17.825-44.456-34.239-61.846
 c-2.311-2.45-2.164-6.281,0.331-8.554c2.495-2.27,6.396-2.126,8.707,0.324c17.806,18.87,30.649,42.067,37.142,67.086
 c0.841,3.24-1.152,6.531-4.448,7.357C379.198,150.141,378.687,150.202,378.183,150.202z"
      />
      <path
        fill="#FD8F31"
        d="M196.805,172.753c-0.885,0-1.779-0.187-2.629-0.582c-3.078-1.429-4.393-5.036-2.938-8.055
 c9.764-20.29,12.028-43.471,6.371-65.266c-0.841-3.239,1.152-6.534,4.448-7.357c3.299-0.823,6.654,1.129,7.492,4.368
 c6.364,24.518,3.816,50.597-7.171,73.425C201.325,171.471,199.113,172.753,196.805,172.753z"
      />
      <path
        fill="#FCB44D"
        d="M99.179,353.4c-1.981,0-3.925-0.937-5.115-2.671c-8.361-12.194-15.212-25.353-20.358-39.111
 c-1.174-3.136,0.465-6.614,3.657-7.768c3.197-1.153,6.738,0.458,7.91,3.595c4.807,12.844,11.202,25.133,19.012,36.523
 c1.903,2.771,1.156,6.535-1.667,8.401C101.559,353.067,100.362,353.4,99.179,353.4z"
      />
      <path
        fill="#FCB44D"
        d="M114.683,372.95c-1.691,0-3.374-0.679-4.591-2.014c-1.383-1.518-2.76-3.077-4.093-4.639
 c-2.187-2.56-1.848-6.378,0.763-8.522c2.604-2.148,6.492-1.813,8.679,0.746c1.246,1.459,2.535,2.923,3.831,4.338
 c2.271,2.491,2.056,6.316-0.479,8.546C117.617,372.443,116.147,372.95,114.683,372.95z"
      />
      <path
        fill="#FCC16D"
        d="M180.712,228.651c-3.405,0-6.162-2.711-6.162-6.053c0-3.343,2.757-6.051,6.159-6.051
 c22.797-0.003,43.011-12.583,52.755-32.83c1.455-3.023,5.13-4.317,8.205-2.885c3.078,1.429,4.393,5.036,2.938,8.056
 C232.804,213.412,208.322,228.647,180.712,228.651z"
      />
      <path
        fill="#B57F5F"
        d="M318.01,456h-6.377c-4.538,0-8.218-3.613-8.218-8.067v-40.626h22.81v40.626
 C326.225,452.387,322.549,456,318.01,456z"
      />
      <path
        fill="#9F6E50"
        d="M318.01,456h-3.19v-48.693h11.408v40.626C326.225,452.387,322.549,456,318.01,456z"
      />
      <path
        fill="#E5394B"
        d="M308.938,273.492L259.18,408.154c-1.482,4.008,1.539,8.245,5.882,8.245h99.517
 c4.342,0,7.364-4.237,5.886-8.245l-49.759-134.662C318.707,268.092,310.932,268.092,308.938,273.492z"
      />
      <path
        fill="#FC4E51"
        d="M364.582,416.396h-9.825c-0.057-0.291-0.145-0.578-0.25-0.868L305.48,282.848l3.455-9.355
 c1.996-5.396,9.777-5.396,11.766,0l49.765,134.658C371.942,412.162,368.918,416.396,364.582,416.396z"
      />
      <path
        fill="#B57F5F"
        d="M194.088,456h-6.38c-4.535,0-8.215-3.613-8.215-8.067v-40.626h22.81v40.626
 C202.303,452.387,198.628,456,194.088,456z"
      />
      <path
        fill="#9F6E50"
        d="M194.088,456h-3.19v-48.693h11.405v40.626C202.303,452.387,198.628,456,194.088,456z"
      />
      <path
        fill="#E5394B"
        d="M185.018,273.492l-49.759,134.662c-1.482,4.008,1.54,8.245,5.882,8.245h99.517
 c4.342,0,7.364-4.237,5.884-8.245L196.78,273.492C194.787,268.092,187.011,268.092,185.018,273.492z"
      />
      <path
        fill="#FC4E51"
        d="M240.661,416.396h-9.826c-0.059-0.291-0.143-0.578-0.252-0.868L181.56,282.848l3.455-9.355
 c1.997-5.396,9.776-5.396,11.766,0l49.761,134.658C248.021,412.162,244.997,416.396,240.661,416.396z"
      />
      <path
        fill="#B57F5F"
        d="M257.666,456h-9.61c-4.539,0-8.218-3.613-8.218-8.067v-61.932h26.043v61.932
 C265.881,452.387,262.202,456,257.666,456z"
      />
      <path
        fill="#9F6E50"
        d="M257.666,456h-4.807v-69.999h13.021v61.932C265.881,452.387,262.202,456,257.666,456z"
      />
      <path
        fill="#E5394B"
        d="M246.143,233.219l-56.811,153.749c-1.691,4.578,1.76,9.411,6.716,9.411h113.623
 c4.959,0,8.407-4.833,6.719-9.411l-56.813-153.749C257.298,227.054,248.42,227.054,246.143,233.219z"
      />
      <path
        fill="#FD8F31"
        d="M134.916,293.434c-3.402,0-6.203-2.708-6.203-6.053c0-3.34,2.72-6.051,6.122-6.051h0.081
 c3.405,0,6.162,2.711,6.162,6.051C141.078,290.726,138.321,293.434,134.916,293.434z"
      />
      <path
        fill="#FD8F31"
        d="M149.682,298.926c-0.021,0-0.04,0-0.062,0c-3.402-0.035-6.174-2.77-6.14-6.11
 c0.035-3.322,2.748-5.994,6.122-5.994h0.062h0.081c3.402,0.034,6.134,2.773,6.1,6.112
 C155.809,296.257,153.056,298.926,149.682,298.926z"
      />
      <path
        fill="#FAB03C"
        d="M300.316,114.404c-3.405,0-6.203-2.708-6.203-6.051c0-3.341,2.72-6.052,6.121-6.052h0.082
 c3.404,0,6.162,2.71,6.162,6.052C306.479,111.696,303.721,114.404,300.316,114.404z"
      />
      <path
        fill="#FCB44D"
        d="M395.83,140.005c-3.405,0-6.203-2.711-6.203-6.051c0-3.344,2.721-6.052,6.122-6.052h0.081
 c3.404,0,6.161,2.708,6.161,6.052C401.991,137.294,399.232,140.005,395.83,140.005z"
      />
      <g>
        <path
          fill="#FD8F31"
          d="M176.108,184.471c-0.022,0-0.041,0-0.062,0c-3.405-0.034-6.175-2.769-6.14-6.113
   c0.034-3.319,2.748-5.991,6.118-5.991h0.062h0.081c3.405,0.034,6.133,2.772,6.1,6.113
   C182.232,181.802,179.481,184.471,176.108,184.471z"
        />
        <path
          fill="#FD8F31"
          d="M163.267,174.485c-0.021,0-0.041,0-0.062,0c-3.401-0.034-6.174-2.769-6.14-6.113
   c0.035-3.319,2.748-5.991,6.121-5.991h0.062h0.081c3.402,0.034,6.134,2.769,6.1,6.113
   C169.395,171.817,166.641,174.485,163.267,174.485z"
        />
      </g>
    </svg>
  );
}
