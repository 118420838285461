import { Gender, SexualPreference } from '@global/constants';

export const langDefPtbr = {
  lang: 'pt-br',
  ptBr: 'Português (BR)',
  enUs: 'Inglês (US)',
  footerPrefix: 'Esse jogo é desenvolvido com muito',
  and: 'e',
  by: 'por',
  rules: 'Regras',
  rulesArr: [
    'Você deve ter 18 ou mais anos de idade',
    'Ninguém deve ser obrigado a jogar. Este é um comportamento abusivo',
    'Os jogadores devem confiar uns nos outros',
    'Todos tem o direito de sair do jogo se ficarem desconfortáveis',
    'Todos tem o direito de não responder uma pergunta ou realizar um desafio se não quiserem',
    'Antes de jogarem, combinem regras e limites pessoais aceitos por todos',
    'Pegue uma bebida e divirta-se 😘',
  ],
  startGame: 'Jogar',
  players: 'Jogadores',
  noPLayers: 'Sem Jogadores!',
  addPlayerInstruction:
    "Clique em 'Novo Jogador' abaixo e comece a adicionar seus jogadores!",
  addPlayer: 'Novo Jogador',
  back: 'Voltar',
  createPlayer: 'Criar Jogador',
  editPlayer: 'Editar Jogador',
  name: 'Nome',
  playerName: 'Nome do Jogador',
  gender: 'Gênero',
  sexuality: 'Sexualidade',
  cancel: 'Cancelar',
  save: 'Salvar',
  male: 'Homem',
  female: 'Mulher',
  straight: 'Hétero',
  bisexual: 'Bissexual',
  gay: 'Gay',
  lesbian: 'Lésbica',
  man: 'Homem',
  woman: 'Mulher',
  min2players: 'Você precisa de pelo menos 2 jogadores para jogar',
  removePlayerQuestion: 'Remover o jogador',
  confirmPlayerRemove: 'Sim, remova!',
  cancelPlayerRemove: 'Não, desculpa :(',
  bottleClick: 'Clique na Garrafa para girar',
  playerOrder: 'Ordem dos Jogadores',
  exit: 'Sair',
  goToPlayersQuestion: 'Ir para a Lista de Jogadores?',
  goToPlayersConfirm: 'Sim!',
  goToPlayersCancel: 'Não!',
  level: 'Nível',
  exitGameQuestion: 'Você quer mesmo sair do jogo?',
  exitGameDescription:
    'O nível voltará para Quebra Gelo e as Tarefas jogadas ficarão disponíveis de novo.',
  exitKeepingPlayers: 'Sim, mas salve os Jogadores',
  exitDeletingPlayers: 'Sim, e apague tudo',
  exitNo: 'Não, vamos jogar mais :D',
  levelSelector: 'Selecione um Nível',
  random: 'Aleatória',
  randomDescription: 'O Jogador da Vez é escolhido aleatoriamente pela Garrafa',
  sequence: 'Sequência',
  sequenceDescription:
    'O Jogador da Vez segue a ordem da lista e após a último, volta ao primeiro',
  truth: 'Verdade',
  dare: 'Desafio',
  aOneMale: 'Um',
  aOneFemale: 'Uma',
  for: 'para',
  choose: 'escolha',
  newMale: 'Novo',
  newFemale: 'Nova',
  done: 'Feito!',
  skip: 'Passar a vez',
  noTruths: 'Somente Desafios nesse nível',
  [Gender.FEMALE + SexualPreference.BISEXUAL]: 'Mulher Bi',
  [Gender.MALE + SexualPreference.BISEXUAL]: 'Homem Bi',
  [Gender.FEMALE + SexualPreference.HOMOSEXUAL]: 'Mulher Lésbica',
  [Gender.MALE + SexualPreference.HOMOSEXUAL]: 'Homem Gay',
  [Gender.FEMALE + SexualPreference.HETEROSEXUAL]: 'Mulher Hétero',
  [Gender.MALE + SexualPreference.HETEROSEXUAL]: 'Homem Hétero',
};
