export default function VeryHotIcon() {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="512px"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <g>
        <g>
          <path
            fill="#B57F5F"
            d="M448.335,375.495v79.795H178.778c-23.767,0-43.03-17.867-43.03-39.9c0-2.11,0.176-4.176,0.512-6.198
			c3.217-19.087,21.026-33.696,42.518-33.696H448.335L448.335,375.495z"
          />
          <path
            fill="#9F6E50"
            d="M416.596,375.495c-5.882,7.615-12.446,14.858-19.675,21.652c-35.711,33.592-83.374,52.497-134.198,53.231
			c-1.036,0.02-2.054,0.025-3.087,0.025h-0.009c-45.679-0.008-88.867-14.529-123.367-41.214
			c3.217-19.087,21.026-33.695,42.519-33.695H416.596L416.596,375.495z"
          />
          <ellipse
            fill="#E6AC8E"
            cx="448.333"
            cy="415.391"
            rx="43.032"
            ry="39.896"
          />
          <path
            fill="#E09977"
            d="M491.365,415.39c0,22.034-19.268,39.898-43.029,39.898c-4.792,0-9.399-0.725-13.702-2.068
			c17.055-5.305,29.335-20.236,29.335-37.83c0-17.587-12.28-32.52-29.335-37.826c4.303-1.341,8.91-2.066,13.702-2.066
			C472.098,375.497,491.365,393.36,491.365,415.39L491.365,415.39z"
          />
          <g>
            <path
              fill="#9F6E50"
              d="M448.333,441.177c-15.337,0-27.814-11.565-27.814-25.783c0-14.219,12.477-25.785,27.814-25.785
				c3.862,0,6.992,2.903,6.992,6.485c0,3.578-3.13,6.48-6.992,6.48c-7.624,0-13.828,5.75-13.828,12.819
				c0,7.068,6.203,12.818,13.828,12.818c7.622,0,13.827-5.75,13.827-12.818c0-3.581,3.13-6.484,6.992-6.484
				c3.863,0,6.993,2.902,6.993,6.484C476.145,429.61,463.669,441.177,448.333,441.177z"
            />
          </g>
        </g>
        <g>
          <path
            fill="#FC4E51"
            d="M432.269,250.874c-0.954-6.841-9.691-10.014-15.362-5.544c-6.005,4.73-12.361,9.09-19.026,13.039
			c-3.302,1.956-7.558-0.453-7.175-4.067c11.947-112.793-78.728-200.222-157.42-200.555c-31.815-0.135,41.106,71.324-41.961,144.412
			c-7.797,6.86-14.565,13.373-20.448,19.55c-1.958,2.057-5.483,1.918-7.23-0.297c-10.318-13.09-18.565-27.67-24.286-43.335
			c-2.366-6.481-11.544-7.875-16.131-2.515c-23.576,27.548-37.638,62.396-37.616,100.297
			c0.054,90.41,79.307,162.646,176.817,161.238c94.846-1.37,171.286-73.077,171.286-161.333
			C433.715,264.686,433.224,257.711,432.269,250.874L432.269,250.874z"
          />
          <path
            fill="#E5394B"
            d="M433.715,271.766c0,88.256-76.438,159.961-171.283,161.335c-8.662,0.121-17.175-0.329-25.5-1.331
			c85.427-10.295,151.402-78.005,151.402-160.004c0-7.115-0.494-14.117-1.454-20.981c-0.962-6.846-9.679-9.923-15.357-5.454
			c-3.406,2.686-6.927,5.25-10.553,7.688c-6.418,4.317-15.338-0.303-14.903-7.643c5.386-91.243-56.015-164.375-121.256-185.473
			c0.541-4.001,2.9-6.181,8.476-6.155c78.69,0.329,169.367,87.755,157.417,200.553c-0.379,3.585,3.844,6.041,7.118,4.104
			c6.686-3.959,13.062-8.33,19.084-13.073c5.669-4.47,14.396-1.393,15.355,5.455C433.221,257.648,433.715,264.65,433.715,271.766
			L433.715,271.766z"
          />
          <path
            fill="#FCC16D"
            d="M373.985,312.413c-0.63-4.507-6.41-6.644-10.143-3.7c-3.137,2.476-6.418,4.795-9.831,6.949
			c-3.213,2.028-7.581-0.277-7.283-3.871c6.088-73.303-53.089-129.725-104.536-129.941c-21.073-0.089,27.224,47.24-27.792,95.646
			c-4.604,4.051-8.665,7.918-12.253,11.608c-2.015,2.071-5.563,1.854-7.294-0.427c-6.268-8.261-11.308-17.379-14.869-27.132
			c-1.56-4.272-7.564-5.303-10.594-1.773c-15.475,18.035-24.787,40.808-25.002,65.594c-0.502,57.723,51.307,106.901,113.565,107.737
			c64.447,0.866,116.989-47.307,116.989-106.857C374.944,321.561,374.616,316.941,373.985,312.413z"
          />
          <path
            fill="#FCB44D"
            d="M374.945,326.249c0,58.452-50.63,105.943-113.445,106.852c-6.518,0.095-12.905-0.313-19.132-1.167
			c55.534-7.676,98.125-52.073,98.125-105.685c0-4.711-0.335-9.345-0.969-13.898c-0.635-4.53-6.415-6.57-10.173-3.614
			c-3.129,2.47-6.4,4.784-9.804,6.933c-3.222,2.034-7.57-0.293-7.271-3.896c4.953-59.797-33.51-108.352-75.784-124.332
			c-0.047-3.612,1.352-5.609,5.696-5.592c51.45,0.216,110.622,56.642,104.537,129.94c-0.3,3.595,4.068,5.9,7.283,3.872
			c3.401-2.146,6.67-4.456,9.801-6.926c3.748-2.955,9.528-0.915,10.163,3.613C374.61,316.904,374.945,321.538,374.945,326.249
			L374.945,326.249z"
          />
          <path
            fill="#FFEED3"
            d="M306.047,385.976c13.903-51.286-3.266-88.031-16.382-106.938c-4.097-5.904-13.606-5.121-16.497,1.365
			c-5.791,12.989-19.461,31.809-51.731,48.274c-37.065,18.913-36.518,45.019-33.736,57.299H306.047z"
          />
          <path
            fill="#FDDAA9"
            d="M306.05,385.98h-32.812c10.938-40.334,2.648-71.679-7.776-92.425c3.487-4.761,5.958-9.224,7.702-13.155
			c2.89-6.484,12.4-7.271,16.503-1.365C302.776,297.948,319.952,334.695,306.05,385.98L306.05,385.98z"
          />
        </g>
        <g>
          <path
            fill="#B57F5F"
            d="M70.995,455.287c-23.767,0-43.033-17.861-43.033-39.896c0-22.033,19.266-39.896,43.033-39.896h244.596
			v79.791H70.995z"
          />
          <g>
            <path
              fill="#9F6E50"
              d="M240.21,440.273c-3.862,0-6.993-2.903-6.993-6.483s3.131-6.482,6.993-6.482
				c21.813,0,34.022-9.777,34.139-9.876c2.858-2.406,7.28-2.214,9.877,0.436c2.6,2.649,2.389,6.749-0.469,9.157
				C283.115,427.566,267.725,440.273,240.21,440.273L240.21,440.273z"
            />
          </g>
          <ellipse
            fill="#EBBBA5"
            cx="315.591"
            cy="415.391"
            rx="43.035"
            ry="39.896"
          />
          <path
            fill="#E6AC8E"
            d="M358.626,415.39c0,22.034-19.27,39.898-43.039,39.898c-4.783,0-9.392-0.725-13.693-2.068
			c17.055-5.305,29.334-20.236,29.334-37.83c0-17.587-12.279-32.52-29.334-37.826c4.302-1.341,8.91-2.066,13.693-2.066
			C339.357,375.497,358.626,393.36,358.626,415.39L358.626,415.39z"
          />
          <g>
            <path
              fill="#9F6E50"
              d="M315.591,441.177c-15.336,0-27.813-11.565-27.813-25.783c0-14.219,12.478-25.785,27.813-25.785
				c3.863,0,6.994,2.903,6.994,6.485c0,3.578-3.131,6.48-6.994,6.48c-7.623,0-13.827,5.75-13.827,12.819
				c0,7.068,6.202,12.818,13.827,12.818s13.828-5.75,13.828-12.818c0-3.581,3.131-6.484,6.993-6.484s6.993,2.902,6.993,6.484
				C343.403,429.61,330.928,441.177,315.591,441.177z"
            />
          </g>
          <g>
            <path
              fill="#9F6E50"
              d="M189.752,416.969c-0.597,0.241-9.454,3.647-27.515,3.647c-12.075,0-28.271-1.521-48.868-6.545
				c-51.748-12.604-77.453-7.874-84.699-5.895c1.025-5.23,3.151-10.107,6.154-14.402c14.536-1.943,40.821-2.29,82.107,7.764
				c46.014,11.202,67.086,3.604,67.282,3.526c3.553-1.418,7.665,0.095,9.194,3.38C194.936,411.738,193.305,415.552,189.752,416.969
				L189.752,416.969z"
            />
          </g>
          <g id="XMLID_337_">
            <path
              fill="#9F6E50"
              d="M246.146,409.338c-0.014,0-0.027,0-0.041,0c-3.862-0.021-7.015-2.942-6.991-6.522
				c0.022-3.567,3.11-6.446,6.952-6.446h0.042h0.079c3.861,0.021,6.976,2.94,6.952,6.522
				C253.113,406.461,249.987,409.338,246.146,409.338L246.146,409.338z"
            />
          </g>
          <g>
            <path
              fill="#9F6E50"
              d="M164.129,455.289h-21.874c-30.769-18.854-77.714-20.081-78.257-20.089
				c-3.86-0.077-6.918-3.052-6.835-6.621c0.084-3.537,3.198-6.345,6.984-6.345h0.158
				C67.066,422.295,131.589,423.937,164.129,455.289z"
            />
          </g>
        </g>
        <g>
          <path
            fill="#FC4E51"
            d="M420.18,175.367c-2.574,0-4.661-1.936-4.661-4.322v-15.679c0-14.256,12.466-25.812,27.843-25.812
			c2.576,0,4.662,1.934,4.662,4.321v15.68C448.023,163.809,435.558,175.367,420.18,175.367z"
          />
        </g>
        <g>
          <path
            fill="#FC4E51"
            d="M165.644,115.375c2.576,0,4.662-1.935,4.662-4.322v-15.68c0-14.256-12.466-25.813-27.843-25.813
			c-2.575,0-4.662,1.936-4.662,4.322V89.56C137.801,103.818,150.267,115.375,165.644,115.375z"
          />
        </g>
      </g>
    </svg>
  );
}
