export function ExitIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="512px"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <g id="Flat">
        <path
          fill="#E5394B"
          d="M35.334,159.844h445.875v284.438H35.334V159.844z"
        />
        <g>
          <path
            fill="#FCC16D"
            d="M135.271,390.469v-7.688h-30.75v-7.688H89.146v15.375c-0.001,4.244,3.439,7.687,7.683,7.688
			c0.001,0,0.003,0,0.004,0h40.513C135.986,395.823,135.27,393.17,135.271,390.469z"
          />
          <path
            fill="#FCC16D"
            d="M304.396,205.969v184.5c0.001,4.244-3.438,7.687-7.684,7.688c-0.002,0-0.002,0-0.004,0h-13.453
			l-40.359,23.062v-23.062h-55.888c1.361-2.333,2.077-4.986,2.076-7.688v-7.688h53.812V236.719l40.359-23.062H104.521v30.75H89.146
			v-38.438c-0.001-4.245,3.439-7.687,7.683-7.688c0.001,0,0.003,0,0.004,0h199.875c4.244-0.001,7.687,3.439,7.688,7.683
			C304.396,205.966,304.396,205.967,304.396,205.969z"
          />
          <path
            fill="#FCC16D"
            d="M196.771,275.156h-20.737l-15.663-10.445c9.101-11.742,6.959-28.64-4.783-37.741
			c-11.743-9.101-28.64-6.959-37.741,4.782c-4.473,5.772-6.408,13.115-5.36,20.342H81.459c-2.419,0.001-4.697,1.141-6.15,3.075
			l-23.062,30.75c-2.294,3.061-1.991,7.344,0.711,10.052l15.375,15.375c3.006,2.998,7.872,2.998,10.878,0l20.804-20.814h4.507
			v38.438h-30.75c-4.245-0.001-7.687,3.438-7.688,7.683c0,0.002,0,0.003,0,0.005v23.062c-0.001,4.245,3.439,7.687,7.683,7.688
			c0.001,0,0.003,0,0.004,0h53.812c3.309,0,6.247-2.117,7.293-5.256l1.009-3.027l7.072,4.709v26.637
			c-0.001,4.244,3.439,7.687,7.684,7.688c0.001,0,0.002,0,0.004,0h23.062c4.245,0.001,7.687-3.438,7.688-7.684
			c0-0.001,0-0.003,0-0.004v-53.812c-0.001-2.419-1.14-4.697-3.075-6.15l-27.675-20.756v-0.865l12.944,4.315
			c0.784,0.259,1.605,0.393,2.431,0.394h30.75c4.245,0.001,7.687-3.438,7.688-7.684c0-0.001,0-0.002,0-0.004v-23.062
			c0.001-4.244-3.439-7.687-7.684-7.688C196.774,275.156,196.773,275.156,196.771,275.156z M139.115,236.719
			c6.368,0,11.531,5.163,11.531,11.531c0,6.369-5.163,11.531-11.531,11.531c-6.369,0-11.531-5.162-11.531-11.531
			C127.589,241.884,132.749,236.725,139.115,236.719z M189.084,298.219h-21.813l-21.881-7.293c-4.026-1.345-8.38,0.831-9.723,4.859
			c-0.262,0.783-0.395,1.606-0.395,2.434v15.375c0.001,2.42,1.14,4.698,3.075,6.15l27.675,20.756v42.281h-7.688v-23.062
			c-0.004-2.569-1.287-4.969-3.421-6.4l-13.981-9.321l1.633-4.909l-14.586-4.863l-5.938,17.807H81.459v-7.688h30.75
			c4.245,0.001,7.687-3.438,7.688-7.684c0-0.002,0-0.003,0-0.004v-53.812c0.001-4.244-3.439-7.687-7.683-7.688
			c-0.001,0-0.003,0-0.004,0H96.834c-2.039-0.001-3.995,0.809-5.439,2.248l-17.623,17.633l-5.228-5.227l16.759-22.342h35.007
			c6.944,6.814,17.031,9.341,26.368,6.602l22.764,15.173c1.265,0.837,2.749,1.286,4.267,1.288h15.375V298.219z"
          />
          <path
            fill="#FCC16D"
            d="M365.895,398.156c-4.245-0.001-7.686-3.442-7.686-7.688v-46.125h-30.75c-4.245,0-7.688-3.441-7.688-7.688
			l0,0v-61.5c0-4.245,3.442-7.688,7.688-7.688l0,0h30.75v-46.125c0-4.245,3.442-7.688,7.688-7.688c2.038,0,3.994,0.81,5.436,2.252
			l84.562,84.563c3.003,3,3.003,7.867,0,10.869l0,0l-84.562,84.563C369.891,397.347,367.935,398.157,365.895,398.156z
			 M335.146,328.969h30.75c4.246,0,7.688,3.441,7.688,7.688l0,0v35.253l66.003-66.003l-66.003-66.003v35.253
			c0,4.245-3.441,7.688-7.688,7.688l0,0h-30.75V328.969z"
          />
        </g>
        <path fill="#2F4054" d="M89.146,75.281h76.875v84.562H89.146V75.281z" />
        <path
          fill="#2F4054"
          d="M350.521,75.281h76.875v84.562h-76.875V75.281z"
        />
      </g>
    </svg>
  );
}
