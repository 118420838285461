import { Avatar, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import { colors } from '@global/constants';
import { ReactElement } from 'react';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';

type ModalListItemProps = {
  title: string;
  description: string;
  footer?: string;
  icon: ReactElement;
  isSelected: boolean;
  onClick: () => void;
};

export function ModalListItem(props: ModalListItemProps) {
  const { title, description, footer, icon, isSelected, onClick } = props;
  return (
    <Flex
      as="button"
      borderBottom={`1px solid ${colors.esquentaBlue}`}
      height="auto"
      padding="1.25rem"
      width="100%"
      onClick={() => onClick()}
    >
      <Flex minW={0}>
        <Avatar
          icon={icon}
          bg={colors.esquentaBlack}
          iconLabel={`${title} icon`}
          mr={{ base: 2, lg: 4 }}
          minHeight="100%"
          size="lg"
        />
        <Flex direction="column" overflow="hidden">
          <Heading
            as="h6"
            pos="relative"
            minW={0}
            overflow="hidden"
            whiteSpace="break-spaces"
            textOverflow="ellipsis"
            className={`playerName`}
            color={isSelected ? colors.esquentaPink : colors.esquentaBlue}
            textAlign="left"
            marginBottom="5px"
            fontSize="1.5rem"
          >
            {title}
          </Heading>
          <Text
            color="textSecondary"
            fontSize="md"
            overflow="hidden"
            textOverflow="ellipsis"
            textAlign="left"
          >
            {description}
          </Text>
          {footer && (
            <Text
              color="textSecondary"
              fontSize="sm"
              marginTop="5%"
              overflow="hidden"
              textOverflow="ellipsis"
              textAlign="left"
            >
              {footer}
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex
        shrink={0}
        basis="20%"
        mt={1}
        ml="auto"
        color="textSecondary"
        fontSize="xl"
        justifyContent="center"
        direction="column"
      >
        <Flex direction="row" justifyContent="center">
          <Icon
            color={isSelected ? colors.esquentaPink : colors.esquentaBlue}
            margin="5px 5px 5px 5"
            fontSize="1.5rem"
            as={isSelected ? ImCheckboxChecked : ImCheckboxUnchecked}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
