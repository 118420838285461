import { Box, Heading, Text } from '@chakra-ui/react';
import { useStrings } from '@context/strings';
import { colors } from '@global/constants';

export function NoPlayers() {
  const { strings } = useStrings();
  return (
    <Box padding="4rem 2rem" bgColor={colors.black}>
      <Heading color={colors.esquentaPink}>{strings.noPLayers}</Heading>
      <Text size="15px" color="white">
        {strings.addPlayerInstruction}
      </Text>
    </Box>
  );
}
