import { Language } from '@global/constants';
import { getStrings, LangDef } from '@global/strings';
import { createContext, useContext } from 'react';

export type StringsContextType = {
  strings: LangDef;
  setStrings: (strings: Language) => void;
};

export const StringsContext = createContext<StringsContextType>({
  strings: getStrings(Language.PT_BR),
  setStrings: () => console.warn('no provider'),
});

export const useStrings = () => useContext(StringsContext);
