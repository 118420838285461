export default function FinishLineIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="512px"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <path
        fill="#FD8F31"
        d="M74.749,288.993c0,0-12.5-18.75-12.5-62.5c0,0,0-81.25,43.75-112.5c0,0,0,50,12.5,62.5
	c0,0,12.5-87.5,62.5-112.5c0,0,6.25,56.25,31.25,75c43.75,20.787,71.213,63.25,68.75,106.25
	c-3.656,63.895-72.326,110.625-131.25,100.001C104.037,336.994,79.437,296.963,74.749,288.993z"
      />
      <path
        fill="#FD8F31"
        d="M166.85,352.994c-6.106,0.013-12.202-0.523-18.212-1.601c-44.757-8.081-70.263-43.943-79.213-59.126
	c-1.438-2.255-13.425-22.437-13.425-65.774c0-3.45,0.525-84.844,46.369-117.587c2.81-2.006,6.712-1.354,8.718,1.455
	c0.757,1.06,1.163,2.33,1.163,3.632c-0.012,14.074,1.347,28.115,4.057,41.925c6.95-28.231,24.256-78.694,61.875-97.5
	c3.088-1.543,6.842-0.292,8.385,2.796c0.329,0.659,0.54,1.371,0.621,2.104c0.062,0.531,6.169,53.056,28.319,70.332
	C261,155.524,289.825,200.437,287.25,245.6c-2.548,31.685-18.517,60.77-43.881,79.925
	C221.616,342.918,194.697,352.581,166.85,352.994z M100.137,127.437c-31.244,33.294-31.638,98.344-31.638,99.057
	c0,40.869,11.356,58.888,11.475,59.068l0.163,0.263c6.25,10.626,29.837,45.888,70.725,53.27
	c30.283,4.461,61.046-4.027,84.757-23.388c22.681-16.829,36.955-42.661,39.131-70.819c2.306-40.337-23.888-80.625-65.194-100.25
	c-0.376-0.173-0.733-0.382-1.068-0.625c-20.582-15.438-29.263-52.381-32.312-69.881c-40.3,27.987-51.388,102.5-51.5,103.269
	c-0.481,3.418-3.643,5.799-7.061,5.317c-1.352-0.19-2.603-0.817-3.564-1.786C104.086,170.918,101.049,144.375,100.137,127.437z"
      />
      <path
        fill="#FF7F1F"
        d="M162.25,188.993c-3.452,0-6.25-2.798-6.25-6.25c0-1.353,0.438-2.668,1.25-3.75
	c10.156-15.036,14.22-33.369,11.369-51.288c-0.591-3.401,1.688-6.636,5.089-7.227c3.284-0.57,6.438,1.537,7.167,4.79
	c3.632,21.405-1.258,43.381-13.625,61.225C166.069,188.067,164.217,188.993,162.25,188.993L162.25,188.993z"
      />
      <path
        fill="#FCC16D"
        d="M105.999,310.575c-2.729,0-5.144-1.771-5.962-4.375c-18.981-60.413-12.962-97.932-12.7-99.501
	c0.606-3.397,3.852-5.662,7.25-5.057c3.355,0.598,5.613,3.772,5.076,7.139c-0.112,0.668-5.625,36.662,12.3,93.668
	c1.036,3.293-0.794,6.802-4.087,7.838C107.268,310.478,106.636,310.575,105.999,310.575z"
      />
      <path
        fill="#FD8F31"
        d="M206,207.743c0,0,70.313-62.5,68.75-106.25c0,0,31.25,6.25,37.5,37.5c0,0,43.75-43.75,43.75-75
	c0,0,62.5,62.5,50,118.75l43.751-37.5c0,0,0,62.5-18.75,100h18.75c0,0,0,37.5-43.751,62.5"
      />
      <path
        fill="#FD8F31"
        d="M406,313.993c-3.451,0.013-6.26-2.776-6.271-6.229c-0.008-2.249,1.193-4.329,3.146-5.446
	c29.907-17.074,37.794-39.913,39.882-50.825h-11.756c-3.452,0.002-6.252-2.795-6.254-6.247c0-0.971,0.226-1.928,0.659-2.797
	c12.619-25.237,16.438-62.956,17.588-83.188l-32.931,28.231c-2.621,2.246-6.567,1.942-8.813-0.678
	c-1.28-1.493-1.782-3.502-1.356-5.422c8.863-39.894-23.687-84.438-39.8-103.5c-8.818,30.625-41.818,63.913-43.431,65.525
	c-2.444,2.438-6.401,2.433-8.84-0.011c-0.863-0.866-1.454-1.966-1.698-3.164c-2.887-13.765-12.523-25.144-25.626-30.256
	c-6.193,44.919-67.593,100-70.368,102.45c-2.58,2.294-6.531,2.062-8.825-0.519c-2.294-2.58-2.062-6.531,0.519-8.825l0,0
	c0.681-0.625,68.125-61.056,66.657-101.356c-0.139-3.449,2.545-6.356,5.995-6.495c0.511-0.021,1.022,0.021,1.522,0.126
	c17.174,3.932,31.58,15.557,39.051,31.513c13.731-15.512,34.7-43.35,34.7-62.888c0.001-3.452,2.8-6.25,6.252-6.249
	c1.657,0,3.245,0.658,4.417,1.83c2.406,2.406,53.812,54.494,53.494,108.157l31.769-27.231c2.62-2.247,6.566-1.944,8.812,0.676
	c0.973,1.133,1.507,2.576,1.507,4.068c0,2.412-0.188,55.431-15.382,93.75h9.132c3.451,0,6.25,2.798,6.25,6.25
	c0,1.682-0.531,41.432-46.876,67.926C408.174,313.713,407.097,313.997,406,313.993z"
      />
      <path
        fill="#FCB44D"
        d="M224.75,88.993c-3.452,0-6.25-2.798-6.25-6.25v-6.25c0-3.452,2.798-6.25,6.25-6.25
	c3.452,0,6.25,2.798,6.25,6.25v6.25C231,86.195,228.202,88.993,224.75,88.993z"
      />
      <path
        fill="#FF7F1F"
        d="M306,88.993c-3.452,0-6.25-2.798-6.25-6.25v-6.25c0-3.452,2.798-6.25,6.25-6.25s6.25,2.798,6.25,6.25v6.25
	C312.25,86.195,309.452,88.993,306,88.993z"
      />
      <path
        fill="#FF7F1F"
        d="M368.5,170.243c-3.451,0-6.25-2.798-6.25-6.25v-6.25c0-3.452,2.799-6.25,6.25-6.25
	c3.452,0,6.25,2.798,6.25,6.25v6.25C374.75,167.445,371.952,170.243,368.5,170.243z"
      />
      <path
        fill="#231F20"
        d="M293.5,213.993c-3.452,0-6.25-2.798-6.25-6.25v-6.25c0-3.452,2.798-6.25,6.25-6.25s6.25,2.798,6.25,6.25
	v6.25C299.75,211.195,296.952,213.993,293.5,213.993z"
      />
      <path
        fill="#231F20"
        d="M243.5,276.493c-3.452,0-6.25-2.798-6.25-6.25v-6.25c0-3.451,2.798-6.25,6.25-6.25s6.25,2.799,6.25,6.25
	v6.25C249.75,273.695,246.952,276.493,243.5,276.493z"
      />
      <path
        fill="#055177"
        d="M406,304.325c0,82.562-70,147.169-152.831,147.169c-80.286,0.993-146.176-63.286-147.17-143.572
	c-0.015-1.199-0.015-2.397,0-3.597c0-82.569,64.325-152.832,147.17-152.832C337.073,152.692,404.802,220.42,406,304.325z"
      />
      <path
        fill="#055177"
        d="M253.169,157.743c-77.701,0-140.92,65.756-140.92,146.582c-0.833,76.994,60.909,140.086,137.903,140.919
	c1.005,0.011,2.011,0.011,3.017,0c80.825,0,146.581-63.219,146.581-140.919C398.696,223.811,333.683,158.797,253.169,157.743z
	 M342.501,173.681c-26.162-18.393-57.334-28.318-89.314-28.438c-84.625,0-153.438,71.375-153.438,159.062
	c-0.18,30.68,8.809,60.714,25.813,86.251c28.535,42.434,76.491,67.681,127.625,67.188c87.689,0,159.063-68.812,159.063-153.438
	C411.717,251.989,385.679,203.227,342.501,173.681z"
      />
      <path
        fill="#146FB4"
        d="M370.875,262.932c0,84.562-71.376,153.438-159.125,153.438c-30.66,0.18-60.674-8.809-86.188-25.812
	c-17.004-25.537-25.993-55.571-25.813-86.251c0-87.688,68.813-159.062,153.438-159.062c31.98,0.12,63.152,10.045,89.314,28.438
	C360.854,199.833,370.758,230.98,370.875,262.932L370.875,262.932z"
      />
      <path
        fill="#FF7F1F"
        d="M293.5,307.743c0,0-37.5-12.5-6.25-62.5l12.5,18.75c1.853-22.368,13.237-42.859,31.25-56.25
	c0,0,31.25,31.25,18.75,68.75l18.75-6.25c3.289,15.347-4.499,30.924-18.75,37.5H293.5z"
      />
      <path
        fill="#FF7F1F"
        d="M349.75,313.993H293.5c-0.671,0.001-1.339-0.106-1.975-0.318c-9.61-3.633-17.2-11.213-20.845-20.819
	c-4.999-13.85-1.188-30.987,11.25-50.925c1.125-1.799,3.086-2.905,5.207-2.938c2.102,0.051,4.06,1.079,5.293,2.781l3.857,5.787
	c5.38-17.73,16.139-33.352,30.787-44.699c2.487-1.99,6.073-1.791,8.325,0.462c1.25,1.25,27.905,28.419,22.956,63.707l8.125-2.719
	c3.273-1.094,6.814,0.674,7.908,3.947c0.052,0.153,0.097,0.309,0.136,0.466c4.065,18.195-5.102,36.767-22.02,44.605
	C351.65,313.76,350.708,313.986,349.75,313.993L349.75,313.993z M294.693,301.493h53.52c8.758-4.302,14.438-13.073,14.781-22.824
	l-11.25,3.75c-3.274,1.092-6.814-0.678-7.907-3.952c-0.427-1.283-0.427-2.671,0-3.955c8.601-25.793-6.143-48.712-13.475-57.987
	c-14.005,12.037-22.748,29.073-24.362,47.469c0.001,3.452-2.797,6.251-6.249,6.252c-2.09,0-4.041-1.044-5.201-2.783l-6.875-10.306
	c-6.35,12.581-8.162,23.344-5.244,31.475C284.653,294.374,289.064,299,294.693,301.493L294.693,301.493z"
      />
      <path
        fill="#FD8F31"
        d="M149.75,357.744c0,0-25-18.75,0-62.501l18.056,18.75c0,0-5.556-31.25,25.694-56.25
	c0,0,36.806,31.25,24.306,68.751l18.75-6.251c3.289,15.347-4.499,30.924-18.75,37.501H149.75z"
      />
      <path
        fill="#FD8F31"
        d="M217.806,363.994H149.75c-1.353,0-2.668-0.439-3.75-1.25c-1.2-0.9-29.1-22.613-1.675-70.626
	c1.708-3,5.524-4.046,8.524-2.337c0.516,0.294,0.988,0.66,1.4,1.088l7.613,7.905c2.964-18.323,12.901-34.789,27.731-45.95
	c2.337-1.868,5.67-1.818,7.95,0.119c1.476,1.25,32.969,28.494,28.75,64.094l8.281-2.762c3.274-1.095,6.815,0.674,7.908,3.947
	c0.051,0.153,0.096,0.309,0.136,0.465c4.066,18.196-5.102,36.767-22.019,44.605C219.736,363.741,218.779,363.98,217.806,363.994
	L217.806,363.994z M152.181,351.494h64.088c8.757-4.303,14.438-13.074,14.781-22.825l-11.25,3.75
	c-3.274,1.092-6.814-0.678-7.906-3.952c-0.428-1.283-0.428-2.671,0-3.954c8.693-26.069-10.181-49.513-18.619-58.319
	c-13.604,11.534-20.791,28.935-19.293,46.706c0.584,3.402-1.7,6.634-5.102,7.219c-2.023,0.347-4.087-0.321-5.523-1.787l-12.025-12.5
	c-12.906,27.45-3.044,41.537,0.856,45.663H152.181z"
      />
      <g>
        <path
          fill="#2290D3"
          d="M304.05,195.243c-2.235,0.002-4.301-1.189-5.418-3.125c-12.819-22.169-28.463-34.375-44.051-34.375
		c-3.451,0-6.25-2.798-6.25-6.25c0-3.452,2.799-6.25,6.25-6.25c20.231,0,39.719,14.425,54.875,40.625
		c1.726,2.989,0.702,6.812-2.287,8.538C306.221,194.953,305.145,195.242,304.05,195.243L304.05,195.243z"
        />
        <path
          fill="#2290D3"
          d="M254.581,457.744c-34.207,0-63.582-38.8-74.925-98.844c-0.639-3.394,1.594-6.662,4.988-7.301
		c3.393-0.639,6.661,1.595,7.3,4.987c10.15,53.857,34.738,88.657,62.638,88.657c35.2,0,65.832-55.063,69.738-125.351
		c0.287-5.138,0.431-10.375,0.431-15.575c0-3.452,2.799-6.25,6.25-6.25c3.452,0,6.25,2.798,6.25,6.25c0,5.432-0.149,10.9-0.45,16.25
		C332.456,398.781,297.112,457.744,254.581,457.744z"
        />
        <path
          fill="#2290D3"
          d="M253.169,382.744c-3.452,0-6.25-2.799-6.25-6.25c0-3.452,2.798-6.25,6.25-6.25
		c79.456,0,146.581-30.838,146.581-67.331c0-13.982-10.1-28.325-28.431-40.389c-2.884-1.898-3.684-5.775-1.784-8.659
		c1.897-2.884,5.775-3.683,8.659-1.784c22.274,14.662,34.056,32.237,34.056,50.832C412.25,347.675,342.376,382.744,253.169,382.744z
		"
        />
        <path
          fill="#2290D3"
          d="M105.999,309.163c-3.452,0-6.25-2.799-6.25-6.25c0-46.357,67.395-82.67,153.42-82.67
		c9.506,0,19.068,0.463,28.431,1.369c3.426,0.426,5.856,3.548,5.431,6.974c-0.409,3.292-3.32,5.691-6.63,5.464
		c-8.97-0.869-18.15-1.307-27.231-1.307c-76.388,0-140.92,32.132-140.92,70.17C112.249,306.364,109.451,309.163,105.999,309.163
		L105.999,309.163z"
        />
      </g>
      <path
        fill="#FCC16D"
        d="M187.25,345.244c-3.452-0.001-6.249-2.8-6.249-6.252c0-1.656,0.659-3.245,1.83-4.417
	c8.75-8.751,10.669-26.832,10.669-33.082c0-3.451,2.798-6.25,6.25-6.25c3.452,0,6.25,2.799,6.25,6.25
	c0,1.126-0.163,27.75-14.331,41.92C190.497,344.585,188.907,345.244,187.25,345.244z"
      />
      <path
        fill="#FCC16D"
        d="M312.25,288.993c-3.452,0-6.249-2.8-6.249-6.251c0-1.657,0.659-3.246,1.83-4.418l6.25-6.25
	c2.482-2.397,6.439-2.329,8.838,0.153c2.339,2.423,2.339,6.263,0,8.685l-6.25,6.25C315.497,288.334,313.907,288.993,312.25,288.993z
	"
      />
      <path
        fill="#FCB44D"
        d="M399.75,220.243c-3.451,0.003-6.252-2.794-6.254-6.245c-0.003-2.694,1.723-5.087,4.279-5.936
	c9.711-3.673,17.378-11.339,21.05-21.051c1.093-3.279,4.637-5.052,7.916-3.959c3.279,1.092,5.052,4.636,3.959,7.915
	c-4.946,13.423-15.527,24.004-28.95,28.95C401.106,220.135,400.43,220.244,399.75,220.243z"
      />
      <path
        fill="#2290D3"
        d="M274.75,357.744h-6.25c-3.452,0-6.25-2.799-6.25-6.25c0-3.452,2.798-6.25,6.25-6.25h6.25
	c3.451,0,6.25,2.798,6.25,6.25C281,354.945,278.201,357.744,274.75,357.744z"
      />
      <path
        fill="#2290D3"
        d="M299.75,357.744h-6.25c-3.452,0-6.25-2.799-6.25-6.25c0-3.452,2.798-6.25,6.25-6.25h6.25
	c3.452,0,6.25,2.798,6.25,6.25C306,354.945,303.202,357.744,299.75,357.744z"
      />
    </svg>
  );
}
