import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { EsquentaButton } from '@components/EsquentaButton';
import RandomIcon from '@components/icons/RandomIcon';
import SequenceIcon from '@components/icons/SequenceIcon';
import { ModalListItem } from '@components/ModalListItem';
import { useGame } from '@context/game';
import { useStrings } from '@context/strings';
import { colors, PlayerOrder } from '@global/constants';
import { LangDef } from '@global/strings';
import { TruthOrDareAPI } from '@utils/TruthOrDareAPI';
import { useRef, useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import { IoMdArrowRoundBack } from 'react-icons/io';

type PlayerOrderModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const playerOrderTypes = (lang: LangDef) => [
  {
    id: PlayerOrder.RANDOM,
    title: lang.random,
    description: lang.randomDescription,
    icon: <RandomIcon />,
  },
  {
    id: PlayerOrder.SEQUENTIAL,
    title: lang.sequence,
    description: lang.sequenceDescription,
    icon: <SequenceIcon />,
  },
];

export function PlayerOrderModal(options: PlayerOrderModalProps) {
  const { game, setGame } = useGame();
  const { strings } = useStrings();
  const { isOpen, onClose } = options;
  const initialRef = useRef(null);

  const [selected, setSelected] = useState(game.playerOrder);

  const [loading, setLoading] = useState(false);

  const updatePLayerOrder = async () => {
    if (!game?.id) {
      return;
    }

    if (game.playerOrder === selected) {
      onClose();
      return;
    }

    setLoading(true);

    const api = await TruthOrDareAPI.setup(game);

    const updatedGame = await api.updatePlayerOrder(selected);

    setGame(updatedGame);
    setLoading(false);
    onClose();
  };

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent
          bgColor={colors.esquentaBlue}
          borderRadius={'xl'}
          minHeight="auto"
        >
          <ModalHeader>{strings.playerOrder}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6} bgColor={colors.esquentaBlack}>
            {loading ? (
              <Button isLoading variant="ghost" width="100%" height="60px" />
            ) : (
              playerOrderTypes(strings).map((playerOrder, index) => (
                <ModalListItem
                  {...playerOrder}
                  isSelected={playerOrder.id === selected}
                  onClick={() => setSelected(playerOrder.id)}
                  key={`player-order-${index}`}
                />
              ))
            )}
          </ModalBody>
          <ModalFooter bgColor="todBlack" marginBottom="20px">
            <EsquentaButton
              colorType="pink"
              onClick={onClose}
              leftIcon={<Icon as={IoMdArrowRoundBack} />}
            >
              {strings.cancel}
            </EsquentaButton>
            <EsquentaButton
              colorType="blue"
              ml={3}
              onClick={updatePLayerOrder}
              rightIcon={<Icon as={FiCheck} />}
              isLoading={loading}
            >
              {strings.save}
            </EsquentaButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
