export default function SequenceIcon() {
  return (
    <svg
      width="614"
      viewBox="0 0 614 614"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="614" height="614" fill="#C4C4C4" fill-opacity="0.01" />
      <rect x="203" y="119" width="77" height="52" fill="#04B9FB" />
      <rect x="203" y="443" width="341" height="52" fill="#04B9FB" />
      <rect x="203" y="362" width="275" height="52" fill="#04B9FB" />
      <rect x="203" y="281" width="209" height="52" fill="#04B9FB" />
      <rect x="203" y="200" width="143" height="52" fill="#04B9FB" />
      <rect
        x="186"
        y="119"
        width="341"
        height="35"
        transform="rotate(90 186 119)"
        fill="#EB83B5"
      />
      <path d="M168 501L87.4596 361.5H248.54L168 501Z" fill="#EB83B5" />
    </svg>
  );
}
