import { Button, ButtonProps } from '@chakra-ui/react';

const colors = {
  pink: {
    color: 'esquentaPink',
    borderColor: 'esquentaPink',
    variant: 'outline',
    _hover: {
      bgColor: 'esquentaPink',
      color: 'esquentaBlack',
    },
  },
  blue: {
    color: 'esquentaBlue',
    borderColor: 'esquentaBlue',
    variant: 'outline',
    _hover: {
      bgColor: 'esquentaBlue',
      color: 'esquentaBlack',
    },
  },
  pepper: {
    color: 'esquentaPepper',
    borderColor: 'esquentaPepper',
    variant: 'outline',
    _hover: {
      bgColor: 'esquentaPepper',
      color: 'esquentaBlack',
    },
  },
};

interface EsquentaButtonProps extends ButtonProps {
  colorType: keyof typeof colors;
}

export function EsquentaButton(props: EsquentaButtonProps) {
  const { colorType, ...restProps } = props;

  const updatedProps = {
    ...colors[colorType],
    ...restProps,
  };

  console.log(updatedProps);

  return <Button {...updatedProps} />;
}
