import { Level } from '@global/types';
import { createContext, useContext } from 'react';

export type LevelsContextType = {
  levels: Level[];
  setLevels: (levels: Level[]) => void;
};

export const LevelsContext = createContext<LevelsContextType>({
  levels: [],
  setLevels: () => console.warn('no provider'),
});

export const useLevels = () => useContext(LevelsContext);
