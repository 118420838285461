import { DEFAULT_LANG, Language } from '@global/constants';

import { langDefEnUs } from './en-us';
import { langDefPtbr } from './pt-br';

export type LangDef = typeof langDefEnUs;
const langs: Record<string, any> = {
  [Language.PT_BR]: langDefPtbr,
  [Language.EN_US]: langDefEnUs,
};

export function getStrings(lang?: Language): LangDef {
  return langs[lang?.toLowerCase() || DEFAULT_LANG];
}
