import {
  Box,
  Center,
  Flex,
  Heading,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';
import { EsquentaButton } from '@components/EsquentaButton';
import { EsquentaSolidButton } from '@components/EsquentaSolidButton';
import { Footer } from '@components/Footer';
import { Header } from '@components/Header';
import { NoPlayers } from '@components/NoPlayers';
import { PlayerListItem } from '@components/PlayerListItem';
import { PlayerModal } from '@components/PlayerModal';
import { useGame } from '@context/game';
import { useStrings } from '@context/strings';
import { Paths, colors } from '@global/constants';
import { Player } from '@global/types';
import { TruthOrDareAPI } from '@utils/TruthOrDareAPI';
import { useEffect, useState } from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { IoPersonAdd, IoPlay } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const buttonIcons = {
  add: <Icon as={IoPersonAdd} />,
  back: <Icon as={IoMdArrowRoundBack} />,
  play: <Icon as={IoPlay} />,
};

export function Players() {
  const { game, setGame } = useGame();
  const { strings } = useStrings();
  const navigate = useNavigate();

  const [currentPlayer, setCurrentPlayer] = useState<Player | undefined>();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const openModal = (player: Player | undefined) => {
    setCurrentPlayer(player);
    onOpen();
  };

  const removePlayer = (playerIndex: number) => {
    const { _id, name } = game.players[playerIndex] as Player;

    Swal.fire({
      icon: 'question',
      text: `${strings.removePlayerQuestion} ${name}?`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: strings.confirmPlayerRemove,
      denyButtonText: strings.cancelPlayerRemove,
    }).then(async ({ isConfirmed }) => {
      if (!isConfirmed) {
        return;
      }

      const api = await TruthOrDareAPI.setup(game);

      api.removePlayer(_id).then((updatedGame) => {
        setGame(updatedGame);
      });
    });
  };

  const startGame = () => {
    if (game.players.length < 2) {
      Swal.fire({
        text: strings.min2players,
        icon: 'warning',
      });
      return;
    }

    navigate(Paths.PLAY);
  };

  useEffect(() => {
    (async () => {
      if (!game.id) {
        const newGame = await TruthOrDareAPI.create(game.lang);
        setGame(newGame);
      } else {
        try {
          const api = await TruthOrDareAPI.setup(game);
          await api.getCurrentGame();
        } catch (err) {
          const api = await TruthOrDareAPI.setup(game, true);
          const updatedGame = await api.getCurrentGame();
          setGame(updatedGame);
        }
      }
    })();
  });

  return (
    <>
      <Header showActions hidePlayerListButton />
      {isOpen ? (
        <PlayerModal
          isOpen={isOpen}
          player={currentPlayer}
          onClose={() => {
            setCurrentPlayer(undefined);
            onClose();
          }}
        />
      ) : undefined}
      <Box
        mb={4}
        shadow="base"
        borderWidth="1px"
        alignSelf={{ base: 'center' }}
        borderColor={colors.esquentaAskov}
        bgColor={colors.esquentaAskov}
        borderRadius={'xl'}
        width={{ base: '100%', lg: '25%' }}
        height={{ base: '100%', lg: '25%' }}
      >
        <Center>
          <Heading padding="20px" width="100%" textAlign="center" margin={0}>
            {strings.players}
          </Heading>
        </Center>
        {game?.players?.length ? (
          game.players.map((player, playerIndex) => {
            const { _id, name, sexualPreference, gender } = player;
            if (!_id) {
              return;
            }

            return (
              <PlayerListItem
                name={name}
                sexualPreference={sexualPreference}
                gender={gender}
                key={`player-${playerIndex}`}
                onEditClick={() => openModal(player)}
                onRemoveClick={() => removePlayer(playerIndex)}
              />
            );
          })
        ) : (
          <NoPlayers />
        )}
        <Flex
          justifyContent="space-between"
          padding={{ base: '15px 20px', lg: '15px 20px' }}
          position="relative"
          bgColor={colors.esquentaBlack}
          borderTop={`1px solid ${colors.esquentaAskov}`}
          marginBottom={'15px'}
        >
          <EsquentaButton
            leftIcon={buttonIcons.back}
            colorType="pink"
            bgColor={colors.esquentaBlack}
            onClick={() => navigate(Paths.HOME)}
          >
            {strings.back}
          </EsquentaButton>
          <EsquentaSolidButton
            leftIcon={buttonIcons.play}
            onClick={startGame}
            colorType="esquentaAskov"
            bgColor={colors.esquentaBlack}
          >
            {strings.startGame}
          </EsquentaSolidButton>
          <EsquentaButton
            leftIcon={buttonIcons.add}
            onClick={() => openModal(currentPlayer)}
            colorType="pink"
            bgColor={colors.esquentaBlack}
          >
            {strings.addPlayer}
          </EsquentaButton>
        </Flex>
      </Box>
      <Footer />
    </>
  );
}
