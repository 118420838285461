// Style
import { Box, Flex, Image } from '@chakra-ui/react';
import { CircleLines } from '@components/CircleLines';
import { CircleNames } from '@components/CircleNames';
import { PlayerOrder, colors } from '@global/constants';
// import beatsBottle from '@img/beats-bottle.png';
import { Player } from '@global/types';
import beatsBottle from '@img/bottle.svg';

type RotateOptions = {
  duration: number;
  easing: string;
  complete?: () => void;
  progress?: (elements: any, percentComplete: number) => void;
};

function rotate(
  element: any,
  degrees: number,
  options?: Partial<RotateOptions>,
) {
  const defaultOptions: RotateOptions = {
    duration: 1000,
    easing: 'swing',
  };

  const finalOptions: RotateOptions = { ...defaultOptions, ...options };
  const finalDegrees = degrees || 360;

  const startDeg = getRotation(element);
  const endDeg = startDeg + finalDegrees;
  const interval = endDeg - startDeg;
  finalOptions.progress = (elements, percentComplete) => {
    const drg = interval * percentComplete + startDeg;
    element.style.transform = `rotate(${drg}deg)`;
  };

  element.velocity({ deg: endDeg }, finalOptions);
}

function nextRandom(min: number, max: number, ignore: number) {
  let next = -1;
  do {
    next = random(min, max);
  } while (next === ignore);

  return next;
}

function random(min: number, max: number) {
  if (min < max) {
    return Math.floor(Math.random() * (++max - min) + min);
  }

  return Math.floor(Math.random() * (++min - max) + max);
}

function next(last: number, max: number) {
  const plusOne = last + 1;

  if (plusOne >= max) {
    return 0;
  }

  return plusOne;
}

function getRotation(element: HTMLElement) {
  const st = window.getComputedStyle(element, null);
  const tr =
    st.getPropertyValue('-webkit-transform') ||
    st.getPropertyValue('-moz-transform') ||
    st.getPropertyValue('-ms-transform') ||
    st.getPropertyValue('-o-transform') ||
    st.getPropertyValue('transform') ||
    'fail...';

  // With rotate(30deg)...
  // matrix(0.866025, 0.5, -0.5, 0.866025, 0px, 0px)
  if (tr === 'none') {
    return 0;
  }

  // rotation matrix - http://en.wikipedia.org/wiki/Rotation_matrix

  const [a, b] = tr.split('(')[1].split(')')[0].split(',');

  const numA = +a;
  const numB = +b;

  // arc sin, convert from radians to degrees, round;
  return Math.round(Math.atan2(numB, numA) * (180 / Math.PI));
}

export type CircleProps = {
  playerOrder: PlayerOrder;
  players: Player[];
  playerOnTurnIndex: number;
  setPlayerOnTurnIndex: React.Dispatch<React.SetStateAction<number>>;
};

export function Circle(props: CircleProps) {
  const { players, playerOrder, playerOnTurnIndex, setPlayerOnTurnIndex } =
    props;

  const numberOfPlayers = players.length;

  const handleRotation = () => {
    const playerIndex =
      playerOrder === PlayerOrder.RANDOM
        ? nextRandom(0, numberOfPlayers - 1, playerOnTurnIndex)
        : next(playerOnTurnIndex, numberOfPlayers);
    const randomPart = random(0, 360 / numberOfPlayers);
    const spinTo = 1080 + (playerIndex * 360) / numberOfPlayers + randomPart;

    if (playerOrder === PlayerOrder.SEQUENTIAL) {
      setPlayerOnTurnIndex(playerIndex);

      return;
    }

    const bottleElement = document.querySelector('.bottle');

    // half a second for each player
    const duration = numberOfPlayers * 500;

    new Promise((resolve) => {
      rotate(bottleElement, spinTo, {
        duration: duration,
        complete: () => resolve(true),
      });
    })
      .then(() => {
        setPlayerOnTurnIndex(playerIndex);

        return new Promise((resolve) => {
          setTimeout(() => resolve(true), 1500);
        });
      })
      .then(() => {
        rotate(bottleElement, -(spinTo - 1080));
      });
  };

  return (
    <Box
      margin="auto"
      width={{ base: '375px', lg: '500px' }}
      height={{ base: '375px', lg: '500px' }}
      border={`2px solid ${colors.esquentaBlue}`}
      borderRadius="50%"
      position="relative"
      marginTop={{ base: '5%', lg: 0 }}
      marginBottom={{ base: '15%', lg: 0 }}
    >
      <CircleLines count={numberOfPlayers} />
      <Flex
        className="bottle"
        onClick={() => handleRotation()}
        width={{ base: '300px', lg: '400px' }}
        height={{ base: '300px', lg: '400px' }}
        backgroundSize="contain"
        backgroundRepeat="no-repeat"
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        margin="auto"
        zIndex={2}
      >
        <Image src={beatsBottle} />
      </Flex>
      <CircleNames players={players} />
    </Box>
  );
}
