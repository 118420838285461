import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { GameContext } from '@context/game';
import { LevelsContext } from '@context/levels';
import { StringsContext } from '@context/strings';
import {
  EMPTY_GAME,
  Language,
  LocalStorageKey,
  colors,
} from '@global/constants';
import { LangDef, getStrings } from '@global/strings';
import { Game, Level } from '@global/types';
import { TruthOrDareAPI } from '@utils/TruthOrDareAPI';
import { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';

import 'velocity-animate/velocity';
import 'velocity-animate/velocity.ui';
import './App.scss';
import { Routes } from './Routes';

const theme = extendTheme({
  styles: {
    global: {
      body: {
        color: colors.esquentaWhite,
        bgColor: colors.esquentaBlack,
      },
    },
  },
  colors: {
    ...colors,
    todGreen: colors.green,
    todRed: colors.red,
    todBlack: colors.black,
    todWhite: colors.white,
  },
});

function App() {
  const [storeGame, setStoreGame] = useLocalStorage<Game | null>(
    LocalStorageKey,
  );

  const [game, setGame] = useState<Game>(storeGame || EMPTY_GAME);
  const [levels, setLevels] = useState<Level[]>([]);
  const [strings, setStrings] = useState<LangDef>(getStrings(game.lang));

  const updateStrings = (lang: Language) => {
    setStrings(getStrings(lang));
  };

  const updateGame = (updatedGame: Game) => {
    const currentLastPlayerIndex = game?.lastPlayerIndex ?? -1;
    const updatedGameLastPlayer = updatedGame?.lastPlayerIndex ?? null;
    if (updatedGame && updatedGameLastPlayer === null) {
      updatedGame.lastPlayerIndex = currentLastPlayerIndex;
    }

    if (updatedGame && !updatedGame.currentLevels) {
      updatedGame.currentLevels = levels.filter((level) =>
        updatedGame.levels.includes(level.selector),
      );
    }

    setStoreGame(updatedGame);
    setGame(updatedGame);
  };

  useEffect(() => {
    if (!levels.length) {
      TruthOrDareAPI.listLevels().then((allLevels) => {
        setLevels(allLevels);
      });
    }
  });

  return (
    <StringsContext.Provider value={{ strings, setStrings: updateStrings }}>
      <LevelsContext.Provider value={{ levels, setLevels }}>
        <GameContext.Provider value={{ game, setGame: updateGame }}>
          <ChakraProvider theme={theme}>
            <div className="App">
              <Routes />
            </div>
          </ChakraProvider>
        </GameContext.Provider>
      </LevelsContext.Provider>
    </StringsContext.Provider>
  );
}

export default App;
