import { Box, Flex, Heading, useDisclosure } from '@chakra-ui/react';
import { Circle } from '@components/Circle';
import { Header } from '@components/Header';
import { TaskModal } from '@components/TaskModal';
import { useGame } from '@context/game';
import { useStrings } from '@context/strings';
import { Paths, PlayerOrder, colors } from '@global/constants';
import { Player } from '@global/types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

export function Play() {
  const { game, setGame } = useGame();
  const { strings } = useStrings();
  const navigate = useNavigate();

  if (!game?.id) {
    navigate(Paths.HOME);
  }

  const [playerOnTurn, setPlayerOnTurn] = useState<Player>();
  const [playerOnTurnIndex, setPlayerOnTurnIndex] = useState(
    game?.lastPlayerIndex as number,
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeModal = () => {
    setPlayerOnTurn(undefined);
    onClose();
  };

  useEffect(() => {
    if (game?.lastPlayerIndex === playerOnTurnIndex) {
      return;
    }

    setPlayerOnTurn(game?.players[playerOnTurnIndex]);
    if (game) {
      setGame({ ...game, lastPlayerIndex: playerOnTurnIndex });
    }
  }, [playerOnTurnIndex]);

  useEffect(() => {
    if (playerOnTurn) {
      onOpen();
    }
  }, [playerOnTurn]);

  return (
    <>
      <Header showActions />
      <Flex
        className="level-selector"
        flexDirection="column"
        margin="auto"
        marginTop="1.5rem"
        maxWidth="fit-content"
        width="100%"
        borderColor="esquentaBlue"
        zIndex={990}
      >
        <Heading
          textAlign="center"
          marginBottom="3rem"
          color={colors.esquentaBlue}
        >
          {strings.bottleClick}
        </Heading>
        <Box py={4} px={5}>
          <Circle
            playerOrder={game?.playerOrder || PlayerOrder.RANDOM}
            players={game?.players || []}
            playerOnTurnIndex={playerOnTurnIndex}
            setPlayerOnTurnIndex={setPlayerOnTurnIndex}
          />
          {isOpen && (
            <TaskModal
              player={playerOnTurn}
              isOpen={isOpen}
              onClose={() => closeModal()}
            />
          )}
        </Box>
      </Flex>
    </>
  );
}
