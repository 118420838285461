import { Box } from '@chakra-ui/layout';
import { colors } from '@global/constants';

type CircleLinesProps = {
  count: number;
};

export function CircleLines(props: CircleLinesProps) {
  const { count } = props;

  const rotationBase = 360 / count;

  const lines = [];

  for (let counter = 0; counter < count; counter++) {
    const rotationAngle = rotationBase * counter;

    lines.push(
      <Box
        transform={`rotate(${rotationAngle}deg)`}
        position="absolute"
        width="100%"
        height="2px"
        top="0"
        bottom="0"
        left="0"
        right="0"
        margin="auto"
        fontSize="0"
        key={`circle-line-${counter}`}
      >
        <Box
          display="inline-block"
          fontSize="0"
          width="50%"
          height="2px"
          visibility="hidden"
        />
        <Box
          display="inline-block"
          fontSize="0"
          width="50%"
          height="2px"
          background={colors.esquentaBlue}
        />
      </Box>,
    );
  }

  return <>{lines}</>;
}
