import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { EsquentaButton } from '@components/EsquentaButton';
import LevelIcon from '@components/icons/LevelIcon';
import { ModalListItem } from '@components/ModalListItem';
import { useGame } from '@context/game';
import { useLevels } from '@context/levels';
import { useStrings } from '@context/strings';
import { colors, LevelSelector } from '@global/constants';
import { arraysAreEqual } from '@utils/arrays';
import { TruthOrDareAPI } from '@utils/TruthOrDareAPI';
import { useRef, useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import { IoMdArrowRoundBack } from 'react-icons/io';

type LevelModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function LevelModal(options: LevelModalProps) {
  const { game, setGame } = useGame();
  const { levels } = useLevels();
  const { strings } = useStrings();
  const { isOpen, onClose } = options;
  const initialRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [seletedLevels, setSelectedLevels] = useState<LevelSelector[]>(
    game?.levels,
  );

  const updateSelectedLevels = (levelSelector: LevelSelector) => {
    const levelIndex = seletedLevels.indexOf(levelSelector);
    const newLevels = [...seletedLevels];

    if (levelIndex !== -1) {
      newLevels.splice(levelIndex, 1);
    } else {
      newLevels.push(levelSelector);
    }

    setSelectedLevels(newLevels);
  };

  const saveLevels = async () => {
    if (!game?.id) {
      return;
    }
    if (arraysAreEqual(game.levels, seletedLevels)) {
      onClose();
      return;
    }

    setLoading(true);

    const api = await TruthOrDareAPI.setup(game);

    const updatedGame = await api.updateLevels(seletedLevels);
    setGame(updatedGame);
    setLoading(false);
    onClose();
  };

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent
          bgColor={colors.esquentaPink}
          minHeight="auto"
          borderRadius={'xl'}
        >
          <ModalHeader>{strings.levelSelector}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6} bgColor={colors.esquentaBlack}>
            {loading ? (
              <Button isLoading variant="ghost" width="100%" height="60px" />
            ) : (
              levels.map((level, index) => {
                const { selector, info, hasTruth } = level;
                const { name, description } = info[game.lang];

                const icon = <LevelIcon level={selector} />;
                return (
                  <ModalListItem
                    title={name}
                    description={description}
                    footer={hasTruth ? undefined : strings.noTruths}
                    icon={icon}
                    isSelected={seletedLevels.includes(selector)}
                    onClick={() => updateSelectedLevels(selector)}
                    key={`level-${index}`}
                  />
                );
              })
            )}
          </ModalBody>
          <ModalFooter
            bgColor="todBlack"
            marginBottom="20px"
            borderTop={`1px solid ${colors.esquentaPink}`}
          >
            <EsquentaButton
              colorType="pink"
              onClick={onClose}
              leftIcon={<Icon as={IoMdArrowRoundBack} />}
            >
              {strings.cancel}
            </EsquentaButton>
            <EsquentaButton
              colorType="blue"
              ml={3}
              onClick={saveLevels}
              rightIcon={<Icon as={FiCheck} />}
              isLoading={loading}
            >
              {strings.save}
            </EsquentaButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
