type EmojiProps = {
  symbol: string;
  label: string;
};

export function Emoji(props: EmojiProps) {
  const { symbol, label } = props;
  return (
    <span
      className="emoji"
      role="img"
      aria-label={label ? label : ''}
      aria-hidden={label ? 'false' : 'true'}
    >
      {' ' + symbol + ' '}
    </span>
  );
}
